import './SavedRecipeCardComponent.css'
import default_img from '../icons/Recipe_DEMO.jpeg'
import { useNavigate } from 'react-router-dom'

export default function SavedRecipeCardComponent(props) {
	const navigate = useNavigate();
	const collectionRedir = () => {
		navigate(`/profile/collection/${props.name}`)
	}

	return (
		<div
			onClick={collectionRedir}
			className='saved-recipe-card'
		>
			<div className='saved-recipe-card-image-container'>
				<img src={props.cover_picture || default_img} className='saved-recipe-card-image' alt='recipe-image'/>
				<div className='saved-recipe-card-image-cover' />
			</div>
			<p className='saved-recipe-card-collection-name'>
				{props.name}
			</p>
			{
				props.count && 
				<p className='saved-recipe-card-collection-count'>
					{props.count} collections
				</p>
			}
		</div>
	)
}

