import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import LoginPage from "./UserManagement/page/LoginPage/LoginPage"; // Update path
import ConfirmUserPage from "./UserManagement/page/ConfirmUserPage";
import SignupPage from "./UserManagement/page/SignupPage/SignupPage"; // Update path
import EmailSignupPage from "./UserManagement/page/SignupPage/EmailSignup"; // Update path
import Forgotpassword from "./UserManagement/page/LoginPage/ForgotPassword"; // Update path
import ConfirmForgotPage from "./UserManagement/page/LoginPage/ConfirmForgotPage"; // Update path

import Careers from "./Careers/Careers";
import JobPost from "./Careers/JobPost";

import RecipeCreatePage from "./recipes/RecipeCreatePage";
// import DynamicForm from "./recipes/DynamicForm";
import Cart from "./ShoppingCart/Cart";
import AboutUs from "./AboutUs/AboutUs";
import RewardShippingCard from "./Reward/RewardShippingCard";
import ConfirmRewardShippingCard from "./Reward/ConfirmRewardShippingCard";
import RewardRedeemed from "./Reward/RewardRedeemed";
import ContactUsCard from "./ContactUs/ContactUsCard";
import ConfirmContactUs from "./ContactUs/ConfirmContactUs";

import KangacookRecipePage from "./RecipePage/RecipePage";
import KangacookHome from "./HomePage/HomePage";

import UserProfile from "./UserProfile/UserProfile";
import UserCollection from "./UserProfile/Collection/Collection";

import ConstructionPage from "./ConstructionPage/ConstructionPage";

import CookieBanner from "./components/CookieBanner/CookieBanner";

import NotFound from "./components/404";
import "./components/i18n.jsx";
import { useTranslation } from "react-i18next";
function App() {
  const { i18n } = useTranslation();
  const cookie_value = Cookies.get("kangacook_user_id");

  const username = (cookie_value && JSON.parse(cookie_value).username) || "";
  const cookie_lang = Cookies.get("lang") || "";
  useEffect(() => {
    const changeLang = () => {
      if (cookie_lang) {
        return;
      }
      var userLang = navigator.language || navigator.userLanguage;
      if (userLang === "zh-TW" || userLang === "zh-HK") {
        userLang = "zh_tw";
      } else if (userLang === "zh-CN" || userLang === "zh") {
        userLang = "zh";
      } else {
        userLang = "en";
      }

      let language_cookie = `${userLang}`;
      Cookies.set("lang", language_cookie, {
        expires: 90,
        domain: process.env.REACT_APP_COOKIE_URL,
      });
      i18n.changeLanguage(`${userLang}`);
    };
    const fetchLang = async () => {
      try {
        const apiHost = process.env.REACT_APP_BACKEND_URL;
        const response = await fetch(
          `${apiHost}/user-identity/${username}/language`,
          {
            method: "GET",
            credentials: "include",
          },
        );
        const data = await response.json();

        let userLang = data.language;
        let language_cookie = `${userLang}`;
        Cookies.set("lang", language_cookie, {
          expires: 90,
          domain: process.env.REACT_APP_COOKIE_URL,
        });
        i18n.changeLanguage(`${userLang}`);
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    };
    if (username) {
      fetchLang();
    } else {
      changeLang();
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Remove force login process, tourist can also access home page */}
        <Route path="/" element={<KangacookHome />} />
        {/*     <Route path="/" element={isAuthenticated() ? <Navigate replace to="/home" /> : <Navigate replace to="/login" />} />
        <Route path="/" element={<LoginPage />} /> */}
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/confirm" element={<ConfirmUserPage />} />
        <Route path="/forgotpassword" element={<Forgotpassword />} />
        <Route path="/forgotpasswordConfirm" element={<ConfirmForgotPage />} />
        <Route path="/resetPassword" element={<ConfirmForgotPage />} />
        <Route path="/EmailSignup" element={<EmailSignupPage />} />
        <Route path="/oneclickpurchase" element={<Cart />} />
        <Route path="/reward" element={<ConstructionPage />} />

        {/* Remove force login process, tourist can also access home page */}
        <Route path="/home" element={<KangacookHome />} />
        {/* <Route path="/home" element={isAuthenticated() ? <KangacookHome /> : <Navigate replace to="/login" />} /> */}

        {/* Career frontend demo */}
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:pk" element={<JobPost />} />

        <Route path="/recipe/:pk" element={<KangacookRecipePage />} />
        <Route path="/recipes/create/:pk?" element={<RecipeCreatePage />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/rewardshippingcard" element={<RewardShippingCard />} />
        <Route
          path="/confirm-rewardshippingcard"
          element={<ConfirmRewardShippingCard />}
        />
        <Route path="/reward-redeemed" element={<RewardRedeemed />} />
        <Route path="/contactus" element={<ContactUsCard />} />
        <Route path="/confirm-contactus" element={<ConfirmContactUs />} />

        <Route path="/kangacook" element={<KangacookHome />} />
        <Route path="/profile/:username" element={<UserProfile />} />
        <Route path="/profile/collection/:pk" element={<UserCollection />} />

        <Route path="/shopping-cart" element={<Cart />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
      <CookieBanner />
    </BrowserRouter>
  );
}

export default App;
