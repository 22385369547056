import user_avatar from '../icons/Avatar.svg'
import back_img from '../icons/back.svg'
import '../../../../components/components.css'
import './Following.css'
import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import { useTranslation } from "react-i18next";

export default function Following({
	setViewFollowing, followings, followings_count,
	toggleFollowing, setToggleFollowing,
}){
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [followingList, setFollowingList] = useState([])
	useEffect(()=>{
		if(followings){
			setFollowingList(
				followings.results.map(item => {
					return {
						profile_picture: item.profile_picture,
						name: item.username,
						following: true
					} 
				})
			)
			console.log(followingList)
		}
	}, [followings])
	const toggleFollow = async(index) => {
		if(followingList[index].following){
			followingList[index].following = false
			setFollowingList([...followingList])
			try {
				await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-network/${followingList[index].name}/`,{
					method: 'DELETE',
					credentials: 'include',
				});
			} catch (error) {
				console.error('Error unfollowing user', error);
			}
		}
		else{
			followingList[index].following = true
			setFollowingList([...followingList])
			try {
				await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-network/`,{
					method: 'POST',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						following: followingList[index].name,
					})
				});
			} catch (error) {
				console.error('Error following user', error);
			}
		}
		setToggleFollowing(!toggleFollowing)
	}
	return (
		<div className='user-profile-followings-component'>
			<div className='user-profile-followings-header'>
				<img src={back_img} className='secondary-icon' onClick={()=>setViewFollowing(false)} alt='back-icon'/>
				<p className='user-profile-followings-text'>
					{followings_count} {followings_count>1 || 0? 'Followings' : 'Following'}
				</p>
			</div>

			<div className='user-profile-followings-list'>
				{
					followingList.map((item, index) =>
						<div className='user-profile-followings-item' key={index}>
							<div className='user-profile-followings-details'>
								<div
									className='x-large-avatar-container'
									onClick={()=>navigate(`/profile/${item.name}`)}
								>
									<img src={item.profile_picture || user_avatar} className='x-large-avatar-avatar' alt='profile-picture'/>
									<div className='x-large-avatar-cover' />
								</div>
								<p className='user-profile-followings-name'>
									{item.name}
								</p>
							</div>

							{item.following?
								<div
									onClick={()=>toggleFollow(index)}
									className='secondary-text-wrapper'
								>
									<p className='secondary-text'>
										{t("follow.following")}
									</p>
								</div>
								:
								<div
									onClick={()=>toggleFollow(index)}
									className='secondary-prime-wrapper'
								>
									<p className='secondary-prime-text'>
										{t("follow.follow")}
									</p>
								</div>
							}

						</div>
					)
				}
			</div>
		</div>
	)
}

