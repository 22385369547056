import { useEffect } from 'react'
import { useState } from 'react'
import RecipeCardComponent from '../../../components/RecipeCardComponent'
import {isAuthenticated} from "../../../UserManagement/hook/Authenticated"
import './Recipes.css'

export default function Recipes({recipes}){
	const [collections, setCollections] = useState(null)
	const [toggleCollection, setToggleCollection] = useState(false)

	const fetchUserCollections = async () => {
		if (!isAuthenticated()) return
		try {
			const apiHost = process.env.REACT_APP_BACKEND_URL;
			const response = await fetch(`${apiHost}/recipe-collection`,{
				method: 'GET',
				credentials: 'include',
			});
			const data = await response.json();
			setCollections(data.filter(collection => collection.name !== 'Saved')); // Set the recipe data to state
		} catch (error) {
			console.error('Error fetching user collection:', error);
		}
	}

	useEffect(() => {
		fetchUserCollections()
	}, [toggleCollection])


	return (
		<div className='home-page-recipes-container'>
			{
				recipes.map((item, index) =>{
					return (
						<div className='home-page-recipe' key={index}>
							<RecipeCardComponent 
									{...item}
									collections={collections}
									toggleCollection={toggleCollection}
									setToggleCollection={setToggleCollection}
							/>
						</div>
					)
				})
			}
		</div>
	)
}

