import React from 'react';
import GrayProgress from '../../../components/icons/recipeCreate/GrayProgress.svg';
import YellowProgress from '../../../components/icons/recipeCreate/YellowProgress.svg';

const ProgressBar = ({ currentStep }) => {
  return (
    <div className="relative flex h-3 justify-center items-center flex-1 mt-10"> 
      <div className="flex items-center flex-1 pr-[6px] pl-[2px]">
        <img src={currentStep >= 1 ? YellowProgress : GrayProgress} alt="Step 1 Progress" />
      </div>

      <div className="flex h-3 items-center flex-1 py-[4px]">
        <img src={currentStep >= 2 ? YellowProgress : GrayProgress} alt="Step 2 Progress" />
      </div>

      <div className="flex items-center flex-1 pl-[6px] pr-[2px]">
        <img src={currentStep >= 3 ? YellowProgress : GrayProgress} alt="Step 3 Progress" />
      </div>
    </div>
  );
};

export default ProgressBar;