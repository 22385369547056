// TODO: change link reference to real url
import FacebookIcon from "../icons/facebook.png";
import InstagramIcon from "../icons/instagram.png";
import YoutubeIcon from "../icons/youtube.svg";
import { Link } from "react-router-dom";

const SocialLinks = () => {
  return (
    <div className="nav-item social-nav-item">
      <div className="nav-item-box social-media-links">
        <Link
          to="https://www.facebook.com/profile.php?id=61561452261888"
          target="_blank"
          className="social-media-link"
        >
          <img
            src={FacebookIcon}
            alt="facebook-icon"
            className="nav-icon"
			title='facebook-icon'
          />
        </Link>
        <Link
          to="https://www.instagram.com/kangacook_official/?igsh=bjA3cXR3bzJ3eXZi"
          target="_blank"
          className="social-media-link"
        >
          <img
            src={InstagramIcon}
            alt="instagram-icon"
			title='instagram-icon'
            className="nav-icon"
          />
        </Link>
        <Link
          to="https://www.youtube.com/channel/UC_DgEDskMZgKKP9wlUe1xgw"
          target="_blank"
          className="social-media-link"
        >
          <img
            src={YoutubeIcon}
            alt="youtube-icon"
			title='youtube-icon'
            className="nav-icon"
          />
        </Link>
      </div>
    </div>
  );
};

export default SocialLinks;
