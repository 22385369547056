import React from 'react';
import SuccessIcon from '../../../components/icons/recipeCreate/check-circle.svg';

const SuccessToast = ({ message, className }) => {
  return (
    <div
      className={`fixed top-[120px] left-1/2 transform -translate-x-1/2 z-50 flex items-center gap-[8px] p-[11px] border rounded-lg bg-[#F5FBEE] border-[#669F2A] ${className}`}
    >
      <img src={SuccessIcon} alt="Alert" />
      <p
        className="text-[#3F621A] text-sm font-normal leading-[22px] tracking-[0.28px]"
        style={{ fontFamily: '"Noto Sans TC", sans-serif' }}
      >
        {message}
      </p>
    </div>
  );
};

export default SuccessToast;
