import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { signIn } from '../hook/awsAuthServices';
import { jwtDecode } from 'jwt-decode';
import { Input } from 'antd';
import { useTranslation } from "react-i18next";

export default function KangaLogin({ setErrorMessage }) { 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [placeholder, setPlaceholder] = useState('Password minimum length 8 character(s)');
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const updatePlaceholder = () => {
            if (window.innerWidth < 768) {
                setPlaceholder('minimum length 8 character(s)');
            } else {
                setPlaceholder('Password minimum length 8 character(s)');
            }
        };

        window.addEventListener('resize', updatePlaceholder);

        // Initial check
        updatePlaceholder();

        return () => window.removeEventListener('resize', updatePlaceholder);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const session = await signIn(email, password);
            const decoded = jwtDecode(session.IdToken);
            const kangacook_user_id = { userId: decoded.sub };
            const apiHost = process.env.REACT_APP_BACKEND_URL;
            Cookies.set("kangacook_user_id", JSON.stringify(kangacook_user_id), {
                expires: 90,
                domain: process.env.REACT_APP_COOKIE_URL,
            });
              

			const usernameRes = await fetch(
				`${apiHost}/user-identity/get_username`,
				{
					credentials: 'include',
				}
			)
			const data = await usernameRes.json()
			kangacook_user_id['username'] = data.username
            Cookies.set("kangacook_user_id", JSON.stringify(kangacook_user_id), {
                expires: 90,
                domain: process.env.REACT_APP_COOKIE_URL,
            });
              
			
            navigate('/home');
        } catch (error) {
            // 当登录失败时，设置错误消息
            setErrorMessage(t("kangaLogin.errorMessage"));
        }
    };

    return (
        <form className="kanga-login" onSubmit={handleSubmit}>
            <div className='input-email'>{t("kangaLogin.inputEmail")}</div>
            <input 
                type="email" 
                placeholder="Email" 
                className="email-input" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
            />
            <div className='input-password'>{t("kangaLogin.inputPassword")}</div>
            <Input.Password 
                placeholder={placeholder} 
                className="password-input" 
                value={password}
                prefix={null}  
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ paddingLeft: '16px' }}  
            />
            <button type="submit" className="login-button">
                {t("kangaLogin.submit")}
            </button>
        </form>
    );
}
