import CollectionContent from './CollectionContent/CollectionContent'
import {useParams} from 'react-router-dom'
import './Collection.css'

import Header from '../../components/Header'
import NavigationBar from '../../components/NavigationBar/NavigationBar'

export default function Collection(){
	const { pk } = useParams();
	return (
		<div>
			<Header />
			<NavigationBar />
			<div className='user-main-content'>
				<div className='user-main-collection-content'>
					<CollectionContent pk={pk}/>
				</div>
			</div>
		</div>
	)
}

