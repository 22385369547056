import './Search.css'
import search_icon from '../../icons/search.svg'
import filter_icon from '../../icons/filter.svg'
import x_icon from '../../icons/x.svg'
import '../../../components/components.css'
import {useState, useEffect, useRef} from 'react'
import axios from 'axios'
import { useTranslation } from "react-i18next";


export default function Search({tagChange, isTagChange, setRecipes}){
	// Remove hard code for tags in serch bar
	const [tags, setTags] = useState([])
	// const [tags, setTags] = useState(['tag 1', 'tag 2', 'tag 3'])

	const [suggestion, setSuggestion] = useState([])

	const [searchType, setSearchType] = useState('recipe')
	const [searchStr, setSearchStr] = useState('')

	const [filters, setFilters] = useState('suggested')
	const [tempFilters, setTempFilters] = useState('suggested')
	const [isFiltering, setIsFiltering] = useState(false)
	const { t } = useTranslation();

	const toggleFilter = ()=>{
		setTempFilters(filters)
		setIsFiltering(!isFiltering)
	}
	const confirmFilter = ()=>{
		setFilters(tempFilters)
		setIsFiltering(false)
	}
	const resetFilter = ()=>{
		setFilters('suggested')
		setTempFilters('suggested')
	}

	const searchRef = useRef(null)
	const searchRef_sm = useRef(null)
	const suggestionRef = useRef(null)
	const selectionRef = useRef(null)

	useEffect(() => {
		const searchBar = searchRef.current;
		const searchBar_sm = searchRef_sm.current;

		const handleFocus = () => {
			if (selectionRef.current){
				selectionRef.current.classList.remove('hidden')
			}
			if (suggestionRef.current){
				suggestionRef.current.classList.remove('hidden')
			}
		}
		const handleBlur = () => {
			if (selectionRef.current && suggestionRef.current){
			}
		}

		const handleClick = (e) => {
			if(selectionRef.current && suggestionRef.current &&
				searchRef.current && searchRef_sm.current
			){
				if(
					!selectionRef.current.contains(e.target) &&
					!suggestionRef.current.contains(e.target) &&
					!searchRef_sm.current.contains(e.target) &&
					!searchRef.current.contains(e.target)
				){
					selectionRef.current.classList.add('hidden')
					suggestionRef.current.classList.add('hidden')
				}
			}
		}
		document.addEventListener('click', handleClick)
		searchBar.addEventListener('focus', handleFocus)
		searchBar.addEventListener('input', handleFocus)

		searchBar_sm.addEventListener('focus', handleFocus)
		searchBar_sm.addEventListener('input', handleFocus)
		
		return () => {
			document.removeEventListener('click', handleClick)
			searchBar.removeEventListener('focus', handleFocus)
			searchBar.removeEventListener('blur', handleBlur)

			searchBar_sm.removeEventListener('focus', handleFocus)
			searchBar_sm.removeEventListener('blur', handleBlur)
		}
	}, [])
	useEffect(() => {
		if(tagChange){
			// Since tags are not expected to be long, 
			//   for loop is enough to find if element in array
			for (let i = 0; i < tags.length; i++){
				if(tags[i] === tagChange){
					tags.splice(i, 1)
					setTags([...tags])
					return
				}
			}
			setTags([...tags, tagChange])
		}
	}, [isTagChange]);



	const searchItem = async (e)=>{
		let searchString = e.target.value
		setSearchStr(searchString)
		searchString = searchString.toLowerCase();
		if (!searchString){
			setSuggestion([])
			return
		}
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/recipe/search-suggest/?query=${searchString}`,
			{
				credentials: 'include',
			}
		)
		const data = await response.json()
		if(searchString){
			setSuggestion(data.suggestions)
		}
		else{
			setSuggestion([])
		}
	}
	const deleteTag = (idx) => {
		tags.splice(idx, 1)
		setTags([...tags])
	}
	const toggleSearch = async (e)=>{
		e.preventDefault();
		try {
			let searchString = `${process.env.REACT_APP_BACKEND_URL}/recipe/?`
			if(searchType === 'recipe'){
				searchString += `title=${encodeURIComponent(searchStr)}&`
			}
			else{
				searchString += `ingredient=${encodeURIComponent(searchStr)}&`
			}
			if(filters !== 'suggested'){
				searchString += 'following=True&'
			}
			tags.forEach((tag) => searchString += ('tags='+tag+'&'))
			searchString += 'lang=en'
			console.log("search String: ", searchString)
			const response = await axios.get(searchString, { withCredentials: true });
			console.log("RESPONSE: ", response)
			setRecipes({
				next: response.data.next,
				results: response.data.results,
				history: [response.data.next],
			});  // Update the recipe list in the parent component
		} catch (error) {
			console.error('Error fetching recipes:', error);
		}
	}
	const selectChange = (e) => {
		setSearchType(e.target.value)
	}

	return (
		<div className='home-search-component'>
		<form onSubmit={toggleSearch}>
			<div className='home-search'>
				<select
					className='home-search-select select'
					name='home-search-select'
					id='home-search-select-id'
					onChange={selectChange}
				>
					<option value='recipe'> {t("search.findRecipe")} </option>
					<option value='ingredient'> {t("search.findIngredient")} </option>
				</select>

				<div
					className='home-search-bar-container'
				>
					<label
						className='home-search-bar-icon-container'
						htmlFor='home-search-submit'
					>
						<img src={search_icon} className='home-search-bar-icon' alt='search icon' />
					</label>
					{
						tags.map((item, index) => {
							return (
								<div 
									key={index}
									className='s-gray-tag-container'
									onClick={()=>deleteTag(index)}
								>
									<p className='s-gray-tag-text'>{item}</p>
									<img src={x_icon} className='s-gray-tag-icon' alt='x-icon'/>
								</div>
							)
						})
					}
					<input
						autoComplete='off'
						className='home-search-bar'
						name='search[]'
						ref={searchRef}
						onChange={(e) => searchItem(e)}
						placeholder={t("search.searchYourRecipe")}
						value={searchStr}
					/>
					<input
						autoComplete='off'
						className='home-search-bar-sm'
						ref={searchRef_sm}
						name='search[]'
						onChange={(e) => searchItem(e)}
						placeholder={t("search.search")}
						value={searchStr}
					/>
				</div>
				
				<span className='home-search-search'>
					<div
						className='secondary-text-wrapper'
					>
						<label
							htmlFor='home-search-submit'
							className='secondary-text'
						>
							{t("search.search")}
						</label>
					</div>
				</span>
				<span className='home-search-filter'>
					<div
						onClick={toggleFilter}
						className='secondary-text-wrapper'
					>
						<img src={filter_icon} className='secondary-text-icon' alt='filter-icon'/>
						<p className='secondary-text'>
							{t("search.filter")}
						</p>
					</div>
				</span>
				<span className='home-search-filter-sm'>
					<div
						onClick={toggleFilter}
						className='l-tertiary-container home-search-filter-sm'
					>
						<img src={filter_icon} className='l-tertiary-icon' alt='filter-icon'/>
					</div>
				</span>
				<input id='home-search-submit' className='hidden' type='submit' />
			</div>
			
			{
				isFiltering &&
				<div className='home-search-filter-card'>
					<div className='home-search-filter-img-container'>
						<img 
							onClick={()=>setIsFiltering(false)}
							src={x_icon}
							alt='x-icon'
						/>
					</div>
					
					<div className='home-search-filter-card-header'>
						<p className='home-search-filter-card-title'>
							{t("search.recipeFilter")}
						</p>
						<p
							onClick={resetFilter}
							className='home-search-filter-card-reset'
						>
							{t("search.resetAll")}
						</p>
					</div>

					<div className='home-search-filter-card-setting'>
						<p className='home-search-filter-description'>
							{t("search.showOnFeed")}
						</p>
						<div
							className='home-search-filter-card-options'
						>
							<div 
								onClick={()=>setTempFilters('suggested')}
								className={
									tempFilters === 'suggested'?
									'tab-button-secondary-active-container':
									'tab-button-secondary-container'
								}
							>
								<p className={
									tempFilters === 'suggested'?
									'tab-button-secondary-active-text':
									'tab-button-secondary-text'
								}>{t("search.suggested")}</p>
							</div>
							<div 
								onClick={()=>setTempFilters('following')}
								className={
									tempFilters !== 'suggested'?
									'tab-button-secondary-active-container':
									'tab-button-secondary-container'
								}
							>
								<p className={
									tempFilters !== 'suggested'?
									'tab-button-secondary-active-text':
									'tab-button-secondary-text'
								}>{t("search.following")}</p>
							</div>
						</div>
					</div>

					<div className='home-search-filter-confirm'>
						<div 
							onClick={confirmFilter}
							className={
								'secondary-prime-wrapper'
							}
						>
							<p className={
								'secondary-prime-text'
							}>{t("search.confirm")}</p>
						</div>
					</div>
					
				</div>
			}

			{
			searchStr.length > 0 &&
			<div
				className='home-search-select-sm'
				ref={selectionRef}
			>
				<div
					onClick={()=>setSearchType('recipe')}
					className={
						searchType ==='recipe'?
						'flex-1 tab-button-secondary-active-container' :
						'flex-1 tab-button-secondary-container'
					}
				>
					<p 
						className={
							searchType ==='recipe'?
							'tab-button-secondary-active-text' :
							'tab-button-secondary-text'
						}
					>
						{t("search.findRecipe")}
					</p>
				</div>
				<div
					className={
						searchType !=='recipe'?
						'flex-1 tab-button-secondary-active-container' :
						'flex-1 tab-button-secondary-container'
					}
					onClick={()=>setSearchType('ingredient')}
				>
					<p 
						className={
							searchType !=='recipe'?
							'tab-button-secondary-active-text' :
							'tab-button-secondary-text'
						}
					>
						{t("search.findIngredient")}
					</p>
				</div>
			</div> 
			}
			{
				searchStr.length > 0 &&
				suggestion.length > 0 &&
			<div
				ref={suggestionRef}  
				className='home-search-dropdown-container'
			>
				<div className='dropdown-container'>
					{
						suggestion.map((item, index)=>{
							return (
								<label
									htmlFor='home-search-submit'
									key={index}
									className='dropdown-item'
									onClick={()=>{setSearchStr(item); setSuggestion([])}}
								>
									{item}
								</label>
							)
						})
					}
				</div>
			</div>
			}
		</form>
		</div>
	)
}

