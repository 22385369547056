import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { confirmForgotPassword, resendConfirmationCode } from '../hook/awsAuthServices';
import './ForgotPasswordVerification.css';  
import { useTranslation } from "react-i18next";

export default function ForgotPasswordVerification({ email, setErrorMessage }) {
  const navigate = useNavigate();
  const [otpValues, setOtpValues] = useState(new Array(6).fill(''));
  const [timer, setTimer] = useState(120); // 初始倒计时时间
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [password, setPassword] = useState(''); // 添加密码状态

  const inputRefs = useRef([]); // 用于存储输入框的refs
  const { t } = useTranslation();

  useEffect(() => {
    if (isResendDisabled) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 1) {
            return prevTimer - 1;
          } else {
            clearInterval(interval);
            setIsResendDisabled(false); // 倒计时结束，启用Resend按钮
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(interval); // 清除定时器
    }
  }, [isResendDisabled]);

  const handleChange = (value, index) => {
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    if (value && index < otpValues.length - 1 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
    } else if (!value && index > 0 && inputRefs.current[index - 1]) {
        inputRefs.current[index - 1].focus();
    }
  };

  const onFinish = async (values) => {
    const otp = otpValues.join('');  // 将验证码拼接成字符串
    const newPwd = values.password;  // 获取新密码

    try {
        // 直接调用 confirmForgotPassword，提交验证码和新密码进行验证和重置密码操作
        await confirmForgotPassword(email, otp, newPwd); 
        navigate('/login');  // 成功后跳转到登录页面
    } catch (error) {
      // Check the error type and show a specific message if it's a code mismatch
      if (error.name === 'CodeMismatchException' || error.message.includes('Invalid code')) {
          setErrorMessage('The verification code is incorrect. Please check and try again.');
      } else {
          setErrorMessage('Failed to reset password. Please try again later.');
      }
  }
  };

  const onResend = async () => {
    try {
      await resendConfirmationCode(email);
      setTimer(120); // 重置计时器
      setIsResendDisabled(true); // 重新禁用按钮
    } catch (error) {
      console.log(`Resend confirmation code error: ${error}`);
    }
  };

  return (
    <>
      <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
        <Form.Item 
          label={t("forgotPasswordVerification.verificationCode")}
          name="verificationCode" 
          style={{ marginBottom: 0 }}
        >
          <div className="otp-container">
            <div className="otp-input-container">
              {otpValues.map((value, index) => (
                <Input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  className={`otp-input ${value ? 'filled' : ''}`}
                  maxLength={1}
                  value={value}
                  onChange={(e) => handleChange(e.target.value, index)}
                  placeholder="0"
                />
              ))}
            </div>
            <Button
              type="text"
              onClick={onResend}
              className="resend-button"
              disabled={isResendDisabled}
            >
              {isResendDisabled ? `Resend (${timer}s)` : 'Resend'}
            </Button>
          </div>
        </Form.Item>

        <Form.Item
          label={t("forgotPasswordVerification.resetNewPassword")}
          name="password"
          className="reset-password-form-item"
        >
          <Input.Password
            type="password"
            placeholder="Password minimum length 8 character(s)"
            className="custom-password-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Button type="primary" htmlType="submit" className="custom-submit-button">
          {t("forgotPasswordVerification.submit")}
        </Button>
      </Form>
    </>
  );
}
