import MyRecipes from './MyRecipes/MyRecipes'
import SavedRecipes from './SavedRecipes/SavedRecipes'
import LikedRecipes from './LikedRecipes/LikedRecipes'
import './Recipes.css'
import '../../../../components/components.css'
import { useTranslation } from "react-i18next";

export default function Recipes(props){
	const { t } = useTranslation();
	const mine_str = 'mine'
	const saved_str = 'saved'
	const liked_str = 'liked'
	

	return (
		<>
		<div className='user-profile-recipes'>
			<div className='user-profile-recipes-selection-container'>
				<div className='user-profile-recipes-selection'>
					<div 
						className={
							props.selectedTab===mine_str?
								'tab-button-active-container'
								: 
								'tab-button-container'
							}
							onClick={() => props.setSelectedTab(mine_str)}
					>
						<p 
							className={
								props.selectedTab===mine_str?
									'tab-button-active-text'
									:
									'tab-button-text'
							}
						>
							{t("recipes.myRecipes")}
						</p>
					</div>
					<div 
						className={
							props.selectedTab===saved_str?
								'tab-button-active-container'
								: 
								'tab-button-container'
							}
							onClick={() => props.setSelectedTab(saved_str)}
					>
						<p 
							className={
								props.selectedTab===saved_str?
									'tab-button-active-text'
									:
									'tab-button-text'
							}
						>
							{t("recipes.savedRecipes")}
						</p>
					</div>
					<div 
						className={
							props.selectedTab===liked_str?
								'tab-button-active-container'
								: 
								'tab-button-container'
							}
							onClick={() => props.setSelectedTab(liked_str)}
					>
						<p 
							className={
								props.selectedTab===liked_str?
									'tab-button-active-text'
									:
									'tab-button-text'
							}
						>
							{t("recipes.likedRecipes")}
						</p>
					</div>
				</div>
			</div>
	
			{
				props.selectedTab === mine_str &&
				<MyRecipes
					collections={props.collections}
					toggleCollection={props.toggleCollection}
					setToggleCollection={props.setToggleCollection}
					recipes={props.recipes}
					selected={props.selected}
					setSelected={props.setSelected}
					owner={true}
				/>
			}
	
			{
				props.selectedTab === saved_str &&
				<SavedRecipes
					collections={props.collections}
					toggleCollection={props.toggleCollection}
					setToggleCollection={props.setToggleCollection}
				/>
			}

			{
				props.selectedTab === liked_str &&
				<LikedRecipes 
					recipes={props.likedRecipes}
					collections={props.collections}
					toggleCollection={props.toggleCollection}
					setToggleCollection={props.setToggleCollection}
				/>
			}

		</div>
		</>
	)
}

