import React, { useState, useEffect, useRef } from 'react';
import UploadIcon from '../../../components/icons/recipeCreate/upload.svg';
import ErrorUploadIcon from '../../../components/icons/recipeCreate/errorUpload.svg';
import AlertIcon from '../../../components/icons/recipeCreate/alert.svg';
import { useTranslation } from "react-i18next";

// Helper function to get a cookie value by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

// Map language codes to corresponding values
const mapLanguage = (lang) => {
  const langMap = {
    'zh-TW': 'zh_tw',
    'zh-CN': 'zh', // Adjust mapping if needed
  };
  return langMap[lang] || lang.toLowerCase(); // Fallback to lowercase if not mapped
};

const RecipeCreateStepOne = ({ onNext, setFormData, formData }) => {
  const { t } = useTranslation();
  const [lang, setLang] = useState(mapLanguage(getCookie('lang') || 'en'));
  const [recipeName, setRecipeName] = useState(formData[`title`] || '');
  const [description, setDescription] = useState(
    formData[`description`] || ''
  );
  const [tags, setTags] = useState(formData.tags || []);
  const [tagInputValue, setTagInputValue] = useState('');
  const [cookingTime, setCookingTime] = useState(formData.cook_time || '');
  const [servings, setServings] = useState(formData.servings || '');
  const [image, setImage] = useState(
    formData.cover_picture ? formData.cover_picture : null
  );
	useEffect(() => {
		setImage(
			formData.cover_picture ? formData.cover_picture : null
		)
		setTags(formData.tags)
		setCookingTime(formData.cook_time)
		setServings(formData.servings)
	}, [formData])
  const fileInputRef = useRef(null);

  const [errors, setErrors] = useState({});
  const [isComposing, setIsComposing] = useState(false);

  // Update recipe name and description when the language changes
  useEffect(() => {
    setRecipeName(formData[`title`] || '');
    setDescription(formData[`description`] || '');
  }, [lang, formData]);

  // Reflect changes immediately into formData using useEffect
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [`title`]: recipeName,
      [`description`]: description,
      cook_time: parseInt(cookingTime, 10) || '',
      servings: parseInt(servings, 10) || '',
      tags,
      cover_picture: image,
    }));
  }, [
    recipeName,
    description,
    tags,
    cookingTime,
    servings,
    image,
    lang,
    setFormData,
  ]);

  // Monitor changes to the language cookie
  useEffect(() => {
    const interval = setInterval(() => {
      const currentLang = mapLanguage(getCookie('lang') || 'en');
      if (currentLang && currentLang !== lang) {
        setLang(mapLanguage(currentLang));
      }
    }, 1000); // Check every second for cookie updates

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [lang]);

  // Available tags for the recipe
  const availableTags = [
    { value: 'Chinese', label: t("tags.chinese") },
    { value: 'Taiwanese', label: t("tags.taiwanese") },
    { value: 'Japanese', label: t("tags.japanese") },
    { value: 'Korean', label: t("tags.korean") },
    { value: 'Indian', label: t("tags.indian") },
  ];

  // Handle tag selection and deselection
  const handleTagClick = (tag) => {
    if (tags.includes(tag)) {
      setTags(tags.filter((t) => t !== tag)); // Remove tag if it's already selected
    } else {
      setTags([...tags, tag]); // Add new tag to the list
    }
  };

  // Handle tag input via keyboard
  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' && tagInputValue.trim() && !isComposing) {
      // 仅在 tagInputValue 存在且未重复时添加
      if (!tags.includes(tagInputValue.trim())) {
        setTags([...tags, tagInputValue.trim()]);
      }
      setTagInputValue(''); // 清空输入框
      e.preventDefault(); // 阻止默认行为
    }
  };

  const handleCompositionStart = () => {
    setIsComposing(true);
  };
  
  const handleCompositionEnd = () => {
    setIsComposing(false);
    if (tagInputValue.trim() && !tags.includes(tagInputValue.trim())) {
      setTags([...tags, tagInputValue.trim()]);
      setTagInputValue(''); // 清空输入框
    }
  };

  // Convert image to Base64 format
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file); // Read the file as Data URL (Base64)
      reader.onload = () => resolve(reader.result); // Resolve with Base64 string
      reader.onerror = (error) => reject(error); // Reject if error occurs
    });
  };

  // Handle image upload and validation
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/heic'];

      if (!validTypes.includes(file.type)) {
        setErrors({
          ...errors,
          image: t("recipeCreateStepOne.errorMessageRestrictedFormat"), // Validate file type
        });
        setImage(null);
      } else if (file.size > 10 * 1024 * 1024) {
        setErrors({ ...errors, image: t("recipeCreateStepOne.errorMessageFileSizeExceeds") }); // Validate file size
        setImage(null);
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.image; // Clear image errors if valid
          return newErrors;
        });

        const base64Image = await convertToBase64(file); // Convert image to Base64
        setImage(base64Image); // Store Base64 image
      }
    }
  };

  // Handle drag-and-drop image upload
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleImageChange({ target: { files: [file] } });
    }
  };

  // Handle drag over event to allow file drop
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleCookingTimeChange = (e) => {
    const value = e.target.value;
    // Allow empty string or positive integers
    if (value === '' || /^[1-9]\d*$/.test(value)) {
      setCookingTime(value);
    }
  };

  const handleServingsTimeChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^[1-9]\d*$/.test(value)) {
      setServings(value);
    }
  };


  // Validate the form before moving to the next step
  const validateForm = () => {
    const newErrors = {};

    if (!recipeName.trim()) {
      newErrors.recipeName = t("recipeCreateStepOne.errorMessageAddRecipeName"); // Check if recipe name is provided
    } else if (recipeName.length > 200) {
      newErrors.recipeName = t("recipeCreateStepOne.errorMessageTooManyChars"); // Validate name length
    }

    if (!image) {
      newErrors.image = t("recipeCreateStepOne.errorMessageUploadCorrectFormat"); // Check if image is uploaded
    }

    if (!description.trim()) {
      newErrors.description = t("recipeCreateStepOne.errorMessageFillDescription"); // Check if description is provided
    }

    if (
      !cookingTime ||
      !cookingTime.toString().trim() ||
      isNaN(cookingTime) ||
      parseInt(cookingTime) <= 0
    ) {
      newErrors.cookingTime = t("recipeCreateStepOne.errorMessagePositiveTime"); // Validate cooking time
    }

    if (
      !servings ||
      !servings.toString().trim() ||
      isNaN(servings) ||
      parseInt(servings) <= 0
    ) {
      newErrors.servings = t("recipeCreateStepOne.errorMessagePositiveNum"); // Validate servings
    }

    setErrors(newErrors); // Update error state
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Handle moving to the next step
  const handleNext = () => {
    if (validateForm()) {
      onNext(); // Proceed to the next step if form is valid
    }
  };

  // Automatically update error state based on input changes
  useEffect(() => {
    const newErrors = { ...errors };

    if (recipeName && recipeName.trim() && recipeName.length <= 200) {
      delete newErrors.recipeName; // Clear recipe name error if valid
    }
    if (description && description.trim()) {
      delete newErrors.description; // Clear description error if valid
    }
    if (tags.length > 0) {
      delete newErrors.tags; // Clear tags error if valid
    }
    if (cookingTime && parseInt(cookingTime, 10) > 0) {
      delete newErrors.cookingTime; // Clear cooking time error if valid
    }
    if (servings && parseInt(servings, 10) > 0) {
      delete newErrors.servings; // Clear servings error if valid
    }
    if (image) {
      delete newErrors.image; // Clear image error if valid
    }

    setErrors(newErrors); // Update error state
  }, [recipeName, description, tags, cookingTime, servings, image]);

  return (
    <div>
      <h4 className="text-[24px] font-normal leading-[40px] tracking-[0.48px] text-primary-600">
        {t("recipeCreateStepOne.title")}
      </h4>

      <div className="h-10"></div>
      <div className="flex flex-col gap-[20px]">
        {/* Recipe Name Input */}
        <div className="flex flex-col gap-[6px]">
          <label className="text-gray-700">{t("recipeCreateStepOne.addRecipesName")}</label>
          <input
            type="text"
            placeholder={t("recipeCreateStepOne.recipesName")}
            value={recipeName}
            onChange={(e) => setRecipeName(e.target.value)}
            className={`w-full mt-2 p-[9px_16px] border rounded-lg ${
              errors.recipeName ? 'border-red-500' : 'border-gray-300'
            }`}
          />
          {errors.recipeName && (
            <div className="flex">
              <img src={AlertIcon} alt="Alert" className="mr-2" />
              <p
                className="text-red-500 text-sm font-normal leading-[22px] tracking-[0.28px]"
                style={{
                  fontFamily: '"Noto Sans TC", sans-serif',
                }}
              >
                {errors.recipeName}
              </p>
            </div>
          )}
        </div>

{/* Upload Image */}
<div>
  <div
    className={`border p-[16px] rounded-lg text-center bg-white flex flex-col h-[256px] ${
      errors.image ? 'border-red-500' : 'border-gray-300'
    }`}
    onDrop={handleDrop}
    onDragOver={handleDragOver}
  >
    <input
      type="file"
      className="hidden"
      ref={fileInputRef}
      onChange={handleImageChange}
    />
    {image ? (
      <div className="relative w-full h-full">
        {/* 显示图片 */}
        <img
          src={image}
          alt="Preview"
          className="w-full h-full rounded-lg object-scale-down hover:cursor-pointer object-contain"
          onClick={() => fileInputRef.current.click()}
        />
        {/* 右上角的删除按钮 */}
        <button
          className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
          onClick={() => setImage(null)}  // 点击时删除图片
        >
          &times;
        </button>
      </div>
    ) : (
      <div className="flex flex-col gap-[12px] items-center">
        {errors.image ? (
          <button
            className="p-2 rounded-full bg-error-100 shadow-xs"
            onClick={() => fileInputRef.current.click()}
          >
            <img src={ErrorUploadIcon} alt="Upload" />
          </button>
        ) : (
          <button
            className="flex justify-center items-center w-[40px] h-[40px] rounded-full bg-gray-100 shadow-xs"
            onClick={() => fileInputRef.current.click()}
          >
            <img src={UploadIcon} alt="Upload" />
          </button>
        )}
        <p
          className={`${errors.image ? 'text-red-500' : 'text-gray-400'} font-normal text-[14px] leading-[22px] tracking-[0.28px]`}
        >
          {t("recipeCreateStepOne.uploadYourImage")}
        </p>
        <p
          className={`${errors.image ? 'text-red-500' : 'text-gray-400'} font-normal text-[14px] leading-[22px] tracking-[0.28px]`}
        >
          {t("recipeCreateStepOne.restrictedFormats")}
        </p>
        <button
          className={`mt-4 px-4 py-2 w-fit bg-white border ${errors.image ? 'text-red-500 border-error-300' : 'border-gray-300'} font-normal text-[14px] leading-[22px] tracking-[0.28px] rounded-full shadow-sm h-[44px] min-w-[80px]`}
          onClick={() => fileInputRef.current.click()}
        >
          {t("recipeCreateStepOne.browse")}
        </button>
      </div>
    )}
  </div>
          {errors.image && (
            <div className="flex">
              <img src={AlertIcon} alt="Alert" className="mr-2" />
              <p
                className="text-red-500 text-sm font-normal leading-[22px] tracking-[0.28px]"
                style={{
                  fontFamily: '"Noto Sans TC", sans-serif',
                }}
              >
                {errors.image}
              </p>
            </div>
          )}
        </div>

        {/* Description Input */}
        <div>
          <label className="block text-gray-700">{t("recipeCreateStepOne.description")}</label>
          <textarea
            placeholder={t("recipeCreateStepOne.pleaseEnterContent")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={`w-full mt-2 px-3 py-2 border rounded-lg h-32 resize-none ${
              errors.description ? 'border-red-500' : 'border-gray-300'
            }`}
          ></textarea>
          {errors.description && (
            <div className="flex">
              <img src={AlertIcon} alt="Alert" className="mr-2" />
              <p
                className="text-red-500 text-sm font-normal leading-[22px] tracking-[0.28px]"
                style={{
                  fontFamily: '"Noto Sans TC", sans-serif',
                }}
              >
                {errors.description}
              </p>
            </div>
          )}
        </div>

        {/* Tags */}
        <div>
          <label className="block text-gray-700">{t("recipeCreateStepOne.Tags")}</label>
          {/* <label className="block text-gray-500 mt-[6px]">
            Tag your dish with some keywords!
          </label> */}

          <div
            className={`flex items-center self-stretch p-[9px_16px] flex-wrap border rounded-lg mt-2 bg-white ${
              errors.tags ? 'border-red-500' : 'border-gray-300'
            }`}
          >
            {tags.map((tag) => (
              <div
                key={tag}
                className="flex items-center bg-gray-100 text-gray-700 border border-gray-300 rounded-[8px] px-2 py-1 mr-2 mb-2"
              >
                <span>{tag}</span>
                <button
                  onClick={() => handleTagClick(tag)}
                  className="ml-2 text-gray-500 hover:text-gray-700"
                >
                  &times;
                </button>
              </div>
            ))}

            <input
              type="text"
              placeholder={t("recipeCreateStepOne.selectOrTyprSomeKeywords")}
              value={tagInputValue}
              onChange={(e) => setTagInputValue(e.target.value)}
              onKeyDown={handleInputKeyDown}
              onCompositionStart={handleCompositionStart} // 开始输入法事件
              onCompositionEnd={handleCompositionEnd}     // 结束输入法事件
              className="flex-grow p-2 border-none focus:ring-0 outline-none"
            />
          </div>
          {errors.tags && (
            <div className="flex mt-[6px]">
              <img src={AlertIcon} alt="Alert" className="mr-2" />
              <p
                className="text-red-500 text-sm font-normal leading-[22px] tracking-[0.28px]"
                style={{
                  fontFamily: '"Noto Sans TC", sans-serif',
                }}
              >
                {errors.tags}
              </p>
            </div>
          )}

          {/* availableTags */}
          <div className="flex flex-wrap gap-2 mt-4">
            {availableTags.map(
                (item) =>
                    !tags.includes(item.value) && (
                        <button
                            key={item.value}
                            onClick={() => handleTagClick(item.value)}
                            className="w-fit flex h-[36px] px-[12px] justify-center items-center gap-[8px] border rounded-[8px] bg-yellow-50 text-yellow-600 border-yellow-400"
                        >
                            {item.label}
                        </button>
                    )
            )}
          </div>
        </div>

        {/* Cooking Times */}
        <div className="flex flex-col gap-[6px] w-full">
          <label className="text-gray-700">{t("recipeCreateStepOne.cookingTimes")}</label>
          <div className="flex items-center border rounded-lg w-full">
            <input
              type="number"
              placeholder={t("recipeCreateStepOne.Minutes")}
              value={cookingTime}
              onChange={handleCookingTimeChange}
              className={`w-full p-[9px_16px] border-none focus:ring-0 ${
                errors.cookingTime ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            <span className="p-2 border rounded-r-[8px] font-thin text-gray-500" style={{ whiteSpace: 'nowrap' }}>
              {t("recipeCreateStepOne.minutes")}
            </span>
          </div>
          {errors.cookingTime && (
            <div className="flex">
              <img src={AlertIcon} alt="Alert" className="mr-2" />
              <p
                className="text-red-500 text-sm font-normal leading-[22px] tracking-[0.28px]"
                style={{
                  fontFamily: '"Noto Sans TC", sans-serif',
                }}
              >
                {errors.cookingTime}
              </p>
            </div>
          )}
        </div>

        {/* Servings */}
        <div className="flex flex-col gap-[6px] w-full">
          <label className="text-gray-700">{t("recipeCreateStepOne.Servings")}</label>
          <div className="flex items-center border rounded-lg w-full">
            <input
              type="number"
              placeholder={t("recipeCreateStepOne.numberOfPeople")}
              value={servings}
              onChange={handleServingsTimeChange}
              className={`w-full p-[9px_16px] border-none focus:ring-0 ${
                errors.servings ? 'border-red-500' : 'border-gray-300'
              }`}
            />
            <span className="p-2 border rounded-r-[8px] font-thin text-gray-500" style={{ whiteSpace: 'nowrap' }}>
              {t("recipeCreateStepOne.servings")}
            </span>
          </div>
          {errors.servings && (
            <div className="flex">
              <img src={AlertIcon} alt="Alert" className="mr-2" />
              <p
                className="text-red-500 text-sm font-normal leading-[22px] tracking-[0.28px]"
                style={{
                  fontFamily: '"Noto Sans TC", sans-serif',
                }}
              >
                {errors.servings}
              </p>
            </div>
          )}
        </div>

        {/* Next Button */}
        <div className="flex w-full justify-end space-x-4 mt-[28px]">
          <button
            onClick={() => {window.scrollTo({top: 0}); handleNext()}}
            className="tablet:w-fit max-w-full bg-white text-primary-600 px-4 rounded-full border border-primary-300 h-[44px]"
          >
            {t("recipeCreateStepOne.nextStep")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecipeCreateStepOne;
