import './ErrorPage.css'
import './components.css'
import lock_img from './icons/lock.svg'
import alert_img from './icons/alert-circle.svg'
import { useNavigate } from 'react-router-dom'


export function Restricted(){
	const navigate = useNavigate();
	return (
		<div className='error-page-container'>
			<div className='error-page-description'>
				<img src={lock_img} alt='lock-icon'/>
				<div className='error-page-text-container'>
					<h3>
						This page is restricted
					</h3>
					<p>
						You can't browse this page because it's restricted
					</p>
				</div>
			</div>
			<div className='error-page-button'>
				<div 
					onClick={() => navigate('/')}
					className='secondary-text-wrapper'
				>
					<p className='secondary-text'>
						Back to home
					</p>
				</div>
			</div>
		</div>
	)
}



export function NotFound(){
	const navigate = useNavigate()
	return (
		<div className='error-page-container'>
			<div className='error-page-description'>
				<img src={alert_img} alt='alert-icon'/>
				<div className='error-page-text-container'>
					<h3>
						Oops!
					</h3>
					<p>
						The page you are looking for can't be found
					</p>
				</div>
			</div>
			<div className='error-page-button'>
				<div 
					onClick={() => navigate('/')}
					className='secondary-text-wrapper'
				>
					<p className='secondary-text'>
						Back to home
					</p>
				</div>
			</div>
		</div>
	)
}



