// TODO: replace with real url
import { useTranslation } from "react-i18next";

const FooterLinks = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="footer-links">
    <a 
      href="https://kangacook-terms-and-conditions.s3.amazonaws.com/Terms+of+Use.pdf"
      target="_blank"
      rel="noopener noreferrer"
      className="nav-item"
    >
      <div className="nav-item-box">
        <p>{t("navigation.terms")}</p>
      </div>
    </a>

    <a 
      href="https://www.termsfeed.com/live/93288d56-1cc2-41a0-b02b-df39f874782b"
      target="_blank"
      rel="noopener noreferrer"
      className="nav-item"
    >
      <div className="nav-item-box">
        <p>{t("navigation.privacy")}</p>
      </div>
    </a>
  </div>
  );
};

export default FooterLinks;
