import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from '../hook/awsAuthServices';
import { useTranslation } from "react-i18next";

export default function Forgotpassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSending, setIsSending] = useState(false);  // 添加状态管理
  const { t } = useTranslation();

  const handleSubmit = async () => {
    if (isSending) return;  // 防止多次点击

    setIsSending(true);  // 设置为正在发送
    try {
      await forgotPassword(email);
      navigate('/forgotpasswordConfirm', { state: { email } });
    } catch (error) {
      console.log("Forgot password error");
    } finally {
      setIsSending(false);  // 发送完成后允许再次发送
    }
  };

  return (
    <div>
      <div className='forgotpassword-input-email'>{t("kangaForgetPassword.forgotPasswordInputEmail")}</div>
      <input 
        type="email" 
        placeholder="Email" 
        className="forgotpassword-email-input" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button 
        type="submit" 
        className="login-button" 
        onClick={handleSubmit}
        style={{ marginTop: '20px' }}
        disabled={isSending}  // 防止按钮再次点击
      >
        {t("kangaForgetPassword.receiveVerificationCode")}
      </button>
    </div>
  );
}
