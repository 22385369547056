import React from 'react';
import {Link} from 'react-router-dom';

export default function Careers() {
	return (
		<div style={{width: '80%', margin: 'auto'}}>
			<h1> Career Page </h1>
			<ul>
				<li>
					<Link to={`1`} target='_blank'> Junior Software Engineer </Link>
				</li>
				<li>
					<Link to={`2`} target='_blank'> Software Engineer Intern </Link>
				</li>
			</ul>
		</div>
	)
}


