import './AuthorInfo.css'
import '../../components/components.css'
import { useNavigate } from 'react-router-dom'
import {useState, useEffect} from 'react'
import {isAuthenticated} from '../../UserManagement/hook/Authenticated'
import default_profile from '../icons/Avatar.svg'
import { useTranslation } from "react-i18next";

export default function AuthorInfo(props){
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [following, setFollowing] = useState(props.isFollowing)

	useEffect(() => {
		setFollowing(props.isFollowing)
	}, [props.isFollowing])
	const unfollow = async () => {
		try {
			await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-network/${props.username}/`,{
				method: 'DELETE',
				credentials: 'include',
			});
		} catch (error) {
			console.error('Error unfollowing user', error);
		}
		fetchFollowing()
	}
	const follow = async () => {
		try {
			await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-network/`,{
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					following: props.username,
				})
			});
			fetchFollowing()
		} catch (error) {
			console.error('Error following user', error);
		}
	}
	const toggleFollow = async() => {
		if(!isAuthenticated()){
			navigate('/login')
			return 
		}
		if(following){
			setFollowing(false)
			unfollow()
		}
		else{
			setFollowing(true)
			follow()
		}
	}

	const fetchFollowing = async () => {
		try{
			if (!isAuthenticated()){
				return
			}
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-network/${props.username}/is_following/?username=${props.curr_username}`
			)
			if(response.status === 200){
				setFollowing(true)
			}
			else{
				setFollowing(false)
			}
		}
		catch(error){
			console.log("THIS IS ERROR")
			console.error("ERROR FETCHING IF USER IS FOLLOWING. ", error)
		}
	}


	// const author_name = 'Emily Smith'
	// let author_bio = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text Lorem Ipsum has been the industry's standard dummy text `
	// const recipes_count = 0
	// const following_count = 0
	// const follower_count = 0

	return (
		<>
		<div className='recipe-page-author-info-container-mobile '>
			<div className='recipe-page-author-container'>
				<div 
					className='x-large-avatar-container'
					onClick={() => navigate(`/profile/${props.username}`)}
				>
					<div className='x-large-avatar-cover' />
					<img src={props.profile_picture || default_profile} className='x-large-avatar-avatar' alt='profile-picture'/>
				</div>

				<div className='recipe-page-author-name'>
					<h6> {t("recipePage.writtenby")} </h6>
					<a href={`/profile/${props.username}`}> {props.username} </a>
				</div>
			</div>

			<div className='recipe-page-author-details'>
				<div className='recipe-page-author-info'>
					<div className='recipe-page-author-stats-container'>
						<div className='recipe-page-author-stat'>
							<p>{props.recipes}</p>
							<p> {t("recipePage.recipes")} </p>
						</div>
						<div className='recipe-page-author-stat'>
							<p>{props.followers}</p>
							<p> {t("recipePage.followers")} </p>
						</div>
						<div className='recipe-page-author-stat'>
							<p>{props.followings}</p>
							<p> {t("recipePage.followings")} </p>
						</div>
					</div>
				</div>
				{
					props.bio && 
					<p
						className='recipe-page-author-bio'
					>
						{props.bio}
					</p>
				}
				{
					props.username !== props.curr_username &&
					<div
						className='recipe-page-follow-button-container'
						onClick={toggleFollow}
					>
						{
						following?
						<div
							className='secondary-text-wrapper'
							onClick={toggleFollow}
						>
							<p className='secondary-text '>
								{t("recipePage.following")}
							</p>
						</div>
						:
						<div
							className='secondary-prime-wrapper'
							onClick={toggleFollow}
						>
							<p className='secondary-prime-text'>
								{t("recipePage.follow")}
							</p>
						</div>
						}
					</div>
				}
			</div>
		</div>

		<div className='recipe-page-author-info-container'>
			<div className='recipe-page-author-details'>
				<div 
					className='x-large-avatar-container'
					onClick={() => navigate(`/profile/${props.username}`)}
				>
					<div className='x-large-avatar-cover' />
					<img src={props.profile_picture || default_profile} className='x-large-avatar-avatar' alt='profile-picture'/>
				</div>
				<div className='recipe-page-author-info'>
					<div className='recipe-page-author-name'>
						<h6> {t("recipePage.writtenby")} </h6>
						<a href={`/profile/${props.username}`}> {props.username} </a>
					</div>
					<div className='recipe-page-author-stats-container'>
						<div className='recipe-page-author-stat'>
							<p>{props.recipes}</p>
							<p> {t("recipePage.recipes")} </p>
						</div>
						<div className='recipe-page-author-stat'>
							<p>{props.followers}</p>
							<p> {t("recipePage.followers")} </p>
						</div>
						<div className='recipe-page-author-stat'>
							<p>{props.followings}</p>
							<p> {t("recipePage.followings")} </p>
						</div>
					</div>
				</div>
				{
					props.username !== props.curr_username &&
					<div
						className='recipe-page-follow-button-container'
					>
						{
						following?
						<div
							className='secondary-text-wrapper'
							onClick={toggleFollow}
						>
							<p className='secondary-text '>
								{t("recipePage.following")}
							</p>
						</div>
						:
						<div
							className='secondary-prime-wrapper'
							onClick={toggleFollow}
						>
							<p className='secondary-prime-text'>
								{t("recipePage.follow")}
							</p>
						</div>
						}
					</div>
				}
			</div>
			{
				props.bio && 
				<p
					className='recipe-page-author-bio'
				>
					{props.bio}
				</p>
			}

		</div>
		</>
	)
}

