import { Link } from "react-router-dom";

const NavigationItem = ({ icon, title, isActive, path, children, onClick }) => {
  return (
    <Link
      to={path}
      className={`nav-item ${isActive ? "item-active" : ""}`}
      onClick={onClick}
    >
      <div className="nav-item-box">
        <img
          src={icon}
          className={`nav-icon ${isActive ? "icon-active" : ""}`}
          alt="Icon"
		  title={title || "Icon"}
        />
        <p>{children}</p>
      </div>
    </Link>
  );
};

export default NavigationItem;
