import "./HomePage.css";
import { Helmet } from "react-helmet";
import Search from "./components/Search/Search";
import Tags from "./components/Tags/Tags";
import Recipes from "./components/Recipes/Recipes";
import { useState } from "react";
import { useEffect } from "react";

import Header from "../components/Header";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import { useTranslation } from "react-i18next";

export default function HomePage() {
  const { t } = useTranslation();
  const [changeLang, setChangeLang] = useState(false);
  const [tagChange, setTagChange] = useState("");
  const [isTagChange, setIsTagChange] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [recipes, setRecipes] = useState({
    next: `/recipe`,
    results: [],
    history: [`/recipe`],
  });
  const resetRecipe = () => {
    recipes.next = `/recipe`;
    recipes.results = [];
    recipes.history = [`/recipe`];

    setRecipes({
      next: `/recipe`,
      results: [],
      history: [`/recipe`],
    });
  };
  const resetSearch = async () => {
    resetRecipe();
    fetchRecipes();
    setIsFetching(true);
  };
  const fetchRecipes = async () => {
    try {
      const apiHost = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(
        `${apiHost}/recipe/?${recipes.next.split("?")[1] || ""}`,
        {
          method: "GET",
          credentials: "include",
        },
      );
      const data = await response.json();
      if (!recipes.history.includes(data.next)) {
        recipes.history.push(data.next);
        recipes.next = data.next;
        recipes.results = recipes.results.concat(data.results);
        setRecipes({ ...recipes });
      }
    } catch (error) {
      console.error("Error fetching user information:", error);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (!isFetching) {
      resetRecipe();
      fetchRecipes();
      setIsFetching(true);
    }
  }, [changeLang]);

  useEffect(() => {
    const scrollFunc = async () => {
      if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        if (!isFetching) {
          if (recipes.next) {
            fetchRecipes();
            setIsFetching(true);
          }
        }
      }
    };
    window.addEventListener("scroll", scrollFunc);
    return () => {
      window.removeEventListener("scroll", scrollFunc);
    };
  }, [isFetching, recipes]);

  return (
    <div className="home-page">
      <Helmet>
        <meta
          property="og:title"
          content="Kangacook - Share Your Recipe and Food Every Day!"
        />
        <meta
          property="og:description"
          content="Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."
        />
        <meta property="og:url" content="https://kangacook.com/" />
        <meta property="og:type" content="website" />

        <meta
          name="description"
          content="Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."
        />
        <title> Kangacook - Share Your Recipe and Food Every Day! </title>
				<link rel="canonical" href="https://kangacook.com/"/>
      </Helmet>
      <Header changeLang={changeLang} setChangeLang={setChangeLang} />
      <div className="home-page-body">
        <div className="home-page-nav-bar"> Navigation </div>
        <NavigationBar />
          
        <div className="home-page-main-content">
          <Search
            tagChange={tagChange}
            isTagChange={isTagChange}
            setRecipes={setRecipes}
          />
          <Tags
            setTagChange={setTagChange}
            isTagChange={isTagChange}
            setIsTagChange={setIsTagChange}
          />
          {recipes.results && recipes.results.length >= 1 ? (
            <Recipes recipes={recipes.results} />
          ) : (
            <div className="home-page-no-result">
              <p> {t("homePage.recipeNotFound")} </p>
              <button className="secondary-prime-wrapper" onClick={resetSearch}>
                {t("homePage.discoverMoreRecipes")}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
