import { useNavigate } from "react-router-dom";
import {Helmet} from 'react-helmet'

import Button from "../components/Button";
import Header from "../components/Header";
import NavigationBar from "../components/NavigationBar/NavigationBar";

import styles from "./ConstructionPage.module.css";
import mainStyles from "../main.module.css";

import WipIcon from "../components/icons/wip.svg";
import WipIconMobile from "../components/icons/wip-mobile.svg";
import { useTranslation } from "react-i18next";

const ConstructionPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
	  <Helmet>
		  <meta property="og:title" content='Kangacook - Reward'/>
		  <meta property="og:description" content= "Kangacook Rewards! Earn your rewards by sharing your recipes or complete our challenges!"/>
		  <meta property="og:url" content="https://kangacook.com/reward/"/>
		  <meta property="og:type" content="website" />

		  <meta name="description" content="Kangacook Rewards! Earn your rewards by sharing your recipes or complete our challenges!"/>
		  <title> Kangacook - Reward </title>
      <link rel="canonical" href="https://kangacook.com/reward" />
	  </Helmet>
      <Header />
      <NavigationBar />
      <div className={mainStyles["container-wrapper"]}>
        <div className={styles["component-container"]}>
          <div className={styles["text-container"]}>
            <div className={styles.title}>{t("constructionPage.title")}</div>
            <div>{t("constructionPage.subtitle")}</div>
          </div>
          <div className={styles.card}>
            <img src={WipIcon} className={mainStyles["desktop"]} alt='page snippet'/>
            <img src={WipIconMobile} className={mainStyles["mobile"]} alt='page snippet'/>
          </div>
          <Button
            content={t("constructionPage.backToHomeButton")}
            classNames={"btn secondary default"}
            onClick={() => navigate(`/home`)}
          />
        </div>
      </div>
    </>
  );
};

export default ConstructionPage;
