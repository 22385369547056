import './LikedRecipes.css'
import RecipeCardComponent from '../../../../../components/RecipeCardComponent'
import { useTranslation } from "react-i18next";

export default function LikedRecipes(props){
	const { t } = useTranslation();
	return (
		<>
		<div className='user-profile-liked-recipes'>
			{
				props.recipes && 
				props.recipes.results.length>0?
				<div className='user-profile-liked-recipes-list'>
					{
						props.recipes.results.map((item, index) => 
							<div className='user-profile-liked-recipe-card' key={index}>
								<RecipeCardComponent
									{...item}
									collections={props.collections}
									toggleCollection={props.toggleCollection}
									setToggleCollection={props.setToggleCollection}
								/>
							</div>
						)
					}
				</div>
					:
				<div className='user-profile-liked-recipes-empty'>
					<p>{t("likedRecipes.empty")}</p>
				</div>
			}
		</div>
		</>
	)
}


