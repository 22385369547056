import './IngredientCheckbox.css'
// import check_img from '../../icons/check.svg'
import dot_img from '../../icons/Ellipse 80.svg'

export default function IngredientCheckbox(
	{name, index, states, setStates}
){
// 	const toggleStates = () =>{
// 		states[index] = ! states[index]
// 		setStates([...states])
// 	}
	return (
		<div 
			className='recipe-page-ingredient-checkbox-component'
		>
			{/*
				states[index]?
				<div
					onClick={toggleStates}
					className='recipe-page-ingredient-checkbox-active' 
				>
					<img src={check_img} />
				</div>
				:
				<div
					onClick={toggleStates}
					className='recipe-page-ingredient-checkbox' 
				/>
			*/}
			<img src={dot_img} alt='bullet-point'/>
			<p> {name} </p>
		</div>
	)
}

