import user_avatar from '../icons/Avatar.svg'
import back_img from '../icons/back.svg'
import '../../../../components/components.css'
import './Follower.css'
import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import { useTranslation } from "react-i18next";

export default function Follower({
	setViewFollower, followers, followers_count,
	toggleFollowing, setToggleFollowing,
}){
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [followerList, setFollowerList] = useState([])
	useEffect(()=>{
		if(followers){
			setFollowerList(
				followers.results.map(item => {
					return {
						profile_picture: item.profile_picture,
						name: item.username,
						following: item.follow_back,
					} 
				})
			)
			console.log(followerList)
		}
	}, [followers])


	const toggleFollow = async(index) => {
		if(followerList[index].following){
			followerList[index].following = false
			setFollowerList([...followerList])
			try {
				await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-network/${followerList[index].name}/`,{
					method: 'DELETE',
					credentials: 'include',
				});
			} catch (error) {
				console.error('Error unfollowing user', error);
			}
		}
		else{
			followerList[index].following = true
			setFollowerList([...followerList])
			try {
				await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-network/`,{
					method: 'POST',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						following: followerList[index].name,
					})
				});
			} catch (error) {
				console.error('Error unfollowing user', error);
			}
		}
		setToggleFollowing(!toggleFollowing)
	}
	return (
		<div className='user-profile-followers-component'>
			<div className='user-profile-followers-header'>
				<img src={back_img} className='secondary-icon' onClick={()=>setViewFollower(false)} alt='back-icon'/>
				<p className='user-profile-followers-text'>
					{followers_count} {followers_count>1 || 0? 'Followers' : 'Follower'}
				</p>
			</div>

			<div className='user-profile-followers-list'>
				{
					followerList.map((item, index) =>
						<div className='user-profile-followers-item' key={index}>
							<div className='user-profile-followers-details'>
								<div
									className='x-large-avatar-container'
									onClick={()=>navigate(`/profile/${item.name}`)}
								>
									<img src={item.profile_picture || user_avatar} className='x-large-avatar-avatar' alt='profile-picture'/>
									<div className='x-large-avatar-cover' />
								</div>
								<p className='user-profile-followers-name'>
									{item.name}
								</p>
							</div>

							{item.following?
								<div
									onClick={()=>toggleFollow(index)}
									className='secondary-text-wrapper'
								>
									<p className='secondary-text '>
										{t("follow.following")} 
									</p>
								</div>
								:
								<div
									onClick={()=>toggleFollow(index)}
									className='secondary-prime-wrapper'
								>
									<p className='secondary-prime-text'>
										{t("follow.follow")}
									</p>
								</div>
							}

						</div>
					)
				}
			</div>
		</div>
	)
}

