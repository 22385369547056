import './Comments.css'
import profile_img from '../icons/Avatar.svg'
import send_img from '../icons/send.svg'
import up_img from '../icons/chevron-up.svg'
import IndividualComponent from './IndividualComment'
import {useState, useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next";

export default function Comments(props){
	const { t } = useTranslation();
	const inputDisplayRef = useRef(null)
	const navigate = useNavigate();
	const textRef = useRef(null)
	const textRef_m = useRef(null)
	const inputRef = useRef(null)
	const inputRef_m = useRef(null)
	
	const [collapse, setCollapse] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [texting, setTexting] = useState(false)

	const [profilePic, setProfilePic] = useState(profile_img)

	const cookie_value = Cookies.get('kangacook_user_id')
	const username = (cookie_value && JSON.parse(cookie_value).username) || '';

	const time_now = new Date();
	function timeDifference(current, previous) {
		previous = new Date(previous)
		var msPerMinute = 60 * 1000;
		var msPerHour = msPerMinute * 60;
		var msPerDay = msPerHour * 24;
		var msPerMonth = msPerDay * 30;
		var msPerYear = msPerDay * 365;

		var elapsed = current - previous;

		if (elapsed < msPerMinute) {
			let num = Math.floor(elapsed/1000);
			return num === 1? `${num} second ago` : `${num} seconds ago`;
		}

		else if (elapsed < msPerHour) {
			let num = Math.floor(elapsed/msPerMinute);
			return num === 1? `${num} minute ago` : `${num} minutes ago`;
		}

		else if (elapsed < msPerDay ) {
			let num = Math.floor(elapsed/msPerHour);
			return num === 1? `${num} hour ago` : `${num} hours ago`;
		}

		else if (elapsed < msPerMonth) {
			let num = Math.floor(elapsed/msPerDay);
			return num === 1? `${num} day ago` : `${num} days ago`;
		}

		else if (elapsed < msPerYear) {
			let num = Math.floor(elapsed/msPerMonth);
			return num === 1? `${num} month ago` : `${num} months ago`;
		}

		else {
			let num = Math.floor(elapsed/msPerYear);
			return num === 1? `${num} year ago` : `${num} years ago`;
		}
	}

	useEffect(() => {
		async function fetchProfilePic(){
			try{
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/profile_picture`
				)
				const data = await response.json()
				data.profile_picture && setProfilePic(data.profile_picture)
			}
			catch (error){
				console.error('Error fetching profile picture', error);
			}
		}
		username && fetchProfilePic()
	}, [])

	const textOnChange = (e) => {
		textRef.current && setInputValue(textRef.current.value)
		textRef.current.style.height = 0;
		textRef.current.style.height = textRef.current.scrollHeight + 20 + 'px';
	}

	const textOnChange_m = (e) => {
		textRef_m.current && setInputValue(textRef_m.current.value)
		textRef_m.current.style.height = 0;
		textRef_m.current.style.height = textRef_m.current.scrollHeight + 20 + 'px';
	}

	const focusText = () =>{
		setTexting(true)
	}

	const sendComment = async () => {
		if(username === ''){
			navigate('/login')
			return
		}
		setTexting(false)
		try {
			await fetch(`${process.env.REACT_APP_BACKEND_URL}/comment/`,{
				method: 'POST',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					content: inputValue,
					recipe: props.recipe_id,
				})
			});
			setInputValue('')
		} catch (error) {
			console.error('Error sending comment', error);
		}
		props.setToggleComment(!props.toggleComment)
	}

	const replyComment = (name) => {
		setInputValue(`@${name} `)
		setTexting(true)
	}

	useEffect(() => {
		const blurTexting = (e) => {

			if ((inputDisplayRef.current && inputDisplayRef.current.contains(e.target)) ||
				(inputRef.current && inputRef.current.contains(e.target)) ||
				(inputRef_m.current && inputRef_m.current.contains(e.target)) ||
				(textRef.current && textRef.current.contains(e.target)) ||
				(textRef_m.current && textRef_m.current.contains(e.target)) ){

				return
			}
			setTexting(false)
		}
		if(texting){
			if(textRef.current){
				textRef.current.focus()
				textRef.current.value = ''
				textRef.current.value = inputValue
			}
			if(textRef_m.current){
				textRef_m.current.focus()
				textRef_m.current.value = ''
				textRef_m.current.value = inputValue
			}
			document.addEventListener('click', blurTexting)
		}
		return () => {
			document.removeEventListener('click', blurTexting)
		}
	}, [texting])


	useEffect(()=>{
		const scrollFunc = () => {
			if (!collapse && (window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
				props.setToggleFetchComment(!props.toggleFetchComment)
			}
		}
		window.addEventListener('scroll', scrollFunc)
		return ()=>{
			window.removeEventListener('scroll', scrollFunc)
		}
	}, [collapse, props.toggleFetchComment])
	return (
		<div className='recipe-page-comments'>
			<div className='recipe-page-comments-header'>
				<p className='recipe-page-section-title'> {t("recipePage.comments")} </p>
				<p className='recipe-page-comment-count'> ({props.comments_count}) </p>
				<div className='recipe-page-collapse'
					onClick={()=>{setCollapse(!collapse)}}
				>
				{
					collapse?
					<img style={{transform: 'rotate(180deg)'}} src={up_img} alt='collapse-icon'/>
						:
					<img src={up_img} alt='collapse-icon'/>
				}
				</div>
			</div>
		{!collapse &&
		<>
			<div className='recipe-page-comment-input-container'>
				<div 
					className='s-avatar-container cursor-default'
				>
					<img src={profilePic} className='s-avatar-avatar' alt='profile-picture'/>
				</div>
					<input
						ref = {inputDisplayRef}
						onClick={focusText}
						className={
							(!texting)?
							'recipe-page-comment-input'
							: 
							'recipe-page-comment-input-mobile'
						}
						value={inputValue}
						placeholder={t("recipePage.writeComment")}
						readOnly = 'readonly'
					/>
					<textarea
						ref = {textRef}
						className={
							(!texting)?
							'hidden'
							: 
							'recipe-page-comment-textarea'
						}
						value={inputValue}
						onChange={textOnChange}
						placeholder={t("recipePage.writeComment")}
					/>
				<div className='recipe-page-comment-submit-container' ref = {inputRef}>
					<p
						onClick={sendComment}
						className='recipe-page-comment-submit quaternary-prime-text'
					>
						{t("recipePage.post")}
					</p>
					<img
						onClick={sendComment}
						src={send_img}
						alt='send-icon'
					/>
				</div>
			</div>
			{
				props.comments.length === 0 &&
				<p className='recipe-page-comment-empty'>
					{t("recipePage.noComments")}
				</p>
			}
			{
				props.comments.length !== 0 &&
				props.comments.map((comment, index) => {
					return (
						<IndividualComponent
							originalContent={comment.content}
							profile={comment.profile_picture}
							name={comment.commenter_name}
							date={timeDifference(time_now, comment.commented_at)}
							comment_id={comment.comment_id}
							deleteComment={() => props.deleteComment(index)}
							replyComment={replyComment}
							key={index}
						/>
					)
				})
			}
			{
			texting &&
			<div className='recipe-page-comment-mobile-container'>
					<p>
						{t("recipePage.editComments")}
					</p>
					<div className='recipe-page-comment-mobile-details'>
						<div 
							className='s-avatar-container cursor-default'
						>

							<img src={profilePic} className='s-avatar-avatar' alt='profile-picture'/>
						</div>
						<textarea
							ref = {textRef_m}
							value={inputValue}
							onChange={textOnChange_m}
							placeholder={t("recipePage.writeComment")}
						/>

						<div className='recipe-page-comment-submit-container' ref = {inputRef_m}>
								<img
									onClick={sendComment}
									src={send_img}
									alt='send-icon'
								/>
						</div>
					</div>
			</div>
			}
					
		</>
}
		</div>
	)
}

