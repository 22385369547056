import './Separator.css'

export default function Separator(){
	return (
		<div className='recipe-page-section-separator'>
		</div>
	)
}


