import React from 'react';
import { useNavigate } from 'react-router-dom';
import RecipeCard from '../../../components/icons/recipeCreate/RecipeCard.svg';
import { useTranslation } from "react-i18next";

const RecipePublish = ({ recipeId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleVisitHome = () => {
    navigate(`/`); // Redirect to /recipes/{recipeId}
  };
  const handleVisitRecipe = () => {
    navigate(`/recipe/${recipeId}`); // Redirect to /recipes/{recipeId}
  };

  return (
    <div className="flex flex-col items-center gap-[48px] w-full pt-[60px] desktop:p-[80px] bg-gray-50 rounded-lg">
      <div className="flex justify-center items-center">
        {/* This could be an image or illustration to signify completion */}
        <img src={RecipeCard} alt="Published" className="rounded-lg" />
      </div>
      <h3 className="text-[30px] font-normal leading-[50px] tracking-[0.6px] text-gray-700 text-center">
        {t("recipePublish.thanksForPublishing")}
      </h3>

      <div className="flex desktop:flex-row flex-col justify-center gap-[20px] mt-[48px]">
        <button
          className="bg-white text-primary-600 border-primary-300 px-4 rounded-full border h-[44px] shadow-sm"
          onClick={handleVisitRecipe} // Add onClick handler to navigate to the recipe
        >
          {t("recipePublish.visitMyRecipe")}
        </button>
        <button
		  className="bg-white shadow-sm border-gray-300 text-gray-700 px-4 rounded-full border h-[44px]"
          onClick={handleVisitHome} // Add onClick handler to navigate to the recipe
		>
          {t("recipePublish.discoverMoreRecipes")}
        </button>
      </div>
    </div>
  );
};

export default RecipePublish;
