import React from 'react';
import Select from 'react-select';
import Header from '../components/Header';
import NavigationBar from "../components/NavigationBar/NavigationBar";
import './RewardShippingCard.css';

const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      height: '44px',
      paddingLeft: '0', // 确保内边距在 placeholder 中处理
      borderRadius: '8px',
      border: '1px solid #D0D5DD',
      fontSize: '16px',
      boxSizing: 'border-box',
      outline: 'none',
      backgroundColor: '#FFFFFF',
      appearance: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: '6px', 
      fontSize: '16px',
      color: '#98A2B3', // 确保 placeholder 的颜色与其他输入框一致
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: '8px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
  };
  

export default function RewardShippingCard() {
    const stateOptions = [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' }
    ];
    

  return (
    <>
      <div className='container-wrapper'>
        <Header className="header" />
        <NavigationBar className="nav-bar" />
        <div className='rewardshippingcard-page'>
          <div className='rewardshippingcard-container'>
            <h4 className='shipping-info-title'>Shipping Information</h4>
            <div className='name-inputs'>
              <div className='input-group'>
                <label htmlFor='first-name'>First Name</label>
                <input type='text' id='first-name' />
              </div>
              <div className='input-group'>
                <label htmlFor='last-name'>Last Name</label>
                <input type='text' id='last-name' />
              </div>
            </div>
            <div className='address-input-group'>
              <label htmlFor='shipping-address'>Shipping Address</label>
              <input 
                type='text' 
                id='shipping-address' 
                placeholder='Street address or P.O box' 
              />
            </div>
            <div className='specific-address-input-group'>
              <input 
                type='text' 
                id='specific-shipping-address' 
                placeholder='Apt, suite, unit, building, floor, etc.' 
              />
            </div>

            {/* 新的 City 和 State 输入框布局 */}
            <div className='city-state-container'>
              <div className='city-container'>
                <input 
                  type='text' 
                  id='city' 
                  placeholder='City' 
                  className='city-input'
                />
              </div>
              <div className='state-container'>
                <Select 
                  options={stateOptions} 
                  styles={customStyles}
                  placeholder="State"
                />
              </div>
            </div>

            <div className='country-zip-inputs'>
              <input 
                type='text' 
                id='country' 
                placeholder='United States' 
                disabled 
                style={{ backgroundColor: '#F9FAFB'}} 
              />
              <input 
                type='text' 
                id='zip' 
                placeholder='Zip code' 
              />
            </div>
            <div className='email-inputs'>
              <div className='email-input-group'>
                <label htmlFor='email'>Email</label>
                <input type='text' id='email' />
              </div>
              <div className='email-input-group'>
                <label htmlFor='Phone-Number'>Phone Number</label>
                <input type='text' id='Phone-Number' />
              </div>
            </div>
            <div className='button-group'>
              <button className='cancel-button'>Cancel</button>
              <a href='/confirm-rewardshippingcard' className='next-button'>
                  Next
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
