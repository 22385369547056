import "./Popup.css";

const Popup = ({ children }) => {
  return (
    <div className="popup-shadow">
      <div className="popup-content">
        <div className="policy-box">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
