import './Overview.css'
import {Helmet} from 'react-helmet'
import '../../../../components/components.css'

import UpdateProfile from './UpdateProfile/UpdateProfile'
import default_profile from '../icons/Avatar.svg'

import {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import { useTranslation } from "react-i18next";

export default function Overview({
	userInfo, userStats,
	setViewFollowing, setViewFollower,
	toggleUser, setToggleUser,
}){
	const navigate = useNavigate()
	const recipe_count = userStats.recipes
	const follower_count = userStats.followers
	const following_count = userStats.followings
	const { t } = useTranslation();

	const username = userInfo.username
	const [userIcon, setUserIcon] = useState(default_profile)

	const [updatingProfile, setUpdatingProfile] = useState(false)

	useEffect(() => {
		userInfo.profile_picture && setUserIcon(userInfo.profile_picture)
	}, [userInfo.profile_picture])

	return (
		<>
		<Helmet>
			<meta property="og:title" content={username ? username : 'Profile'} />
			<meta property="og:description" content={userInfo && userInfo.bio ? userInfo.bio : t("overview.noDescription")} />
			<meta property="og:image" content={userIcon || default_profile} />
			<meta property="og:url" content={`https://kangacook.com/profile/${username}`} />
			<meta property="og:type" content="profile" />

			<meta name="description" content={userInfo && userInfo.bio ? userInfo.bio : t("overview.noDescription")} />
			<title> {username ? username : 'Loading...'} </title>
			<link rel="canonical" href={`/profile/${username}`} />
		</Helmet>
		<div className='user-profile-user-description'>
			<div className='user-profile-detailed-description'>
				<div className='user-profile-avatar-container'>
					<span>
						<div
							onClick={()=>setUpdatingProfile(true)}
							className='x-large-avatar-container'
						>
							<img src={userIcon} className='x-large-avatar-avatar' alt='user-icon'/>
							<div className='x-large-avatar-cover'>
							</div>
						</div>
					</span>
				</div>
				<div className='user-profile-stats'>
					<div className='user-profile-stats-count'>
						{recipe_count}
					</div>
					<div className='user-profile-stats-name'>
						{recipe_count > 1? t("overview.recipes") : t("overview.recipe")}
					</div>
				</div>
		{/*<div className='user-profile-stats pointer' onClick={()=>setViewFollower(true)}>*/}
				<div className='user-profile-stats pointer' onClick={()=>setViewFollower(true)}>
					<div className='user-profile-stats-count'>
						{follower_count}
					</div>
					<div className='user-profile-stats-name'>
						{follower_count > 1? t("overview.followers") : t("overview.follower")}
					</div>
				</div>
				<div className='user-profile-stats pointer' onClick={()=>setViewFollowing(true)}>
					<div className='user-profile-stats-count'>
						{following_count}
					</div>
					<div className='user-profile-stats-name'>
						{following_count > 1? t("overview.followings") : t("overview.following")}
					</div>
				</div>
			</div>

			<h1 className='user-profile-user-name'>
				{username}
			</h1>

			{
			userInfo && userInfo.bio &&
			<div className='user-profile-user-bio'>
				<p> {userInfo.bio} </p>
			</div>
			}

			<div className='user-profile-user-options'>
				<div
					onClick={()=>navigate('/recipes/create')}
					className='secondary-text-wrapper'
				>
					<p 
						className='secondary-text'
					>
						{t("overview.createRecipe")}
					</p>
				</div>
				<div
					onClick={()=>setUpdatingProfile(true)}
					className='tertiary-text-container'
				>
					<p
						className='tertiary-text'
					>
						{t("overview.editProfile")}
					</p>
				</div>
			</div>
		</div>
		{
			updatingProfile && 
			<UpdateProfile
				setUpdatingProfile={setUpdatingProfile}
				userInfo={userInfo}
				toggleUser={toggleUser}
				setToggleUser={setToggleUser}
			/>
		}
		</>
	)
}

