import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signUp } from '../hook/awsAuthServices';
import ReCAPTCHA from 'react-google-recaptcha';
import { Input, Checkbox } from 'antd';
import { useTranslation } from "react-i18next";

export default function KangaSignup({ setErrorMessage }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null);
  const [validUsername, setValidUsername] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPassword, setValidPassword] = useState(true);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const { t } = useTranslation();

  // 新增的验证条件状态
  const [passwordCriteria, setPasswordCriteria] = useState({
    hasNumber: false,
    hasSpecialChar: false,
    hasUpperCase: false,
    hasLowerCase: false,
    minLength: false,
  });

  // 验证密码的逻辑
  const validatePassword = (value) => {
    const criteria = {
      hasNumber: /\d/.test(value),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      hasUpperCase: /[A-Z]/.test(value),
      hasLowerCase: /[a-z]/.test(value),
      minLength: value.length >= 8,
    };
    setPasswordCriteria(criteria);
    setValidPassword(Object.values(criteria).every(Boolean));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // 验证基本表单字段
    if (!username.trim()) {
      setValidUsername(false);
      return;
    }

    if (!email.trim()) {
      setValidEmail(false);
      return;
    }

    if (!validPassword) {
      return;
    }

    if (!agreeToTerms) {
      setErrorMessage("You must agree to the terms and conditions!");
      return;
    }

    try {
      await signUp(password, email, username);
      navigate('/confirm', { state: { email } });
    } catch (error) {
      // 检查是否是邮箱已存在的错误
      if (error.code === 'UsernameExistsException') {
        setErrorMessage("An account with this email already exists.");
      } else {
        setErrorMessage(`Sign up failed: ${error.message}`);
      }
    }
  };

  const onCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  return (
    <form className="signup-form" onSubmit={handleSubmit}>
      <div className='input-username'>
        <label className='username-text'>{t("kangaSignUp.username")}</label>
        <input 
          type="text" 
          placeholder="Username" 
          className={`input-box ${!validUsername ? 'input-error' : ''}`}
          value={username}
	  	  maxLength='20'
          onChange={(e) => {
            setUsername(e.target.value);
            setValidUsername(true);
          }}
          required
        />
        {!validUsername && <div className="error-message">{t("kangaSignUp.usernameErrorMessage")}</div>}
      </div>

      <div className='signup-input-email'>{t("kangaSignUp.email")}</div>
        <input 
          type="email" 
          placeholder="Email" 
          className={`signup-email-input ${!validEmail ? 'input-error' : ''}`}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setValidEmail(true);
          }}
          required
        />
        {!validEmail && <div className="error-message">{t("kangaSignUp.emailErrorMessage")}</div>}

      <div className='signup-input-password'>{t("kangaSignUp.password")}</div>
        <Input.Password 
          placeholder="Password minimum length 8 character(s)" 
          className={`signup-password-input ${!validPassword ? 'input-error' : ''}`}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            validatePassword(e.target.value);
          }}
          required
          style={{ paddingLeft: '16px' }}
        />
      
        {/* 密码条件列表 */}
        <ul className="password-criteria-list">
          <li style={{ color: passwordCriteria.hasNumber ? '#669F2A' : 'grey' }}>
            {t("kangaSignUp.mustContainNumber")}
          </li>
          <li style={{ color: passwordCriteria.hasSpecialChar ? '#669F2A' : 'grey' }}>
            {t("kangaSignUp.mustContainSpecialChar")}
          </li>
          <li style={{ color: passwordCriteria.hasUpperCase ? '#669F2A' : 'grey' }}>
            {t("kangaSignUp.mustContainUpperCase")}
          </li>
          <li style={{ color: passwordCriteria.hasLowerCase ? '#669F2A' : 'grey' }}>
            {t("kangaSignUp.mustContainLowerCase")}
          </li>
        </ul>

      <div className='agreement'>
        <Checkbox
          checked={agreeToTerms}
          onChange={(e) => setAgreeToTerms(e.target.checked)}
        >
          {t("kangaSignUp.agreement")}
        </Checkbox>
      </div>

      <div className='verification'>
        <ReCAPTCHA
          sitekey='6LcCAygqAAAAAHheHcFQ54P_RAtWUpU-nlJj29Sk'
          onChange={onCaptchaChange}
        />
      </div>

      <button type="submit" className="signup-button" disabled={!captchaValue}>
        {t("kangaSignUp.createAccount")}
      </button>

    </form>
  );
}
