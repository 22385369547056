import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ApplicationForm from './components/ApplicationForm'

export default function JobPost() {
	const {pk} = useParams();
	const [jobContent, setJobContent] = useState(<h1> Loading </h1>)
	useEffect(() => {
		async function fetchJobPost(){
			try{
				const response = await import(`./data/${pk}.json`)
				let jobBody = (
					<div>
						<h1> {response['title']} </h1>

						<h3> Company Description: </h3>
						<p style={{whiteSpace: 'pre-line'}}> {response['company-description']} </p>

						<h3> Role Description: </h3>
						<p style={{whiteSpace: 'pre-line'}}> {response['role-description']} </p>
						

						{ 
							('responsibilities' in response) &&
							(<>
								<h3> Responsibilities: </h3>
								<ul>
									{
										response['responsibilities'].map(
											(item, item_index) => 
												<li key={`responsibilities-${item_index}`}> {item} </li>
										)
									}
								</ul>
							</>)
						}

						<h3> Qualifications: </h3>
						<ul>
							{
								response['qualifications'].map(
									(item, item_index) => 
										<li key={`qualification-${item_index}`}> {item} </li>
								)
							}
						</ul>
					</div>
				)
				setJobContent(jobBody)
			}
			catch(err){
				setJobContent(<h1> Error </h1>)
				return
			}
		}
		fetchJobPost()
	}, [pk])

	return (
		<div style={{width: '80%', margin: 'auto'}}>
			{jobContent}
			{<ApplicationForm id={pk}/>}
		</div>
	)
}


