import React, { useState } from 'react';
import {Helmet} from 'react-helmet'
import { useLocation } from 'react-router-dom';
import EmailVerification from '../components/EmailVerification';  // 假设这个组件类似 ForgotPasswordVerification
import './ConfirmUserPage.css';  
import { useTranslation } from "react-i18next";

export default function ConfirmUserPage() {
  const location = useLocation();
  const { email } = location.state;

  const [errorMessage, setErrorMessage] = useState(''); 
  const { t } = useTranslation();

  return (
    <div className="container">
	  <Helmet>
		  <meta property="og:title" content="Kangacook - Confirm User"/>
		  <meta property="og:description" content= "Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <meta property="og:url" content="https://kangacook.com/confirm/"/>
		  <meta property="og:type" content="website" />

		  <meta name="description" content="Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <title> Kangacook - Confirm User</title>
      <link rel="canonical" href={`/confirm`} />
	  </Helmet>
      <div className="confirmuser-image-section"></div>
      <div className="form-section">
        <div className="form-container">
          {/* 显示错误消息 */}
          {errorMessage && (
            <div className="error-message">
              <img src="/stroke.png" alt="Error" className="error-icon" />
              <span className="error-text">{errorMessage}</span>
            </div>
          )}
          <img src="/logo.png" alt="Logo" className="logo" />
          <div className="login-page-header">
            <h1 className="title">{t("confirmUserPage.title")}</h1>
          </div>
          <div className="confirm-subtitle">
          {t("confirmUserPage.subtitle")}
          </div>
          <div className="input-header">
            <EmailVerification 
              email={email} 
              setErrorMessage={setErrorMessage}  
            />
          </div>
          <div className="confirm-no-account-container">
            <span className="no-account-text">{t("confirmUserPage.hasAccountText")}</span>
            <a href="/login" className="register-link">{t("confirmUserPage.loginButton")}</a>
          </div>
          <div className="agreement-container">
            <div className="agreement-text">
              {t("confirmUserPage.agreementText")} 
              <a href="https://kangacook-terms-and-conditions.s3.amazonaws.com/Terms+of+Use.pdf" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("confirmUserPage.terms")} </a>
              {t("confirmUserPage.and")}
              <a href="https://www.termsfeed.com/live/93288d56-1cc2-41a0-b02b-df39f874782b" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("confirmUserPage.privacy")}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
