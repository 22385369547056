import './Instructions.css'
import React from 'react'
import {useState} from 'react'
import up_img from '../icons/chevron-up.svg'
import { useTranslation } from "react-i18next";

export default function Instructions({instructions}){
	const [collapse, setCollapse] = useState(false)
	const { t } = useTranslation();

	return (
		<div className='recipe-page-instructions-container'>
			<div className='recipe-page-instructions-header'>
				<p className='recipe-page-section-title'>
					{t("recipePage.instructions")}
				</p>
				<div className='recipe-page-collapse'
					onClick={()=>{setCollapse(!collapse)}}
				>
				{
					collapse?
					<img style={{transform: 'rotate(180deg)'}} src={up_img} alt='collapse-icon'/>
						:
					<img src={up_img} alt='collapse-icon'/>
				}
				</div>
			</div>
		{!collapse &&
			<div className='recipe-page-instruction-list'>
			{
			instructions.map(({description, image}, index) => {
				return (
					<React.Fragment key={index}>
					{index !== 0 &&
						<div className='recipe-page-instruction-separator-container'>
							<div className='recipe-page-instruction-separator'/>
						</div>
					}
					<div className='recipe-page-instruction'>
						<div className='recipe-page-instruction-num-container'>
							<p> {index+1} </p>
						</div>
						<div className='recipe-page-instruction-detail'>
							<p>
								{description}
							</p>
							{
							image && 
							<div className='recipe-page-instruction-img-container'>
								<img src={image} alt='instruction-image'/>
							</div>
							}
						</div>
					</div>
					</React.Fragment>
				)
			})
			}
			</div>
		}
		</div>
	)
}

