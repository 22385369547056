import React from 'react'
import Header from '../components/Header'
import NavigationBar from "../components/NavigationBar/NavigationBar";
import './RewardRedeemed.css';
import '../main.css';
import { RecipeCardDesktop, RecipeCardMobile } from "./image";

export default function RewardRedeemed() {
    return (
        <>
            <div className='container-wrapper'>
                <Header className="header" />
                <NavigationBar className="nav-bar" />
                <div className='reward-redeemed-page'>
                    <img src={RecipeCardDesktop} alt='recipe-card' className='image desktop-image' />
                    <img src={RecipeCardMobile} alt='recipe-card' className='image mobile-image' />
                    <div className='reward-redeemed-container'>
                        <div className='redeemed-title-container'>
                            <h2 className='redeemed-title'>Your points were redeemed.</h2>
                        </div>
                        <div className='redeemed-info-container'>
                            <p>We'll ship your order after confirming your details.</p>
                            <p>Shipping status will be emailed. If verification fails, points will be refunded.</p>
                            <p>Expect shipping within 5 business days.</p>
                        </div>
                        <div className='redeemed-button-container'>
                            <button className='redeemed-button'>Back to reward page</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
