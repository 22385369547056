import React from 'react';
import {Helmet} from 'react-helmet'
import { Row, Col, ColorPicker } from 'antd';
// import KangaSignup from '../components/KangaSignup';
import './SignupPage.css';
// import GoogleOauth from '../../components/AlternativeLogin/GoogleOauth';
import GoogleOauth_Signup from '../../components/AlternativeLogin/GoogleOauth-Signup';
import { useTranslation } from "react-i18next";

export default function SignupPage() {
  const { t } = useTranslation();
  return(
    <div className="signup-container">
	  <Helmet>
		  <meta property="og:title" content="Kangacook - Signup"/>
		  <meta property="og:description" content= "Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <meta property="og:url" content="https://kangacook.com/signup/"/>
		  <meta property="og:type" content="website" />

		  <meta name="description" content="Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <title> Kangacook - Signup</title>
      <link rel="canonical" href={`/signup`} />
	  </Helmet>
      <div className='signup-image-section'></div>
      <div className='signup-form-section'>
        <div className='signup-form-container'>
	  	<a href='/'>
          <img src="/logo.png" alt="Logo" className="signup-logo" /> 
	  </a>
          <div className="signup-form-header">
            <h1 className="signup-page-title">{t("signUpPage.signUpTitle")}</h1>
            <p className="signup-page-subtitle">{t("signUpPage.signUpSubtitle")}</p>
          </div>
          <div className="signup-alterAccount">
            {/* Not for MVP */}
            {/*<button className="signup-page-button">
              <img src="/Facebook.png" alt="Facebook Icon" className="facebook-icon" /> 
              Sign up with Facebook
            </button>  */}
              <GoogleOauth_Signup /> {/* google组件 */}

            {/* Not for MVP */}
            {/*<button className="signup-page-button">
              <img src="/Line.png" alt="Line Icon" className="line-icon" /> 
              Sign up with Line
            </button> */}     
          </div>
          <div className="signup-dividerheader">
            <div className="signup-divider">
              <div className="signup-divider-line"></div>
                <span>{t("signUpPage.or")}</span>
              <div className="signup-divider-line"></div>
            </div>
          </div>
          <div className='email-signup'>
            <a href='/EmailSignup'>
              <button className="signup-page-button">
                <img src="/mail.png" alt="Mail Icon" className="mail-icon" /> 
                {t("signUpPage.signUpButton")}
              </button>
            </a>
          </div>
          <div className='footer-container'>
            <div className="has-account-container">
              <span className="has-account-text">{t("signUpPage.hasAccountText")}</span>
              <a href="/login" className="login-link">{t("signUpPage.loginButton")}</a>
            </div>
            <div className="signup-agreement-text">
              {t("signUpPage.agreementText")}
              <a href="https://kangacook-terms-and-conditions.s3.amazonaws.com/Terms+of+Use.pdf" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("signUpPage.terms")} </a>
              <br/>
              {t("signUpPage.and")} 
              <a href="https://www.termsfeed.com/live/93288d56-1cc2-41a0-b02b-df39f874782b" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("signUpPage.privacy")}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
