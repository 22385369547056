import React from 'react'
import { useGoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { loginGoogleOauth } from '../../hook/oauthService';
import './GoogleOauth.css';

export default function GoogleOauthButton() {
  const navigate = useNavigate();
  const onFailure = (error) => {
    console.error('Google login failed:', error);
  };

    const onSuccess = async (response) => {
        // get accessToken fron response
        const accessToken = response.access_token;
        const kangacook_user_id = await loginGoogleOauth(accessToken);

        //save userId to session
        Cookies.set("kangacook_user_id", JSON.stringify(kangacook_user_id), {
					expires: 90,
					domain: process.env.REACT_APP_COOKIE_URL,
				});
          
        //redirect to homepage
        navigate('/');
    };

    // const onError = (error) => {
    //     console.log(error);
    // };

    const login = useGoogleLogin({
        onSuccess,
        onFailure,
    });

    return (
        <div>
            <button
            type="primary"
            onClick={() => login()}
            className="google-login-button"
            >
            <img src="/Google.png" alt="Google Icon" className="google-icon" /> 
            Login with Google
            </button>
        </div>
    );
}
