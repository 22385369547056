import React from 'react'
import { GoogleOAuthProvider} from '@react-oauth/google'
import GoogleOauthButton from './GoogleOauthButton';


export default function GoogleOauth() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <GoogleOauthButton />
    </GoogleOAuthProvider>
  );
};