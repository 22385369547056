import React from 'react'
import Header from '../components/Header'
import NavigationBar from "../components/NavigationBar/NavigationBar";
import './ConfirmContactUs.css'
import '../main.css';
import { RecipeCardDesktop, RecipeCardMobile } from "./image";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function ConfirmContactUs() {
    const { t } = useTranslation();
    return (
        <div className='home-page'>
            <Header />
                <div className='home-page-body'>
                    <div className='home-page-nav-bar'> Navigation </div>
                    <NavigationBar />
                <div className='confirm-contactus-page'>
                    <img src={RecipeCardDesktop} alt='recipe-card' className='image desktop-image' />
                    <img src={RecipeCardMobile} alt='recipe-card' className='image mobile-image' />
                    <div className='confirm-contactus-container'>
                        <div className='contactus-title-container'>
                            <h2 className='contactus-title'>{t("confirmContactUs.title")}</h2>
                        </div>
                        <div className='contactus-info-container'>
                            <p>{t("confirmContactUs.subtitle")}</p>
                        </div>
                        <div className='contactus-button-container'>
                            <Link to="/home">
                                <button className='contactus-button'>{t("confirmContactUs.backToHome")}</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
