import React, { useState, useEffect } from 'react';
import {Helmet} from 'react-helmet'
import {useParams, useNavigate} from 'react-router-dom';
import ProgressBar from './components/recipeCreate/ProgressBar';
import IconBar from './components/recipeCreate/IconBar';
import RecipeCreateStepOne from './components/recipeCreate/RecipeCreateStepOne';
import RecipeCreateStepTwo from './components/recipeCreate/RecipeCreateStepTwo';
import RecipeCreateStepThree from './components/recipeCreate/RecipeCreateStepThree';
import RecipePublish from './components/recipeCreate/RecipePublish';
import Header from '../components/Header';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import CircularLoader from './components/recipeCreate/CircularLoader';

const RecipeCreatePage = () => {
  const navigate = useNavigate()
  const {pk} = useParams()
  const [recipeId, setRecipeId] = useState(pk || null);
  const [loading, setLoading] = useState(false);
  // Step state
  const [currentStep, setCurrentStep] = useState(
		1
  );

  useEffect(() => {
	  console.log("RECIPE ID: ", recipeId)
	  recipeId? navigate(`/recipes/create/${recipeId}`) :
						  navigate(`/recipes/create`)
  }, [recipeId])

  const [isPublish, setIsPublish] = useState(false);

  // FormData
  const defaultFormData = {
    access_level: 4,
    title: '',
    description: '',
    servings: 0,
    cook_time: 0,
		tags: [],
    ingredients: [],
    cookwares: [],
    instructions: [],
    cover_picture: null,
  };

  const [formData, setFormData] = useState(defaultFormData);

  // User ID
//   const getCookieValue = (name) => {
//     const cookieString = document.cookie
//       .split('; ')
//       .find((row) => row.startsWith(name + '='));
//     return cookieString ? cookieString.split('=')[1] : null;
//   };
// 
// 	const loadCoverPicture = async (url) => {
// 		const cover = await convertImageToBase64(url)
// 		setFormData((fd) => ({...fd, cover_picture: cover}))
// 	}
	const fetchDraftDescription = async () => {
		// Fetch draft Description
		try{
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			if (response.ok) {
				const data = await response.json()
				setFormData((fd) => ({
					...fd,
					access_level: data.access_level,
					title: data.title,
					description: data.description,
					servings: data.servings,
					cook_time: data.cook_time,
					cover_picture: data.cover_picture,
				}))
				// HERE: 
				// loadCoverPicture(data.cover_picture)
			} else {
				console.error('Failed to fetch draft recipe:', response.statusText);
			}
		} 
		catch (error) {
			console.error('Error fetching draft recipe:', error);
		}
	}

	const fetchDraftTags = async () => {
		// Fetch draft tags
		try{
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/tags`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			if (response.ok) {
				const data = await response.json()
				setFormData((fd) => ({
					...fd,
					tags: data.map(obj => obj.name)
				}))
			} else {
				console.error('Failed to fetch draft recipe:', response.statusText);
			}
		} 
		catch (error) {
			console.error('Error fetching draft recipe:', error);
		}
	}
		
	const fetchDraftIngredients = async () => {
		// Fetch draft tags
		try{
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/ingredients`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			if (response.ok) {
				const data = await response.json()
 				setFormData((fd) => ({
 					...fd,
 					ingredients: data.map(
						({step_number, name, serving}) => ({
							step_number: step_number,
							name: name, 
							serving: serving,
						})
					)
 				}))
			} else {
				console.error('Failed to fetch draft recipe:', response.statusText);
			}
		} 
		catch (error) {
			console.error('Error fetching draft recipe:', error);
		}
	}
		
	const fetchDraftCookwares = async () => {
		// Fetch draft tags
		try{
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/cookwares`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			if (response.ok) {
				const data = await response.json()
				setFormData((fd) => ({
					...fd,
 					cookwares: data.map(
						({step_number, name, quantity}) => ({
							step_number: step_number,
							name: name, 
							quantity: quantity,
						})
					)
				}))
			} else {
				console.error('Failed to fetch draft recipe:', response.statusText);
			}
		} 
		catch (error) {
			console.error('Error fetching draft recipe:', error);
		}
	}

	const fetchDraftInstructions = async () => {
		// Fetch draft tags
		try{
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/instructions`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			if (response.ok) {
				const data = await response.json()
				setFormData((fd) => ({
					...fd,
 					instructions: data.map(
						({step_number, description, image}) => ({
							step_number: step_number,
							description: description, 
							image: image,
						})
					)
				}))
			} else {
				console.error('Failed to fetch draft recipe:', response.statusText);
			}
		} 
		catch (error) {
			console.error('Error fetching draft recipe:', error);
		}
	}
		
  // Save user ID to formData
  useEffect(() => {

		if(recipeId){
			fetchDraftDescription()
			fetchDraftTags()
			fetchDraftIngredients()
			fetchDraftCookwares()
			fetchDraftInstructions()
		}

  }, []);

  // Step navigation
  const handleNextStep = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
// 	async function convertImageToBase64(imageUrl) {
// 		const response = await fetch(
// 			imageUrl,
// 			{
// 				mode: 'no-cors',
// 			}
// 		);
// 		const blob = await response.blob();
// 		
// 		return new Promise((resolve, reject) => {
// 			const reader = new FileReader();
// 			
// 			reader.onloadend = () => resolve(reader.result);
// 			reader.onerror = reject;
// 			
// 			reader.readAsDataURL(blob);
// 		});
// 	}
  const base64ToFile = (base64String, filename) => {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handlePublish = async () => {
    setLoading(true); // Show spinner before starting the publish process

    const finalData = new FormData();
		// When publishing, default access_level to public
    finalData.append('access_level', 0);
    finalData.append('title', formData.title);
    finalData.append('description', formData.description);
    finalData.append('servings', formData.servings);
    finalData.append('cook_time', formData.cook_time);
	let tags = formData.tags.map((tag, index) => ({name: tag, step_number: index+1}))
	finalData.append('tags', JSON.stringify(tags));
    finalData.append('ingredients', JSON.stringify(formData.ingredients));
    finalData.append('cookwares', JSON.stringify(formData.cookwares));

    // Convert the Base64 cover picture to a file
	let coverFormData = null
    if (formData.cover_picture && formData.cover_picture.startsWith('data:')) {
	  coverFormData = new FormData()
      const coverFile = base64ToFile(formData.cover_picture, 'cover_image.png');
	  coverFormData.append('cover_picture', coverFile);
    }
	else{
      finalData.append('cover_picture', JSON.stringify(formData.cover_picture));
	}

    // Append instructions without images
    const instructionsWithoutImages = formData.instructions.map(
      (instruction) => {
        const instructionCopy = { ...instruction };
        delete instructionCopy.image; // Explicitly remove only the `image` field
        return instructionCopy;
      }
    );

    finalData.append('instructions', JSON.stringify(instructionsWithoutImages));

	let instructionFormData = []
    // Process each instruction and convert Base64 images to files
    formData.instructions.forEach((instruction, index) => {
      // Convert Base64 image to a file if the image exists
      if (instruction.image && instruction.image.startsWith('data:')) {
        const imageFile = base64ToFile(
          instruction.image,
          `instruction_${index + 1}.png`
        );

		let newFormData = new FormData()
		newFormData.append('step_number', index+1)
		newFormData.append(`instruction_${index + 1}`, imageFile)
		instructionFormData.push(newFormData)
      }
	  else if(instruction.image){
        finalData.append(`instruction_${index + 1}`, JSON.stringify(instruction.image));
	  }
    });

    try {
	  let response;
	  if(recipeId){
		  response = await fetch(
			  `${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/`,
			  {
				  method: 'PUT',
				  body: finalData,
				  credentials: 'include',
			  }
		  );
	  }
	  else{
		  response = await fetch(
			  `${process.env.REACT_APP_BACKEND_URL}/recipe/`,
			  {
				  method: 'POST',
				  body: finalData,
				  credentials: 'include', 
			  }
		  );
	  }
      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON to extract recipe_id
        const recipeId = responseData.recipe_id;

		coverFormData && fetch(
			`${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/cover_picture/`,
			{
				method: 'POST',
				body: coverFormData,
				credentials: 'include', 
			}
		);

		instructionFormData.forEach((dt, index) => {
		  response = fetch(
			  `${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/instruction/`,
			  {
				  method: 'POST',
				  body: dt,
				  credentials: 'include', 
			  }
		  );
		})

        setIsPublish(true);
        setRecipeId(recipeId); // Store the recipe_id in state
        setCurrentStep(1);
      } else {
        console.error('Failed to publish recipe:', response.statusText);
      }
    } catch (error) {
      console.error('Error publishing recipe:', error);
    } finally {
      setLoading(false); // Hide spinner after the process is done
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
	  <Helmet>
		  <meta property="og:title" content="Kangacook - Create Recipe"/>
		  <meta property="og:description" content= "Kangacook Create Recipe - Share your recipes with people around the world"/>
		  <meta property="og:url" content="https://kangacook.com/recipes/create/"/>
		  <meta property="og:type" content="website" />

		  <meta name="description" content="Kangacook Create Recipe - Share your recipes with people around the world"/>
		  <title> Kangacook - Create Recipe </title>
		  <link rel="canonical" href={`/recipes/create`} />
	  </Helmet>
      <Header />
      <NavigationBar />

      <div className="tailwind-preflight flex flex-col justify-center desktop:pl-[400px] tablet:pl-[80px] tablet:pr-[80px] pl-[24px] pr-[24px] pt-[48px] pb-[125px] bg-gray-50 rounded-lg mt-4 mx-auto">
        {loading ? (
          <div className="flex justify-center items-center ">
            <CircularLoader />
          </div>
        ) : !isPublish ? (
          <>
            <ProgressBar currentStep={currentStep} />
            <IconBar
              formData={formData}
              setFormData={setFormData}
              defaultFormData={defaultFormData}
							recipeId={recipeId}
							setRecipeId={setRecipeId}
							setLoading={setLoading}
            />
            {currentStep === 1 && (
              <RecipeCreateStepOne
                onNext={handleNextStep}
                setFormData={setFormData}
                formData={formData}
              />
            )}
            {currentStep === 2 && (
              <RecipeCreateStepTwo
                onNext={handleNextStep}
                onPrevious={handlePreviousStep}
                setFormData={setFormData}
                formData={formData}
              />
            )}
            {currentStep === 3 && (
              <RecipeCreateStepThree
                onPrevious={handlePreviousStep}
                onPublish={handlePublish}
                setFormData={setFormData}
                formData={formData}
              />
            )}
          </>
        ) : (
          <RecipePublish recipeId={recipeId} />
        )}
      </div>
    </div>
  );
};

export default RecipeCreatePage;
