import React, { useState } from 'react';
import {Helmet} from 'react-helmet'
import KangaLogin from '../../components/KangaLogin';
import GoogleOauth from '../../components/AlternativeLogin/GoogleOauth';
import FacebookLogin from '../../components/AlternativeLogin/FacebookLogin';
import LineLogin from '../../components/AlternativeLogin/LineLogin';
import './LoginPage.css'; 
import { useTranslation } from "react-i18next";

export default function LoginPage() {
  const { signIn } = GoogleOauth(); 
  const [errorMessage, setErrorMessage] = useState(''); 
  const { t } = useTranslation();

  return (
    <div className="login-container">
	  <Helmet>
		  <meta property="og:title" content="Kangacook - Login"/>
		  <meta property="og:description" content= "Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <meta property="og:url" content="https://kangacook.com/login/"/>
		  <meta property="og:type" content="website" />

		  <meta name="description" content="Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <title> Kangacook - Login</title>
      <link rel="canonical" href={`/login`} />
	  </Helmet>
      <div className="login-image-section"></div>
      <div className="login-form-section">
        <div className="login-form-container">
          
          {/* 显示错误消息 */}
          {errorMessage && (
            <div className="error-message">
              <img src="/stroke.png" alt="Error" className="error-icon" />
              <span className="error-text">{errorMessage}</span>
            </div>
          )}
		  <a href='/'>
          <img src="/logo.png" alt="Logo" className="logo" /> 
		  </a>
          
          <div className="login-page-header">
            <h1 className="title">{t("loginPage.title")}</h1>
          </div>
          
          <div className='subtitle'>{t("loginPage.subtitle")}</div>

          {/* Not for MVP */}
          {/* <div className='facebookheader'>
            <button className="button facebook">
              <img src="/Facebook.png" alt="Facebook Icon" className="facebook-icon" /> 
              Login with Facebook
            </button>
          </div> */}
          
          <div className='googleheader'>
            <GoogleOauth /> {/* google组件 */}
          </div>
          {/* Not for MVP */}
          {/* <div className='lineheader'>
            <button className="button line">
              <img src="/Line.png" alt="Line Icon" className="line-icon" /> 
              Login with Line
            </button>
          </div> */}
          
          <div className="login-dividerheader">
            <div className="login-divider">
              <div className="login-divider-line"></div>
                <span>{t("loginPage.or")}</span>
              <div className="login-divider-line"></div>
            </div>
           </div>
          
          <div className='input-header'>
            <KangaLogin setErrorMessage={setErrorMessage} /> {/* 使用KangaLogin组件并传递 setErrorMessage */}
          </div>
          
          <div className="forgot-password-container">
            <a href="/forgotpassword" className="forgot-password-link">{t("loginPage.forgotPassword")}</a>
          </div>
          
          <div className="no-account-container">
            <span className="no-account-text">{t("loginPage.noAccount")}</span>
            <a href="/signup" className="register-link">{t("loginPage.signUpButton")}</a>
          </div>
          
          <div className="agreement-container">
            <div className="agreement-text">
              {t("loginPage.agreementText")}
              <a href="https://kangacook-terms-and-conditions.s3.amazonaws.com/Terms+of+Use.pdf" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("loginPage.terms")} </a>
              {t("loginPage.and")}
              <a href="https://www.termsfeed.com/live/93288d56-1cc2-41a0-b02b-df39f874782b" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("loginPage.privacy")}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
