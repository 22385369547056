import React from 'react';
import AlertIcon from '../../../components/icons/recipeCreate/alert.svg';

const ErrorToast = ({ message, className }) => {
  return (
    <div
      className={`fixed top-[120px] left-1/2 transform -translate-x-1/2 z-50 flex items-center gap-[8px] p-[11px] border rounded-lg bg-[#FEF3F2] border-[#F04438] ${className}`}
    >
      <img src={AlertIcon} alt="Alert" />
      <p
        className="text-[#F04438] text-sm font-normal leading-[22px] tracking-[0.28px]"
        style={{ fontFamily: '"Noto Sans TC", sans-serif' }}
      >
        {message}
      </p>
    </div>
  );
};

export default ErrorToast;
