import './404.css'
import Header from './Header'
import Navigation from './NavigationBar/NavigationBar'
import {NotFound} from './ErrorPage'


export default function PageNotFound(){
	return (
		<>
		<Header />
		<Navigation />

		<div
			className='not-found-container'
		>
			<NotFound />
		</div>

		</>
	)
}

