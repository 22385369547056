import React from 'react'
import { GoogleOAuthProvider} from '@react-oauth/google'
import GoogleOauthButton_Signup from './GoogleOauthButton-Signup';


export default function GoogleOauth_Signup() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <GoogleOauthButton_Signup />
    </GoogleOAuthProvider>
  );
};