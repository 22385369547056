import LikesComponent from "../../components/LikesComponent";
import SavedComponent from "../../components/SavedComponent";
import share_img from "../icons/share.svg";
import facebook_img from "../icons/facebook.svg";
import line_img from "../icons/line.svg";
import link_img from "../icons/link.svg";
import check_circle_img from "../icons/check-circle.svg";
import {useRef, useState, useEffect} from 'react'
import "./Interactions.css";

import { useTranslation } from "react-i18next";

export default function Interactions (props) {
	const [sharing, setSharing] = useState(false)
  const [shareToast, setShareToast] = useState(false);
	const shareRef = useRef(null)
	const RecipeTitle = props.title
	const { t } = useTranslation();
	const copyLink = () => {
		navigator.clipboard.writeText(window.location.href)
		setShareToast(true)
		setTimeout(() => setShareToast(false), 1500);
	}

  useEffect(() => {
    const handleClick = (e) => {
      if (shareRef.current && !shareRef.current.contains(e.target)) {
        setSharing(false);
      }
    };
    sharing && document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [sharing]);

  const handleLineShare = () => {
    const title = RecipeTitle;
    const url = window.location.href;
    const textToShare = `【${title}】`;
    const lineShareUrl = `https://social-plugins.line.me/lineit/share?url=${encodeURIComponent(url)}&text=${encodeURIComponent(textToShare)}`;

    window.open(lineShareUrl, "_blank");
  };

  const handleFacebookShare = () => {
    const title = RecipeTitle;
    const url = window.location.href;

    const textToShare = `【${title}】`;
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(textToShare)}`;
    window.open(shareUrl, "_blank");
  };

	return (
		<div className='recipe-page-header-stats'>
      {shareToast && (
        <div className="recipe-page-header-share-toast-container">
          <div className="recipe-page-header-share-toast">
            <img
              src={check_circle_img}
              title="check-circle-icon"
              alt="check-circle-icon"
            />
            <p> Link copied to clipboard </p>
          </div>
        </div>
      )}
			<LikesComponent
				recipe_id={props.recipe_id}
				like_count={props.like_count}
				liked={props.liked}
				toggleLike={props.toggleLike}
				setToggleLike={props.setToggleLike}
			/>
			<SavedComponent
				recipe_id={props.recipe_id}
				saved_count={props.saved_count}
				collections={props.collections}
				toggleCollection={props.toggleCollection}
				setToggleCollection={props.setToggleCollection}
				saved={props.saved}
			/>
			<div
				onClick = {()=>setSharing(!sharing)}
				className='recipe-page-header-share tertiary-text-container'
				ref = {shareRef}
			>
				<img src={share_img} alt='share-icon'/>
				<p className='tertiary-text'> {t("recipePage.share")} </p>
				{
					sharing &&

					<div className='recipe-page-header-share-card-container'>
						<div className='recipe-page-header-share-card'>
							<div className='recipe-page-header-share-card-title'> 
								<p> {t("recipePage.shareRecipe")} </p>
							</div>
							<div
								onClick={handleFacebookShare}
								className='recipe-page-header-share-item'
							>
								<img src={facebook_img} alt='facebook-icon'/>
								<p> Facebook </p>
							</div>
							<div
								onClick={handleLineShare}
								className='recipe-page-header-share-item'
							>
								<img src={line_img} alt='line-icon'/>
								<p> Line </p>
							</div>
							<div
								onClick={() => navigator.clipboard.writeText(window.location.href)}
								className='recipe-page-header-share-item'
							>
								<img src={link_img} alt='link-icon'/>
								<p> {t("recipePage.copyLink")} </p>
							</div>
						</div>
					</div>
				}
			</div>
			
		</div>
	)
}
