import './RecipeTags.css'
import '../../components/components.css'
import {useState} from 'react'
import up_img from '../icons/chevron-up.svg'
import { useTranslation } from "react-i18next";

export default function RecipeTags({tags}){
	const [collapse, setCollapse] = useState(false)
	const { t } = useTranslation();
	
	return (
		tags.length !== 0 &&
		<div className='recipe-page-recipe-tag-container'>
			<div className='recipe-page-tags-header'>
				<p className='recipe-page-section-title'>
					{t("recipePage.recipeTags")}
				</p>
				<div className='recipe-page-collapse'
					onClick={()=>{setCollapse(!collapse)}}
				>
				{
					collapse?
					<img style={{transform: 'rotate(180deg)'}} src={up_img} alt='collapse-icon'/>
						:
					<img src={up_img} alt='collapse-icon'/>
				}
				</div>
			</div>
		{!collapse &&
			<div className='recipe-page-tag-container'>
				{
					tags.map(({name}, index) => {
						return (
							<div className='xl-orange-stroke-tag-container' key={index}>
								<p className='xl-orange-stroke-tag-text'>{name}</p>
							</div>
						)
					})
				}
			</div>
		}
		</div>
	)
}

