// PopupContent.jsx
import React from "react";
import Button from "../Button";
import "./PolicyPopupContent.css";

const PolicyPopupContent = ({ togglePopup, handleAcceptAll, handleRejectAll }) => {
  return (
    <div className="policy-box">
      <h2>Roulettech Cookie Policy</h2>
      <div className="textbox">
        <p>
          Roulettech uses third-party cookies and other non-essential trackers
          (collectively, "Cookies") for statistical analysis, to personalize
          your experience, and for advertising and social media purposes,
          including but not limited to track your use of our site, offer you
          advertisements that target your particular interests, improve our
          services, assist you more efficiently when browsing our website, and
          analyze the performance of our advertising campaigns. You have the
          right to accept or reject all Cookies.
          <br />
          <br />
          Using Cookies, we may collect and disclose to third-party partners,
          including but not limited to service providers, basic identification
          information, device information, and other unique identifiers, your
          activity on our website such as pages viewed, certain interactions
          with our chat functions and videos, and commercial data. You may
          accept or reject all Cookies by clicking on the "Reject All" button
          below. You may also turn off particular Cookies by using the "Set
          Cookie Preferences" option. If you visit us from a different device or
          browser, or clear Cookies, you may need to return to this screen to
          re-select your preferences. Please note that even if you select Reject
          All, your use of our website or app may still be tracked by Roulettech
          and/or our service providers through functional (essential) trackers
          that may collect personal information for different purposes,
          including but not limited to operate the site and for legal and
          security reasons.
        </p>
        <p>
          You can learn more about our processing of your personal information
          by visiting our{" "}
          <button className="privacy-policy-link" onClick={() => window.location.href = 'https://www.termsfeed.com/live/93288d56-1cc2-41a0-b02b-df39f874782b'}>
            Privacy Policy.
          </button>
        </p>
      </div>
      <div className="button-box">
        <Button
          content={"Reject all cookies"}
          path="/NA"
          onClick={() => {togglePopup(); handleRejectAll();}}
          classNames={"btn secondary default"}
        />
        <Button
          content={"Accept All"}
          path="/NA"
          onClick={() => {togglePopup(); handleAcceptAll()}}
          classNames={"btn primary default"}
        />
      </div>
    </div>
  );
};

export default PolicyPopupContent;
