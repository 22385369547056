import React from "react";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import Header from "../components/Header";
import NavigationBar from "../components/NavigationBar/NavigationBar";
import "./Cart.css";
import DishList from "./components/DishList";
import StoreList from "./components/StoreList";
import ItemList from "./components/ItemList";
import Checkout from "./components/Checkout";

export default function Cart() {
  const [selectedStore, setSelectedStore] = useState("fudiAsianMart");
  const [dishes, setDishes] = useState(false);
  const fetchDishes = async () => {
    try {
      const apiHost = process.env.REACT_APP_BACKEND_URL;
      const response = await fetch(`${apiHost}/recipe`, {
        method: "GET",
        credentials: "include",
      });
      const data = await response.json();
      let result_data = [];
      for (let i = 0; i < data.results.length; i++) {
        if (data.results[i].recipe_id !== 10) {
          result_data.push(data.results[i]);
          if (result_data.length === 5) {
            break;
          }
        }
      }
      console.log(result_data);
      setDishes(result_data);
    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  };
  useEffect(() => {
    fetchDishes();
  }, []);
  return (
    <div className="cart-page">
      <Helmet>
        <meta property="og:title" content="Kangacook - Shopping Cart" />
        <meta
          property="og:description"
          content="Kangacook shopping cart - buy all your recipe ingredients in a single click"
        />
        <meta
          property="og:url"
          content="https://kangacook.com/shopping-cart/"
        />
        <meta property="og:type" content="website" />

        <meta
          name="description"
          content="Kangacook shopping cart - buy all your recipe ingredients in a single click"
        />
        <title> Kangacook - Shopping Cart </title>
				<link rel="canonical" href="https://kangacook.com/shopping-cart" />
      </Helmet>
      <Header />
      <NavigationBar />
      <div className="cart">
        <DishList dishes={dishes} />
        <StoreList
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
        />
        <ItemList recipes={dishes} />
        <Checkout selectedStore={selectedStore} />
      </div>
    </div>
  );
}
