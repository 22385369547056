import Overview from './ProfileHome/UserDetails/Overview'
import Recipes from './ProfileHome/Recipes/Recipes'
import './UserProfileContent.css'
import {useState, useEffect} from 'react'
import Following from './ProfileHome/Network/Following'
import Follower from './ProfileHome/Network/Follower'
import Cookies from 'js-cookie'
import { useLocation} from 'react-router-dom'
import {isAuthenticated} from '../../UserManagement/hook/Authenticated'

export default function UserProfileContent({changeLang}){
	const loc = useLocation();
	const queryParams = new URLSearchParams(loc.search)
	const mine_str = 'mine'
	const saved_str = 'saved'
	const liked_str = 'liked'
	const [selectedTab, setSelectedTab] = useState(queryParams.get('section') || 'mine')
	const [fetchingRecipe, setFetchingRecipe] = useState(false)
	const [fetchingFollows, setFetchingFollows] = useState(false)
	// Update url to remove ?section=...
// 	const url = new URL(window.location.href);
// 	url.searchParams.delete('section'); 
// 	window.history.replaceState(null, null, url.pathname); 

	const LATEST = 'latest'
	const POPULAR = 'popular'
	const EARLIEST = 'earliest'

	const [viewFollowing, setViewFollowing] = useState(false)
	const [viewFollower, setViewFollower] = useState(false)
	const cookie_value = Cookies.get('kangacook_user_id')
	const [username, setUsername] = useState(JSON.parse(cookie_value).username)
	const [userInfo, setUserInfo] = useState({
		username: 'unknown',
		profile_picture: null,
		bio: 'empty bio',
		email: 'no email',
		first_name: '',
		last_name: '',
		birthday: '',
	})
	const [toggleUser, setToggleUser] = useState(false)
	//用来更新selectedTab
	useEffect(() => {
		const section = queryParams.get('section');
		setSelectedTab(section || mine_str); // 设置为 'mine' 默认值
	  }, [loc.search]); // 监听 loc.search 的变化
	  
	  
	
	
	const fetchUserInfo = async () => {
		try {
			const currUsername = JSON.parse(cookie_value).username
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile/${currUsername}`);
			const data = await response.json();
			setUserInfo(data);
		} catch (error) {
			console.error('Error fetching user information:', error);
		}
	}
	useEffect(() => {
		fetchUserInfo()
	}, [toggleUser])
	const [userStats, setUserStats] = useState({
		recipes: 0,
		followers: 0,
		followings: 0,
	})
	const [userFollowers, setUserFollowers] = useState({
		next:'/user-network/list-followers', results: [], history: [`/user-network/list-followers`]
	})
	const [userFollowings, setUserFollowings] = useState({
		next:'/user-network', results: [], history: [`/user-network`]
	})
	const [userCollections, setUserCollections] = useState(null)

	const [latest, setLatest] = useState({
		next:`/user-profile/${username}/my-recipes/?sort=latest`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=latest`]
	})
	const [popular, setPopular] = useState({
		next:`/user-profile/${username}/my-recipes/?sort=popular`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=popular`]
	})
	const [earliest, setEarliest] = useState({
		next:`/user-profile/${username}/my-recipes/?sort=earliest`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=earliest`]
	})

	const [recipes, setRecipes] = useState(latest)
	const [selected, setSelected] = useState(LATEST)

	const [likedRecipes, setLikedRecipes] = useState({
		next:'/liked-recipe', results: [], history:[`/liked-recipe`]
	})
	const [toggleFollowing, setToggleFollowing] = useState(false)
	const [toggleCollection, setToggleCollection] = useState(false)
	const fetchUserStats = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/stats`,{
				method: 'GET',
			});
			const data = await response.json();
			setUserStats(data); // Set the recipe data to state
		} catch (error) {
			console.error('Error fetching user followers:', error);
		}
	}
	const fetchUserFollowings = async ()=>{
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-network/?${userFollowings.next.split('?')[1] || ''}`,
				{
					method: 'GET',
					credentials: 'include',
				});
			const data = await response.json();
			if(!userFollowings.history.includes(data.next)){
				userFollowings.history.push(data.next)
				userFollowings.next = data.next
				userFollowings.results = userFollowings.results.concat(data.results)
				setUserFollowings(({...userFollowings}))
			}
		} catch (error) {
			console.error('Error fetching user followings:', error);
		}
		setFetchingFollows(false)
	}
	const fetchUserFollowers = async ()=>{
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-network/list-followers/?${userFollowers.next.split('?')[1] || ''}`,
				{
					method: 'GET',
					credentials: 'include',
				});
			const data = await response.json();
			if(!userFollowers.history.includes(data.next)){
				userFollowers.history.push(data.next)
				userFollowers.next = data.next
				userFollowers.results = userFollowers.results.concat(data.results)
				setUserFollowers(({...userFollowers}))
			}
		} catch (error) {
			console.error('Error fetching user followers:', error);
		}
		setFetchingFollows(false)
	}
	const fetchUserCollections = async () => {
		if (!isAuthenticated()){
			return
		}
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/recipe-collection`,{
				method: 'GET',
				credentials: 'include',
			});
			const data = await response.json();
			setUserCollections(data.filter(collection => collection.name !== 'Saved')); // Set the recipe data to state
		} catch (error) {
			console.error('Error fetching user collection:', error);
		}
	}
	const fetchLikedRecipes = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/liked-recipe/?${likedRecipes.next.split('?')[1] || ''}`,{
				method: 'GET',
				credentials: 'include',
			});
			const data = await response.json();
			if(!likedRecipes.history.includes(data.next)){
				likedRecipes.history.push(data.next)
				likedRecipes.next = data.next
				likedRecipes.results = likedRecipes.results.concat(data.results)
				setLikedRecipes(({...likedRecipes}))
			}
		} catch (error) {
			console.error('Error fetching user liked recipe:', error);
		}
		setFetchingRecipe(false)
	}

	useEffect(() => {
		// TODO: No refetch needed?
		userFollowings.next = '/user-network'
		userFollowings.results = []
		userFollowings.history = ['/user-network']
		userFollowers.next && fetchUserFollowers()
		fetchUserFollowings()
		fetchUserStats()
	}, [toggleFollowing])

	useEffect(() => {
		fetchUserCollections()
	}, [toggleCollection])

	useEffect(() => {
		const scrollFunc = async () => {
			if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
				if(viewFollower){
					if(!fetchingFollows && userFollowers.next){
						fetchUserFollowers()
						setFetchingFollows(true)
					}
				}
				else if(viewFollowing){
					if(!fetchingFollows && userFollowings.next){
						fetchUserFollowings()
						setFetchingFollows(true)
					}

				}
				else if(!fetchingRecipe){
					// My Recipes Tab
					if(selectedTab === mine_str){
						if(selected === LATEST){
							if(latest.next){
								fetchLatest()
								setFetchingRecipe(true)
							}
						}
						else if(selected === POPULAR){
							if(popular.next){
								fetchPopular()
								setFetchingRecipe(true)
							}
						}
						else if(selected === EARLIEST){
							if(earliest.next){
								fetchEarliest()
								setFetchingRecipe(true)
							}
						}
						else{
							console.log("SOME TING WONG")
						}
					}
					// Saved Recipes Tab
					else if(selectedTab === saved_str){
						console.log("Fetch For Saved Recipe (NOT GONNA HAPPEN)")
					}
					// Liked Recipes Tab
					else if(selectedTab === liked_str){
						if(likedRecipes.next){
							fetchLikedRecipes()
							setFetchingRecipe(true)
						}
					}
					else{
						console.log("UNKNOWN")
					}
				}
			}
		}
		window.addEventListener('scroll', scrollFunc)
		return ()=>{
			window.removeEventListener('scroll', scrollFunc)
		}
	}, [selectedTab, selected, fetchingRecipe, fetchingFollows, viewFollower, viewFollowing])
	const fetchLatest = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/my-recipes/?${latest.next.split('?')[1] || ''}`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			const data = await response.json();
			if(!latest.history.includes(data.next)){
				latest.history.push(data.next)
				latest.next = data.next
				latest.results = latest.results.concat(data.results)
				setLatest(({...latest})); 
			}
		} catch (error) {
			console.error('Error fetching user information:', error);
		}
		setFetchingRecipe(false)
	}

	const fetchPopular = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/my-recipes/?${popular.next.split('?')[1] || ''}`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			const data = await response.json();
			if(!popular.history.includes(data.next)){
				popular.history.push(data.next)
				popular.next = data.next
				popular.results = popular.results.concat(data.results)
				setPopular(({...popular})); 
			}
		} catch (error) {
			console.error('Error fetching user recipe (order: popular):', error);
		}
		setFetchingRecipe(false)
	}
	const fetchEarliest = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/my-recipes/?${earliest.next.split('?')[1] || ''}`,
				{
					method: 'GET',
					credentials: 'include',
				}
			);
			const data = await response.json();
			if(!earliest.history.includes(data.next)){
				earliest.history.push(data.next)
				earliest.next = data.next
				earliest.results = earliest.results.concat(data.results)
				setEarliest(({...earliest})); 
			}
		} catch (error) {
			console.error('Error fetching user recipe (order: popular):', error);
		}
		setFetchingRecipe(false)
	}
	useEffect(() => {
		switch (selected){
			case LATEST:
				setRecipes(latest)
				return 
			case POPULAR:
				setRecipes(popular)
				return 
			case EARLIEST:
				setRecipes(earliest)
				return 
			default:
				return null
		}
	}, [selected, latest, earliest, popular])

	const resetRecipes = () => {
	  likedRecipes.next = '/liked-recipe'
	  likedRecipes.results = []
	  likedRecipes.history = [`/liked-recipe`]
	  setLikedRecipes({
		  next:'/liked-recipe', results: [], history:[`/liked-recipe`]
	  })

	  latest.next = `/user-profile/${username}/my-recipes/?sort=latest`
	  latest.results = []
	  latest.history = [`/user-profile/${username}/my-recipes/?sort=latest`]
	  setLatest({
		next:`/user-profile/${username}/my-recipes/?sort=latest`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=latest`]
	  })
	  popular.next = `/user-profile/${username}/my-recipes/?sort=popular`
	  popular.results = []
	  popular.history = [`/user-profile/${username}/my-recipes/?sort=popular`]
	  setPopular({
		next:`/user-profile/${username}/my-recipes/?sort=popular`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=popular`]
	  })
	  earliest.next = `/user-profile/${username}/my-recipes/?sort=earliest`
	  earliest.results = []
	  earliest.history = [`/user-profile/${username}/my-recipes/?sort=earliest`]
	  setEarliest({
		next:`/user-profile/${username}/my-recipes/?sort=earliest`, results: [], history:[`/user-profile/${username}/my-recipes/?sort=earliest`]
	  })
	}

	useEffect(()=>{
		resetRecipes()
		fetchUserInfo()
		fetchLatest()
		fetchLikedRecipes()
		fetchPopular()
		fetchEarliest()
	},[changeLang, username])
	return (
		<>
		<div className='user-profile-content'>
			{
				(
					viewFollowing
						&&
					<Following
						setViewFollowing={setViewFollowing}
						followings={userFollowings}
						followings_count={userStats.followings}
						toggleFollowing={toggleFollowing}
						setToggleFollowing={setToggleFollowing}
					/>
				) ||
				(
					viewFollower
						&&
					<Follower
						setViewFollower={setViewFollower}
						followers={userFollowers}
						followers_count={userStats.followers}
						toggleFollowing={toggleFollowing}
						setToggleFollowing={setToggleFollowing}
					/>
				) ||
				<span>
					<Overview 
						userInfo={userInfo}
						userStats={userStats}
						setViewFollower={setViewFollower}
						setViewFollowing={setViewFollowing}
						toggleUser={toggleUser}
						setToggleUser={setToggleUser}
					/>
					<Recipes
						collections={userCollections}
						toggleCollection={toggleCollection}
						setToggleCollection={setToggleCollection}
						recipes={recipes}
						setSelected={setSelected}
						selected={selected}
						setSelectedTab={setSelectedTab}
						selectedTab={selectedTab}
						likedRecipes={likedRecipes}
					/>
				</span>
			}
		</div>
		</>
	)
}

