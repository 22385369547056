import React, { useState } from 'react';
import {Helmet} from 'react-helmet'
import './EmailSignupPage.css';
import KangaSignup from '../../components/KangaSignup';
import { useTranslation } from "react-i18next";

export default function EmailSignupPage() {
  const [errorMessage, setErrorMessage] = useState(''); // 错误消息状态
  const { t } = useTranslation();

  return (
    <div className='ES-container'>
	  <Helmet>
		  <meta property="og:title" content="Kangacook - Sign Up"/>
		  <meta property="og:description" content= "Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <meta property="og:url" content="https://kangacook.com/emailsignup/"/>
		  <meta property="og:type" content="website" />

		  <meta name="description" content="Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <title> Kangacook - Sign Up</title>
      <link rel="canonical" href={`/emailsignup`} />
	  </Helmet>
      <div className='ES-img-section'></div>
      <div className='ES-form-section'>
        <div className='ES-form-container'>

          {/* 显示错误消息 */}
          {errorMessage && (
            <div className="error-message">
              <img src="/stroke.png" alt="Error" className="error-icon" />
              <span className="error-text">{errorMessage}</span>
            </div>
          )}

          {/* Logo */}
	  	<a href='/'>
          <img className='ES-logo' src='/logo.png' />
	  </a>

          <div className="signup-form-header">
            <h1 className="title">
              <a href='/signup'>
                <button className='arrow-left-button'>
                  <img className='arrow-left' src='/arrow-left.png'></img>
                </button>
              </a>
              {t("emailSignUp.title")}
            </h1>
            <p className="subtitle">{t("emailSignUp.subtitle")}</p>
          </div>

          <div>
            {/* 将 setErrorMessage 传递给 KangaSignup */}
            <KangaSignup setErrorMessage={setErrorMessage} />
          </div>

        </div>
      </div>
    </div>
  );
}
