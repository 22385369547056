import React from "react";
import "./Dish.css";
import defaultImage from "../icons/RecipeCard.jpeg";
import deleteIcon from "../icons/DeleteIcon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Dish({ description, title, cover_picture, recipe_id }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div
      className="cart-dish-component"
      onClick={() => navigate(`/recipe/${recipe_id}`)}
    >
      <div className="cart-dish-image-container">
        <img src={cover_picture || defaultImage} alt="recipe-image" />
      </div>
      <div className="cart-dish-text-container">
        <p className="cart-dish-title">{title ? title : "Recipe Title"}</p>
        <p className="cart-dish-supporting-text">
          {description ? description : t("cart.supportingText")}
        </p>
      </div>
      <div className="cart-dish-delete-icon-container">
        <img src={deleteIcon} alt="delete-icon" />
      </div>
    </div>
  );
}
