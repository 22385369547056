const Divider = () => {
  return (
    <>
      <hr
        style={{
          border: 0,
          height: "1px",
          backgroundColor: "#e0e0e0",
          margin: "16px 0",
          width: "100%",
          marginLeft: "0px",
        }}
      />
    </>
  );
};

export default Divider;
