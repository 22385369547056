import './Description.css'
import up_img from '../icons/chevron-up.svg'
import {useState} from 'react'
import { useTranslation } from "react-i18next";

export default function Description({details}){
	const [collapse, setCollapse] = useState(false)
	const { t } = useTranslation();
	
	// const details = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. `
	// const [collapse, setCollapse] = useState(false)
	return (
		<div className='recipe-page-description'>
			<div className='recipe-page-description-header'>
				<div className='recipe-page-section-title'>
					{t("recipePage.description")}
				</div>
				<div className='recipe-page-collapse'
					onClick={()=>{setCollapse(!collapse)}}
				>
				{
					collapse?
					<img style={{transform: 'rotate(180deg)'}} src={up_img} alt='collapse-icon'/>
						:
					<img src={up_img} alt='collapse-icon'/>
				}
				</div>
			</div>

		{!collapse && 
			<p className='recipe-page-description-detail'>
				{details}
			</p>
		}
		</div>
	)
}

