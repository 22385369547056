// TODO: replace with real url
import "./CookieBanner.css";
import Button from "../Button";
import Popup from "../Popup";
import PolicyPopupContent from "./PolicyPopupContent";

import React, { useState, useEffect } from "react";

const CookieBanner = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  window.gtag = function(){
	window.dataLayer.push(arguments)
  }
  useEffect(() => {
    const consentCookie = getCookie('kangacookConsentMode');
    console.log('Consent cookie value:', consentCookie);

    if (!consentCookie) {
      console.log('No consent cookie found, showing banner');
      setShowBanner(true);
    } else {
      try {
        const parsedConsent = JSON.parse(consentCookie);
        console.log('Parsed consent:', parsedConsent);
        window.gtag('consent', 'update', parsedConsent);
        setShowBanner(false);
      } catch (error) {
        console.error('Error parsing consent cookie:', error);
        setShowBanner(true);
      }
    }
  }, []);

  const hideBanner = () => {
    setShowBanner(false);
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  };

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    console.log(`Cookie set: ${name}=${value}`);
  };

  const setConsent = (consent) => {
    const kangacookConsentMode = {
      'ad_storage': consent.marketing ? 'granted' : 'denied',
      'ad_user_data': consent.marketing ? 'granted' : 'denied',
      'ad_personalization': consent.marketing ? 'granted' : 'denied',
      'functionality_storage': consent.necessary ? 'granted' : 'denied',
      'security_storage': consent.necessary ? 'granted' : 'denied',
      'analytics_storage': consent.analytics ? 'granted' : 'denied',
      'personalization_storage': consent.preferences ? 'granted' : 'denied',
    };
    window.gtag('consent', 'update', kangacookConsentMode);
    setCookie('kangacookConsentMode', JSON.stringify(kangacookConsentMode), 365);
  };

  const handleAcceptAll = () => {
    setConsent({ necessary: true, analytics: true, preferences: true, marketing: true });
    hideBanner();
  };

  const handleRejectAll = () => {
    setConsent({ necessary: false, analytics: false, preferences: false, marketing: false });
    hideBanner();
  };

  const togglePopup = () => {
    setShowPopup((prevShowPopup) => !prevShowPopup);
  };

  return (
	<>
	{
	showBanner && 
    <div className="cookie-footer">
      <div className="cookie-container">
        <div className="text-box">
          <p>
            Roulettech uses third-party cookies and other non-essential trackers
            (collectively, “Cookies”) for statistical analysis, to personalize
            your experience, and for advertising and social media purposes,
            including to track your use of our site, offer you advertisements
            that target your particular interests, improve our services, assist
            you more efficiently when browsing our website, and analyze the
            performance of our advertising campaigns. You have the right to
            accept or reject all Cookies. If you want more information about the
            trackers used, and your rights,{" "}
            <button className="read-more-link" onClick={togglePopup}>
              read more
            </button>
            .
          </p>
        </div>
        <div className="policy-buttons">
          <Button
            content={"Accept All"}
            path="/NA"
            classNames={"btn primary default"}
			onClick={handleAcceptAll}
          />

          <Button
            content={"Set Cookie Preferences"}
            path="/NA"
            classNames={"btn secondary default"}
          />
        </div>
      </div>

      {showPopup && (
        <Popup>
          <PolicyPopupContent
			togglePopup={togglePopup}
			handleAcceptAll={handleAcceptAll}
			handleRejectAll={handleRejectAll}
		  />
        </Popup>
      )}
    </div>
	}
	</>
  );
};

export default CookieBanner;
