import './RecipeCardComponent.css'
import Default_img from './icons/Kangacook logo.png'
import './components.css'
import { useNavigate } from 'react-router-dom'
import LikesComponent from '../components/LikesComponent'
import SavedComponent from '../components/SavedComponent'

export default function RecipeCardComponent(props){
	// update liked and saved props to make it fit DB
	// add navigate and goToRecipe to make the component can 
	// redirect to recipe detail page after clicking
	const navigate = useNavigate();
	const goToRecipe = () => {
		navigate(`/recipe/${props.recipe_id}`)
	};
	return (
		<div className='recipe-card' onClick={goToRecipe}>
			<div className='recipe-card-image-container'>
				{/* updated props.img to cover_pictures to show the picture */}

				<img src={props.cover_picture || Default_img} className='recipe-card-image' alt='recipe-image'/>
			</div>
			<div className='recipe-card-details'>
				<p className='recipe-card-recipe-name'>
					{props.title}
				</p>
				<div className='recipe-card-recipe-details'>
					<p
						className='recipe-card-author-name'
						onClick={(e) => {e.stopPropagation(); navigate(`/profile/${props.author}`)}}
					>
						{props.author}
					</p>
					<LikesComponent
						recipe_id={props.recipe_id}
						like_count={props.like_count}
						liked={props.liked}
					/>
					<SavedComponent
						recipe_id={props.recipe_id}
						saved_count={props.saved_count}
						collections={props.collections}
						toggleCollection={props.toggleCollection}
						setToggleCollection={props.setToggleCollection}
						saved={props.saved}
					/>
				</div>
			</div>
		</div>
	)
}

