import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie'
import {useNavigate} from 'react-router-dom'
import TrashIcon from '../../../components/icons/recipeCreate/trash.svg';
import SaveIcon from '../../../components/icons/recipeCreate/save.svg';
import EyeIcon from '../../../components/icons/recipeCreate/eye.svg';
import ConfirmationModal from './ConfirmationModal';
import SuccessToast from './SuccessToast';
import ErrorToast from './ErrorToast';
import { useTranslation } from "react-i18next";

const IconBar = ({ formData, setFormData, defaultFormData, recipeId, setRecipeId, setLoading }) => {
  const { t } = useTranslation();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  
  const navigate = useNavigate()
  const [profileUrl, setProfileUrl] = useState('/login')
  const cookie_value = Cookies.get('kangacook_user_id')
  useEffect(() => {
	if (cookie_value){
	  const username = JSON.parse(cookie_value).username || ''
	  setProfileUrl(`/profile/${username.replace(/ /g, "%20")}/`)
	}
  }, [])

  const validateSteps = () => {
	if (formData.access_level === 4){
	  return true
	}

	// Step one
    if (!formData.title.trim()) {
	  setErrorMessage(t("iconBar.confirmSavedChanges"));
	  return false
    } else if (formData.title.length > 200) {
	  setErrorMessage(t("iconBar.errorMessageRecipeTitleTooLong"));
	  return false
    }

    if (!formData.cover_picture) {
	  setErrorMessage(t("iconBar.errorMessageMissingCoverPicture"));
	  return false
    }

    if (!formData.description.trim()) {
	  setErrorMessage(t("iconBar.errorMessageMissingRecipeDescription"));
	  return false
    }

    if (
      !formData.cook_time ||
      formData.cook_time <= 0
    ) {
	  setErrorMessage(t("iconBar.errorMessageInvalidCookingTime"));
	  return false
    }

    if (
      !formData.servings ||
      formData.servings <= 0
    ) {
	  setErrorMessage(t("iconBar.errorMessageInvalidServings"));
	  return false
    }

	// Step two
	for (let i = 0; i < formData.ingredients.length; i++){
	  let ingredient = formData.ingredients[i];
      if (
        ingredient.name.trim().length === 0 ||
        ingredient.serving.trim().length === 0 
      ) {
        setErrorMessage(t("iconBar.errorMessageIncompleteIngredient"));
        return false;
      }
	}

    // Validate cookware
	for (let i = 0; i < formData.cookwares.length; i++){
	  let cookware = formData.cookwares[i];
      if (cookware.name.trim().length === 0 ||
		  !cookware.quantity || 
		  cookware.quantity.toString().trim().length === 0
	  ) {
        // Ensure quantity is string
        setErrorMessage(t("iconBar.errorMessageIncompleteCookwares"));
        return false;
      }
	}

	// Step three
    for (let i = 0; i < formData.instructions.length; i++) {
      if (formData.instructions[i].description.length === 0 ||
		  formData.instructions[i].description.trim().length === 0
	  ) {
        setErrorMessage(t("iconBar.errorMessageIncompleteInstructions"));
        return false;
      }
    }
    setErrorMessage(null);
    return true;
  };

  const handleTrashClick = async () => {
		setLoading(true)
	try {
	  let response;
	  if(recipeId){
		response = await fetch(
		  `${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/`,
		  {
			method: 'DELETE',
			credentials: 'include',
		  }
		);
	  }
	  else{
		setFormData(defaultFormData);
		console.log('Form data reset to default state');
		setLoading(false)
		return
	  }
      if (response.ok) {
		setRecipeId(null)
		navigate(`${profileUrl}`)
      } else {
        console.error('Failed to publish recipe:', response.statusText);
      }
    } catch (error) {
      console.error('Error publishing recipe:', error);
    } finally {
      setLoading(false); // Hide spinner after the process is done
    }
	
	// TODO:
	//	Redirect to home/profile page or confirmation page
    setFormData(defaultFormData);
    console.log('Form data reset to default state');
  };

  const base64ToFile = (base64String, filename) => {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleSave = async (updatedFormData) => {
	if(!validateSteps()){
	  return false
	}
		setLoading(true)
    const finalData = new FormData();
		// Set access_level to Draft (4)
    finalData.append('access_level', formData.access_level);
    finalData.append('title', formData.title);
    finalData.append('description', formData.description);
    finalData.append('servings', formData.servings | 0);
    finalData.append('cook_time', formData.cook_time | 0);
		let tags = formData.tags.map((tag, index) => ({name: tag, step_number: index+1}))
		finalData.append('tags', JSON.stringify(tags));
    finalData.append('ingredients', JSON.stringify(formData.ingredients));
    finalData.append('cookwares', JSON.stringify(formData.cookwares));

    // Convert the Base64 cover picture to a file
	let coverFormData = null
    if (formData.cover_picture && formData.cover_picture.startsWith('data:')) {
	  coverFormData = new FormData()
      const coverFile = base64ToFile(formData.cover_picture, 'cover_image.png');
	  coverFormData.append('cover_picture', coverFile);
    }
	else{
      finalData.append('cover_picture', JSON.stringify(formData.cover_picture));
	}

    // Append instructions without images
    const instructionsWithoutImages = formData.instructions.map(
      (instruction) => {
        const instructionCopy = { ...instruction };
        delete instructionCopy.image; // Explicitly remove only the `image` field
        return instructionCopy;
      }
    );

    finalData.append('instructions', JSON.stringify(instructionsWithoutImages));

	let instructionFormData = []
    // Process each instruction and convert Base64 images to files
    formData.instructions.forEach((instruction, index) => {
      // Convert Base64 image to a file if the image exists
      if (instruction.image && instruction.image.startsWith('data:')) {
        const imageFile = base64ToFile(
          instruction.image,
          `instruction_${index + 1}.png`
        );

		let newFormData = new FormData()
		newFormData.append('step_number', index+1)
		newFormData.append(`instruction_${index + 1}`, imageFile)
		instructionFormData.push(newFormData)
      }
	  else if(instruction.image){
        finalData.append(`instruction_${index + 1}`, JSON.stringify(instruction.image));
	  }
    });

    try {
	  let response;
	  if(recipeId){
		  response = await fetch(
			  `${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/`,
			  {
				  method: 'PUT',
				  body: finalData,
				  credentials: 'include',
			  }
		  );
	  }
	  else{
		  response = await fetch(
			  `${process.env.REACT_APP_BACKEND_URL}/recipe/`,
			  {
				  method: 'POST',
				  body: finalData,
				  credentials: 'include', 
			  }
		  );
	  }
      if (response.ok) {
        const responseData = await response.json(); // Parse the response as JSON to extract recipe_id
        const recipeId = responseData.recipe_id;

        setRecipeId(recipeId); // Store the recipe_id in state

		coverFormData && fetch(
			`${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/cover_picture/`,
			{
				method: 'POST',
				body: coverFormData,
				credentials: 'include', 
			}
		);



		instructionFormData.forEach((dt, index) => {
		  response = fetch(
			  `${process.env.REACT_APP_BACKEND_URL}/recipe/${recipeId}/instruction/`,
			  {
				  method: 'POST',
				  body: dt,
				  credentials: 'include', 
			  }
		  );
		})

		// Show the success toast
		setShowSuccessToast(true);
		setTimeout(() => setShowSuccessToast(false), 3000);
		navigate(profileUrl)
      } else {
        console.error('Failed to save recipe:', response.statusText);
      }
    } catch (error) {
      console.error('Error saving recipe:', error);
    } finally{
				setLoading(false)
		}
  };

  const handleSaveClick = () => {
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData };
      handleSave(updatedFormData); // Use the latest formData
      return updatedFormData; // Return the updated formData
    });
  };

  const handleEyeClick = () => {
    console.log('Eye icon clicked');
  };

  const openConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  useEffect(() => {
	const cookie_value = Cookies.get('kangacook_user_id')
	if (cookie_value){
	  const username = JSON.parse(cookie_value).username
	  setProfileUrl(`/profile/${username.replace(/ /g, "%20")}/`)
	}
  }, []);

  const confirmDelete = () => {
    handleTrashClick();
    closeConfirmationModal();
  };

  return (
    <div className="flex justify-end items-center gap-[12px] self-stretch p-2">
      {errorMessage && (
        <ErrorToast className="ml-[160px]" message={errorMessage} />
      )}
      <button
        className="flex justify-center items-center w-[44px] h-[44px] rounded-full border border-gray-300 bg-white shadow-xs"
        onClick={openConfirmationModal}
      >
        <img src={TrashIcon} alt="Trash Icon" />
      </button>
      <button
        className="flex justify-center items-center w-[44px] h-[44px] rounded-full border border-gray-300 bg-white shadow-xs"
        onClick={handleSaveClick}
      >
        <img src={SaveIcon} alt="Save Icon" />
      </button>
      <button
        className="flex justify-center items-center w-[44px] h-[44px] rounded-full border border-gray-300 bg-white shadow-xs"
        onClick={handleEyeClick}
      >
        <img src={EyeIcon} alt="Eye Icon" />
      </button>

      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={closeConfirmationModal}
        onConfirm={confirmDelete}
        message={t("iconBar.confirmDeleteRecipe")}
      />

      {/* Success Toast */}
      {showSuccessToast && (
        <SuccessToast className="ml-[160px]" message={t("iconBar.confirmSavedChanges")} />
      )}
    </div>
  );
};

export default IconBar;
