import like_img from './icons/like.svg'
import liked_img from './icons/Heart-fill.svg'
import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import './components.css'
import {isAuthenticated} from '../UserManagement/hook/Authenticated'

export default function LikesComponent(props){
	const navigate = useNavigate();
	const [likeCount, setLikeCount] = useState(props.like_count)
	const [liked, setLiked] = useState(false)

	useEffect(() => {
		setLikeCount(props.like_count)
	}, [props.like_count])
	useEffect(() => {
		setLiked(props.liked)
	}, [props.liked])
	const toggleLike = async (e)=>{
		e.stopPropagation();
		if(!isAuthenticated()){
			navigate('/login')
			return
		}
		if(liked){
			setLiked(false)
			setLikeCount(likeCount - 1)
			try {
				await fetch(`${process.env.REACT_APP_BACKEND_URL}/liked-recipe/${props.recipe_id}`,{
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
					credentials: 'include',
				});
			} catch (error) {
				console.error('Error unfollowing user', error);
			}
			if(props.toggleLike !== undefined){
				props.setToggleLike(!props.toggleLike)
			}
		}
		else{
			setLiked(true)
			setLikeCount(likeCount + 1)
			try {
				await fetch(`${process.env.REACT_APP_BACKEND_URL}/liked-recipe/`,{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						recipe_id: props.recipe_id,
					}),
					credentials: 'include',
				});
			} catch (error) {
				console.error('Error unfollowing user', error);
			}
			if(props.toggleLike !== undefined){
				props.setToggleLike(!props.toggleLike)
			}
		}
	}

	return (
		<div
			onClick={toggleLike}
			className='chip-container'
		>
			<img src={liked? liked_img : like_img} className='chip-icon' alt='like-icon'/>
			<p className='chip-stat'>{likeCount}</p>
		</div>
	)
}

