import kangacook_logo from "./icons/Kangacook logo.png";

import globe_img from "./icons/globe.svg";
import kangacook_logo_mobile from "./icons/kangacook logo mobile.png";
import user_img from "./icons/user.svg";
import edit_img from "./icons/edit.svg";
import default_avatar from "./icons/Avatar.svg";
import hamburger_img from "./icons/hamburger.svg";
import back_img from "./icons/back.svg";
import logout_img from "./icons/logout.svg";
import facebook_img from "./icons/facebook.svg";
import youtube_img from "./icons/youtube.svg";
import instagram_img from "./icons/instagram.svg";
import Cookies from "js-cookie";

import "./Header.css";
import { useState, useEffect } from "react";

import { isAuthenticated } from "../UserManagement/hook/Authenticated";

import { useTranslation } from "react-i18next";

export default function Header({ changeLang, setChangeLang }) {
  const { t, i18n } = useTranslation();
  const [selectLanguage, setSelectLanguage] = useState(false);
  const [checked, setChecked] = useState(false);
  const [profilePic, setProfilePic] = useState(default_avatar);

  const cookie_value = Cookies.get("kangacook_user_id");
  const cookie_lang = Cookies.get("lang");
  const [lang, setLang] = useState(cookie_lang || "en");

  const username = (cookie_value && JSON.parse(cookie_value).username) || "";

  const updateLang = async () => {
    if (!username) {
      return;
    }
    try {
      const apiHost = process.env.REACT_APP_BACKEND_URL;
      await fetch(`${apiHost}/user-identity/${username}/putlanguage/`, {
        method: "PUT",
        credentials: "include",
      });
    } catch (error) {
      console.error("Error fetching user information:", error);
    }
  };

  useEffect(() => {
    async function fetchProfilePic() {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/user-profile/${username}/profile_picture`,
        );
        const data = await response.json();
        data.profile_picture && setProfilePic(data.profile_picture);
      } catch (error) {
        console.error("Error fetching profile picture", error);
      }
    }
    if (username) {
      fetchProfilePic();
    }
  }, [username]);

  return (
    <header className={"header"}>
      <nav className="header-nav">
        <input
          type="checkbox"
          onClick={() => setChecked(!checked)}
          className="header-checkbox"
        />
        <div className="header-mobile-icons">
          <img
            src={hamburger_img}
            alt="hamburger"
            title="hamburger"
            className="header-hamburger"
          />
          <img
            src={back_img}
            alt="back"
            title="back-icon"
            className="header-back"
          />
        </div>

        {/* TODO: 
					change link to home
				*/}
        <a href="/" className="header-icon-container">
          <img
            src={kangacook_logo}
            alt="kangacook-icon"
            title="kangacook-icon"
            className="header-icon"
          />
          <img
            src={kangacook_logo_mobile}
            alt="kangacook-icon"
            title="kangacook-icon"
            className="header-icon-mobile"
          />
        </a>

        <div className="header-options">
          <a href="/about-us" className="header-about-us">
            {t("header.aboutUs")}
          </a>

          {/* TODO: 
						change link to Reward page
					*/}
          <a href="/contactus" className="header-about-us">
            {t("header.contactUs")}
          </a>
          <a href="/reward" className="header-reward">
            {t("header.reward")}
          </a>
        </div>

        <div className="header-user">
          {/* use isAuthenticated to check user login status
					if user login, link for sign in and recipe create will be hidden */}
          {isAuthenticated() ? (
            <>
              <a href="/recipes/create" className="header-create-recipe">
                <img
                  src={edit_img}
                  alt="edit-icon"
                  title="edit-icon"
                  className="header-create-recipe-icon"
                />
                <p className="header-create-recipe-text">
                  {t("header.create")}
                </p>
              </a>
            </>
          ) : (
            <>
              <a href="/login" className="header-login">
                <img
                  src={user_img}
                  alt="user-icon"
                  title="user-icon"
                  className="header-login-icon"
                />
                <p className="header-login-text">{t("header.login")}</p>
              </a>
            </>
          )}
          {/* <a href='/login' className='header-login'>
							<img src={user_img} alt='user-icon' className='header-login-icon'/>
							<p className='header-login-text'>
								Sign up / Log in
							</p>
						</a>
					<a href='/login' className='header-create-recipe'>
						<img src={edit_img} alt='edit-icon' className='header-create-recipe-icon'/>
						<p className='header-create-recipe-text'>
							Create Your Recipe
						</p>
					</a> */}

          <div
            className="header-language"
            onClick={() => setSelectLanguage(!selectLanguage)}
          >
            <img
              src={globe_img}
              alt="globe-icon"
              className="header-language-icon"
            />
            <p className="header-language-text">
              {lang === "en" ? "EN" : lang === "zh" ? "简" : "繁"}
            </p>

            {selectLanguage && (
              <div className="header-language-float">
                <p
                  onClick={() => {
                    let language_cookie = `en`;
                    Cookies.set("lang", language_cookie, {
                      expires: 90,
                      domain: process.env.REACT_APP_COOKIE_URL,
                    });
                    setLang("en");
                    i18n.changeLanguage("en");
                    setChangeLang && setChangeLang(!changeLang);
                    updateLang();
                  }}
                >
                  EN
                </p>
                <p
                  onClick={() => {
                    let language_cookie = `zh`;
                    Cookies.set("lang", language_cookie, {
                      expires: 90,
                      domain: process.env.REACT_APP_COOKIE_URL,
                    });
                    setLang("zh");
                    i18n.changeLanguage("zh");
                    setChangeLang && setChangeLang(!changeLang);
                    updateLang();
                  }}
                >
                  简
                </p>
                <p
                  onClick={() => {
                    let language_cookie = `zh_tw`;
                    Cookies.set("lang", language_cookie, {
                      expires: 90,
                      domain: process.env.REACT_APP_COOKIE_URL,
                    });
                    setLang("zh_tw");
                    i18n.changeLanguage("zh_tw");
                    setChangeLang && setChangeLang(!changeLang);
                    updateLang();
                  }}
                >
                  繁
                </p>
              </div>
            )}
          </div>

          {isAuthenticated() && (
            <a href={`/profile/${username}`} className="header-user-icon-a">
              <img
                src={profilePic}
                alt="user-icon"
                title="user-icon"
                className="header-user-icon-img"
              />
            </a>
          )}
        </div>
        <div className="header-options-mobile">
          <a href="/about-us" className="header-about-us">
            {t("header.aboutUs")}
          </a>
          <a href="/contactus" className="header-about-us">
            {t("header.contactUs")}
          </a>

          {/* TODO: 
						change link to Reward page
					*/}
          <a href="/reward" className="header-reward">
            {t("header.reward")}
          </a>

          <div className="header-bottom">
            {isAuthenticated() && (
              <a
                href="/login"
                className="header-sign-out"
                onClick={() =>
                  Cookies.remove("kangacook_user_id", {
                    domain: process.env.REACT_APP_COOKIE_URL,
                  })
                }
              >
                <img
                  src={logout_img}
                  alt="logout-icon"
                  title="logout-icon"
                  className="header-sign-out-icon"
                />
                <p> {t("header.logOut")} </p>
              </a>
            )}
            <hr />
            <a
              href="https://kangacook-terms-and-conditions.s3.amazonaws.com/Terms+of+Use.pdf"
              className="header-terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("header.terms")}
            </a>
            <a
              href="https://www.termsfeed.com/live/93288d56-1cc2-41a0-b02b-df39f874782b"
              className="header-privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("header.privacy")}
            </a>
            <hr />
            <div className="header-social-media">
              <a href="https://www.facebook.com/profile.php?id=61561452261888">
                <img
                  src={facebook_img}
                  alt="facebook-icon"
                  title="facebook-icon"
                  className="header-facebook-icon"
                />
              </a>
              <a href="https://www.instagram.com/kangacook_official/?igsh=bjA3cXR3bzJ3eXZi">
                <img
                  src={instagram_img}
                  alt="instagram-icon"
                  title="instagram-icon"
                  className="header-instagram-icon"
                />
              </a>
              <a href="https://www.youtube.com/channel/UC_DgEDskMZgKKP9wlUe1xgw">
                <img
                  src={youtube_img}
                  alt="youtube-icon"
                  title="youtube-icon"
                  className="header-instagram-icon"
                />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}
