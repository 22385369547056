import './Cookwares.css'
import {useState} from 'react'
import up_img from '../icons/chevron-up.svg'
import { useTranslation } from "react-i18next";

export default function Cookwares({cookwares}){
	const [collapse, setCollapse] = useState(false)
	const { t } = useTranslation();
	// Hide cookware section if no cookwares were provided. 
	return (
		cookwares.length !== 0 &&
		<div className='recipe-page-cookwares'>
			<div className='recipe-page-cookwares-header'>
				<p className='recipe-page-section-title'>
					{t("recipePage.cookwares")}
				</p>
				<div className='recipe-page-collapse'
					onClick={()=>{setCollapse(!collapse)}}
				>
				{
					collapse?
					<img style={{transform: 'rotate(180deg)'}} src={up_img} alt='collapse-icon'/>
						:
					<img src={up_img} alt='collapse-icon'/>
				}
				</div>
			</div>
			{!collapse &&
			<p className='recipe-page-cookwares-detail'>
				{
					cookwares.join(', ')
				}
			</p>
			}
		</div>
	)
}

