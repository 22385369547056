import React, { useState } from 'react';
import Header from '../components/Header';
import NavigationBar from "../components/NavigationBar/NavigationBar";
import './ConfirmRewardShippingCard.css';
import RecipeCardImage from './Recipe-Card.png'; 

function ConfirmationModal({ onClose }) {
    return (
        <div className='modal-overlay'>
            <div className='modal-container'>
                <h2>Are you sure you want to redeem points?</h2>
                <div className='modal-buttons'>
                    <button className='modal-cancel-button' onClick={onClose}>Cancel</button>
                    <a href='/reward-redeemed' className='modal-confirm-button'>
                        Redeem
                    </a>
                </div>
            </div>
        </div>
    );
}

export default function ConfirmRewardShippingCard() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleConfirmClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };
    
    return (
        <>
            <div className='container-wrapper'>
                <Header className="header" />
                <NavigationBar className="nav-bar" />
                <div className='confirm-rewardshippingcard-page'>
                    <div className='confirm-rewardshippingcard-container'>
                        <h4 className='confirm-shipping-info-title'>Summary</h4>

                        <div className='reward-summary'>
                            <div className='reward-title-container'>
                                <span className='reward-title'>Reward</span>
                            </div>
                            <div className="reward-content">
                                <span className='reward-description'>Kangacook Original Chopsticks</span>
                                <img 
                                    src={RecipeCardImage} 
                                    alt='Kangacook Original Chopsticks' 
                                    className='reward-image' 
                                />
                            </div>
                        </div>

                        <hr className='divider-line' />

                        <div className='balance-section'>
                            <div className='balance-row'>
                                <div className='balance-title-container'>
                                    <span className='balance-title'>Balance</span>
                                </div>
                                <div className='points-column'>
                                    <span className='balance-value'>Available points</span>
                                    <span className='balance-value-points'>450 points</span>
                                </div>
                            </div>
                            <div className='balance-row'>
                                <div className='balance-title-container'>
                                    <span className='balance-title'></span> 
                                </div>
                                <div className='points-column'>
                                    <span className='balance-value'>Points to be redeemed</span>
                                    <span className='balance-value-points'>400 points</span>
                                </div>
                            </div>

                            <div className='balance-row'>
                                <div className='balance-title-container'>
                                    <span className='balance-title'></span> 
                                </div>
                                <div className='dotted-line-container'>
                                    <div className='dotted-line'></div>
                                </div>
                            </div>
                            <div className='balance-row'>
                                <div className='balance-title-container'>
                                    <span className='balance-title'></span>
                                </div>
                                <div className='points-column'>
                                    <span className='balance-value'>Remaining points</span>
                                    <span className='balance-value-points'>50 points</span>
                                </div>
                            </div>
                        </div>

                        <hr className='divider-line-bottom' />

                        <div className='information-section'>
                            <div className='balance-row'>
                                <div className='information-title-container'>
                                    <span className='information-title'>Shipping information</span>
                                </div>
                                <div className='points-column'>
                                    <span className='information-value'>Emily Smith</span>
                                </div>
                            </div>
                            <div className='balance-row'>
                                <div className='information-title-container'>
                                    <span className='information-title'></span> 
                                </div>
                                <div className='points-column'>
                                    <span className='information-value'>1234 Hollywood St, Los Angeles, CA, United States, 98765</span>
                                </div>
                            </div>
                            <div className='balance-row'>
                                <div className='information-title-container'>
                                    <span className='information-title'></span> 
                                </div>
                                <div className='points-column'>
                                    <span className='information-value'>emilysmith@gmail.com</span>
                                </div>
                            </div>
                            <div className='balance-row'>
                                <div className='information-title-container'>
                                    <span className='information-title'></span> 
                                </div>
                                <div className='points-column'>
                                    <span className='information-value'>123-4567-8901</span>
                                </div>
                            </div>
                        </div>

                        <div className='confirmpage-button-group'>
                            <div className='left-buttons'>
                                <a href='/rewardshippingcard' className='confirmpage-back-button'>
                                    Back
                                </a>
                            </div>
                            <div className='right-buttons'>
                                <button className='confirmpage-cancel-button'>Cancel</button>
                                <button className='confirmpage-next-button' onClick={handleConfirmClick}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                        {isModalOpen && <ConfirmationModal onClose={closeModal} />}
                    </div>
                </div>
            </div>
        </>
    );
}
