import './Header.css'
import '../../components/components.css'

import clock_img from '../icons/clock.svg'
import servings_img from '../icons/servings.svg'
import ingredients_img from '../icons/ingredients.svg'

import recipe_img from '../icons/Default_img.jpeg'

import { useState, useEffect} from 'react'
import { useTranslation } from "react-i18next";

export default function Header(props){

	// const RecipeTitle = 'Japanese Dry Curry'
	// const RecipeAuthor = 'Emily Smith'
	// const RecipeDate = 'July 1, 2024'
	const RecipeTitle = props.title
	const RecipeAuthor = props.author
	const postdate = new Date(props.posted_dt)
	const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
	const RecipeDate = postdate.toLocaleDateString('en-US', dateOptions);
	const [recipeImage, setRecipeImage] = useState(recipe_img)
	const { t } = useTranslation();

	useEffect(() => {
		props.cover_picture && setRecipeImage(props.cover_picture)
	}, [props.cover_picture])

	const servings = props.servings
	const time = `${props.cook_time} ${t("recipePage.minutes")}`;
	
	return (
		<div className='recipe-page-header'>
			<div className='recipe-page-header-details'>
				<div className='recipe-page-header-recipe-description'>
					<div className='recipe-page-header-title'>
						{RecipeTitle}
					</div>
					<div className='recipe-page-header-author-time'>
						<div className='recipe-page-header-author'>
							<p>by  </p>
							<a href={`/profile/${RecipeAuthor}`} >{RecipeAuthor}</a>
						</div>
						<div className='recipe-page-header-separator-date' />
						<p className='recipe-page-header-time'>
							{RecipeDate}
						</p>
					</div>
				</div>

				<div className='recipe-page-header-quantities-container'>
					<div className='recipe-page-header-quantity'>
						<img src={servings_img} alt='people-icon'/>
						<p> {servings} {t("recipePage.servings")} </p>
					</div>
					<div className='recipe-page-header-separator-quantity' />
					<div className='recipe-page-header-quantity'>
						<img src={clock_img} alt='clock-icon'/>
						<p> {time} </p>
					</div>
					<div className='recipe-page-header-separator-quantity' />
					<div className='recipe-page-header-quantity'>
						<img src={ingredients_img} alt='ingredient-image'/>
						<p> {props.ingredient_count} {t("recipePage.ingredients")} </p>
					</div>
				</div>
				{
					props.interactionSection
				}
				
			</div>
			
			<div className='recipe-page-header-image'>
				<img src={recipeImage} alt='recipe-image'/>
			</div>
		</div>
	)
}

