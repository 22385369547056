import UserProfileContent from './components/UserProfileContent'
import PublicUserProfileContent from './components/PublicUserProfileContent'
import './UserProfile.css'

import Header from '../components/Header'
import NavigationBar from '../components/NavigationBar/NavigationBar'
import {useParams} from 'react-router-dom'
import Cookies from 'js-cookie'
import {useState} from 'react'

import {isAuthenticated} from '../UserManagement/hook/Authenticated'

export default function UserProfile(){
	const [changeLang, setChangeLang] = useState(false)
	const {username} = useParams();
	const cookie_value = Cookies.get('kangacook_user_id')
	const current_username = (cookie_value && JSON.parse(cookie_value).username) || ''
	return (
		<div className='user-profile'>
			<Header changeLang={changeLang} setChangeLang={setChangeLang} />
			<NavigationBar />
			<div className='user-main-content'>
				{
					isAuthenticated() && username === current_username?
					<UserProfileContent changeLang={changeLang} />
					:
					<PublicUserProfileContent changeLang={changeLang} />
				}
			</div>
		</div>
	)
}

