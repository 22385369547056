import './Tags.css'
import '../../../components/components.css'
import React from 'react'
import { useTranslation } from "react-i18next";

export default function Tags({setTagChange, isTagChange, setIsTagChange}){
	const { t } = useTranslation();
	const tags = [
		{ value: "Chinese", label: t("tags.chinese") },
		{ value: "Taiwanese", label: t("tags.taiwanese") },
		{ value: "Japanese", label: t("tags.japanese") },
		{ value: "Korean", label: t("tags.korean") },
		{ value: "Thai", label: t("tags.thai") },
		{ value: "Vietnamese", label: t("tags.vietnamese") },
		{ value: "Indian", label: t("tags.indian") },
		{ value: "Malaysian", label: t("tags.malaysian") },
		{ value: "Singaporean", label: t("tags.singaporean") },
		{ value: "Filipino", label: t("tags.filipino") },
	];
	return (
		<div className='home-page-tags-component'>
			<div className='home-page-tags-list'>
			{
				tags.map((item, index) => {
					return (
						<React.Fragment key={index}>
						<div
							onClick={()=>{
								setTagChange(item.value);
								setIsTagChange(!isTagChange)
							}}
							className='home-page-tags xl-orange-stroke-tag-container'
						>
							<p className='xl-orange-stroke-tag-text'>
							{item.label}
							</p>
						</div>
						<div
							onClick={()=>{
								setTagChange(item.value);
								setIsTagChange(!isTagChange)
							}}
							className='home-page-tags-sm sm-orange-stroke-tag-container'
						>
							<p className='sm-orange-stroke-tag-text'>
							{item.label}
							</p>
						</div>
						</React.Fragment >
					)
				})
			}
			</div>
		</div>
	)
}

