import create_collection_img from '../../icons/User Saved Recipes.svg'
import SavedRecipeCardComponent from '../../Cards/SavedRecipeCardComponent'
import sm_create_collection_img from '../../icons/add-collection.svg'
import {useState, useEffect} from 'react'
import './SavedRecipes.css'
import { useTranslation } from "react-i18next";

export default function SavedRecipes(props){
	const { t } = useTranslation();
	const [isCreatingCollection, setIsCreatingCollection] = useState(false)
	const [collections, setCollections] = useState([
		{		
			brief: null,
			name:"Saved",
		}
	])
	
	useEffect(() => {
		props.collections && setCollections([
			{		
				brief: null,
				name:"Saved",
			},
			...props.collections
		])
	}, [props.collections])

	async function createCollection(e) {
		e.preventDefault();
		let title = e.target.title.value || 'Untitled'
		console.log(title)
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/recipe-collection/`,{
				method: 'POST',
				body: JSON.stringify({
					name: title,
				}),
				credentials: 'include',
			});
			const data = await response.json();
			console.log(data)

			setIsCreatingCollection(false)
			props.setToggleCollection(!props.toggleCollection)
		} catch (error) {
			console.error('Error fetching user followings:', error);
		}
// 		let newCollection = {
// 			img: recipe_img,
// 			name: title,
// 			count: '0',
// 			id: recipeCollections.length + 1
// 		}
// 		setRecipeCollections([...recipeCollections, newCollection])
// 		setIsCreatingCollection(false)
// 		console.log("Create colleciton: ", title)

	}

	return (
		<>
		<div className='user-profile-saved-recipes'>
			{
				collections && 
				collections.length > 0? 
				<div className='user-profile-saved-recipes-list-container'>
					<div
						className='user-profile-saved-recipe-create-collection'>
						<div
							onClick={()=>setIsCreatingCollection(true)}
							className='l-tertiary-container'
						>
							<img className='l-tertiary-icon' src={sm_create_collection_img} alt='create-collection-image'/>
						</div>
					</div>
					<div className='user-profile-saved-recipes-list'>
						{
							collections.map((item, index) =>
								<div className='user-profile-saved-recipe-card' key={index}>
									<SavedRecipeCardComponent {...item} />
								</div>
							)
						}
					</div>
				</div>
					:
				<div className='user-profile-saved-recipes-empty'>
					<p>{t("savedRecipes.empty")}</p>
					<div
						onClick={()=>setIsCreatingCollection(true)}
						className='user-profile-saved-recipes-create-recipe'>
						<img src={create_collection_img} alt='create-collection-image'/>
					</div>
				</div>
			}
			{
				isCreatingCollection &&
				<div className='user-profile-collection-creation-container'>

					<form
						onSubmit={createCollection}
						className='user-profile-collection-creation-card'
					>

						<p className='user-profile-collection-creation-title'>{t("savedRecipes.createCollection")} </p>
						<div className='user-profile-collection-creation-option'>
							<p> {t("savedRecipes.name")} </p>
							<input
								placeholder={t("savedRecipes.collectionName")}
								name='title'
							/>
						</div>
						
						<div className='user-profile-collection-creation-buttons'>
							<div
								onClick={()=>setIsCreatingCollection(false)}
								className='user-profile-collection-creation-cancel secondary-text-wrapper'
							>
								<p 
									className='secondary-text'
								>
									{t("savedRecipes.cancel")}
								</p>
							</div>

							<input
								id='user-profile-collection-creation-submit-button'
								className='hidden'
								type='submit'
								value='Submit'/>
							<label
								htmlFor='user-profile-collection-creation-submit-button'
								className='user-profile-collection-creation-create secondary-prime-wrapper'
							>
								<p
								className='secondary-prime-text'
								>	
									{t("savedRecipes.create")}
								</p>
							</label>
						</div>

					</form>
				</div>
			}

		</div>
		</>
	)
}


