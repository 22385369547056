import create_recipe_img from '../../icons/User My Recipes.svg'
import MyRecipeCardComponent from '../../Cards/MyRecipeCardComponent'
import './MyRecipes.css'
import '../../../../../components/components.css'
import {useParams} from 'react-router-dom'
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next";

export default function MyRecipes(props){
	const { t } = useTranslation();
	const {username} = useParams();
	const cookie_value = Cookies.get('kangacook_user_id')
	const current_username = (cookie_value && JSON.parse(cookie_value).username) || ''
	const isOwner = username === current_username

	const LATEST = 'latest'
	const POPULAR = 'popular'
	const EARLIEST = 'earliest'

	// const recipes = getRecipes()

	return (
		<>
		<div className='user-profile-my-recipes'>
			{
				props.recipes &&
				props.recipes.results && props.recipes.results.length>0? 
				<>
				<div className='user-profile-my-recipes-order-by-container'>
					<div className={
							props.selected===LATEST? 
							's-tertiary-text-container-active'
							: 
							's-tertiary-text-container'
						}
						onClick={() => {props.setSelected(LATEST)}}
					>
						<p 
							className={
								props.selected===LATEST? 
								'tertiary-text-active'
								: 
								'tertiary-text'
							}
						>
							{t("myRecipes.latest")}
						</p>
					</div>
					<div className={
							props.selected===POPULAR? 
							's-tertiary-text-container-active'
							: 
							's-tertiary-text-container'
						}
						onClick={() => {props.setSelected(POPULAR)}}
					>
						<p 
							className={
								props.selected===POPULAR? 
								'tertiary-text-active'
								: 
								'tertiary-text'
							}
						>
							{t("myRecipes.popular")}
						</p>
					</div>
					<div className={
							props.selected===EARLIEST? 
							's-tertiary-text-container-active'
							: 
							's-tertiary-text-container'
						}
						onClick={() => {props.setSelected(EARLIEST)}}
					>
						<p 
							className={
								props.selected===EARLIEST? 
								'tertiary-text-active'
								: 
								'tertiary-text'
							}
						>
							{t("myRecipes.earliest")}
						</p>
					</div>
				</div>
				<div className='user-profile-my-recipes-list'>

					{
						props.recipes &&
						props.recipes.results.map((item, index) =>
							<div className='user-profile-my-recipe-card' key={index}>
								<MyRecipeCardComponent
									{...item}
									collections={props.collections}
									toggleCollection={props.toggleCollection}
									setToggleCollection={props.setToggleCollection}
									isOwner={isOwner}
								/>
							</div>
						)
					}
					
				</div>
				</>
				:
				<div className='user-profile-my-recipes-empty'>
					<p>{t("myRecipes.empty")}</p>
					<a className='user-profile-my-recipes-create-recipe' href='/recipes/create'>
						<img src={create_recipe_img} alt='create-recipe-image'/>
					</a>
				</div>
			}
		</div>
		</>
	)
}

