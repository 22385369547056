import "./Button.css";

const Button = ({ content, classNames, onClick }) => {
  return (
    <button className={classNames} onClick={onClick}>
      <div>
        <p>{content}</p>
      </div>
    </button>
  );
};

export default Button;
