const ConfirmationModal = ({ show, onClose, onConfirm, message }) => {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-[24px] w-[600px] rounded-lg shadow-lg flex flex-col gap-[24px]">
        <h2 className="text-[18px] font-normal leading-[28px] tracking-[0.36px] text-gray-900">
          {message}
        </h2>

        <div className="flex justify-end w-full space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 rounded-full border border-gray-300 text-gray-700"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 rounded-full bg-primary-500 text-white"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
