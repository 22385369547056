import './CreateCollection.css'
import './components.css'
import {useRef} from 'react'
import { useTranslation } from "react-i18next";

export default function CreateCollection(props){
	const { t } = useTranslation();
	const inputRef = useRef(null)
	const createCollection = async () => {
		let newCollectionName = inputRef.current.value.trim()
		if (newCollectionName === ''){
			console.log("EMPTY")
			return
		}
		try {
			console.log(props)
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/recipe-collection/`,{
				method: 'POST',
				body: JSON.stringify({
					name: newCollectionName,
				}),
				credentials: 'include',
			});
			const data = await response.json();
			console.log(data)
		} catch (error) {
			console.error('Error fetching user followings:', error);
		}
		props.setToggleCollection(!props.toggleCollection)
		props.setCreating(false)
	}
	return (
		<div className='components-create-collection'>
			<div className='components-create-collection-card'>
				<p className='components-create-collection-title'>
					{t("createCollection.createCollection")}
				</p>
				<div className='components-create-collection-name'>
					<p> {t("createCollection.name")} </p>
					<input 
						maxLength='20'
						ref = {inputRef}
						placeholder='Collection name'
					/>
				</div>
				<div className='components-create-collection-submit'>
					<div
						onClick={()=> props.setCreating(false)}
						className='secondary-text-wrapper'
					>
						<p className='secondary-text'> {t("createCollection.cancel")} </p>
					</div>
					<div
						className='primary-text-wrapper'
						onClick={createCollection}
					>
						<p className='primary-text'> {t("createCollection.create")} </p>
					</div>
				</div>
			</div>
		</div>
	)
}

