import React, { useState } from 'react';
import {Helmet} from 'react-helmet'
import { useLocation } from 'react-router-dom';
import ForgotPasswordVerification from '../../components/ForgotPasswordVerification';
import './ConfirmForgotPage.css';
import { useTranslation } from "react-i18next";

export default function ConfirmForgotPage() {
  const location = useLocation();
  const { email } = location.state;

  const [errorMessage, setErrorMessage] = useState(''); // 定义错误消息状态
  const { t } = useTranslation();

  return (
    <div className="container">
	  <Helmet>
		  <meta property="og:title" content="Kangacook - Confirm"/>
		  <meta property="og:description" content= "Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <meta property="og:url" content="https://kangacook.com/forgotpasswordConfirm/"/>
		  <meta property="og:type" content="website" />

		  <meta name="description" content="Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <title> Kangacook - Confirm</title>
      <link rel="canonical" href={`/forgotpasswordConfirm`} />
	  </Helmet>
      <div className="image-section"></div>
      <div className="form-section">
        <div className="form-container">
          {/* 显示错误消息 */}
          {errorMessage && (
            <div className="error-message">
              <img src="/stroke.png" alt="Error" className="error-icon" />
              <span className="error-text">{errorMessage}</span>
            </div>
          )}
		  <a href='/'>
          <img src="/logo.png" alt="Logo" className="logo" />
		  </a>
          <div className="login-page-header">
            <h1 className="title">{t("confirmForgotPage.title")}</h1>
          </div>
          <div className="confirm-subtitle">
            {t("confirmForgotPage.confirmSubtitle")}
          </div>
          <div className="input-header">
            <ForgotPasswordVerification 
              email={email} 
              setErrorMessage={setErrorMessage}  // 传递 setErrorMessage 以便处理错误消息
            />
          </div>
          <div className="confirm-no-account-container">
            <span className="no-account-text">{t("confirmForgotPage.hasAccountText")}</span>
            <a href="/login" className="register-link">{t("confirmForgotPage.loginButton")}</a>
          </div>
          <div className="agreement-container">
                <div className="agreement-text">
                  {t("confirmForgotPage.agreementText")}
                  <a href="https://kangacook-terms-and-conditions.s3.amazonaws.com/Terms+of+Use.pdf" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("confirmForgotPage.terms")} </a>
                  {t("confirmForgotPage.and")} 
                  <a href="https://www.termsfeed.com/live/93288d56-1cc2-41a0-b02b-df39f874782b" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("confirmForgotPage.privacy")}</a>
                </div>
              </div>
        </div>
      </div>
    </div>
  );
}
