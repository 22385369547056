import React from 'react'
import {Helmet} from 'react-helmet'
import Header from '../components/Header'
import NavigationBar from "../components/NavigationBar/NavigationBar";
import './AboutUs.css';
import '../main.css';
import {AboutUsCuisine, AboutUsMobilePhone, AboutUsOylmpus, AboutUsAwsStartups, AboutUsNews} from "./image";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
export default function AboutUs() {
    const { t } = useTranslation();
    return (
        <>
			<Helmet>
                <meta property="og:title" content="Kangacook - About Us"/>
                <meta property="og:description" content= "At Kangacook, food is more than filling your stomach. It’s about sharing stories, traditions, and cultures. Our mission is to bring the vibrant and diverse flavors of Asia to your kitchen, allowing you to experience the richness of authentic Asian cuisine right at home, and also, invite you to share your story of cooking!"/>
                <meta property="og:url" content="https://kangacook.com/about-us/"/>
                <meta property="og:type" content="website" />

				<meta name="description" content="At Kangacook, food is more than filling your stomach. It’s about sharing stories, traditions, and cultures. Our mission is to bring the vibrant and diverse flavors of Asia to your kitchen, allowing you to experience the richness of authentic Asian cuisine right at home, and also, invite you to share your story of cooking!"/>
				<title> Kangacook - About Us</title>
                <link rel="canonical" href="https://kangacook.com/about-us" />
            </Helmet>
			<Header/>
			<NavigationBar/>
            <div className='about-us-container container-wrapper'>
                <div className='page'>
                    <div className='about-us-caption'>
                        <p className='about-us-caption-title'>{t("aboutUs.title")}</p>
                        <p className='about-us-caption-description'>{t("aboutUs.titleDescription1")}</p>
                        <p className='about-us-caption-description'>{t("aboutUs.titleDescription2")}</p>
                        <p className='about-us-caption-description'></p>
                    </div>
                    <div className='about-us-cuisine'>
                        <img src={AboutUsCuisine} alt='about-us-cuisine' className='about-us-cuisine-image'/>
                    </div>
                    <div className='about-us-instruction'>
                        <p className='about-us-instruction-title'>{t("aboutUs.instructionTitle")}</p>
                        <p className='about-us-instruction-description'>{t("aboutUs.instructionDescription")}</p>
                    </div>
                    <div className='about-us-mobile-phone'>
                        <img src={AboutUsMobilePhone} alt='about-us-mobile-phone' className='about-us-mobile-phone-image'/>
                    </div>
                    {/* <div className='about-us-feature'>
                        <div className='about-us-feature-box'>
                            <div className='about-us-feature-box-title-wrapper'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M11 4C14.866 4 18 7.13401 18 11C18 12.8859 17.2542 14.5977 16.0414 15.8564C16.0072 15.8827 15.9742 15.9115 15.9429 15.9429C15.9115 15.9742 15.8827 16.0072 15.8564 16.0414C14.5977 17.2542 12.8859 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4ZM18.0319 16.6177C19.2635 15.078 20 13.125 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C13.125 20 15.078 19.2635 16.6177 18.0319L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L18.0319 16.6177Z" fill="#DC6803"/>
                                </svg>
                                <p className='about-us-feature-box-title'>Explore</p>
                            </div>
                            <p className='about-us-feature-box-description'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='about-us-feature-box'>
                            <div className='about-us-feature-box-title-wrapper'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20 2.87891C19.7026 2.87891 19.4174 2.99705 19.2071 3.20733L9.90296 12.5115L9.37437 14.6259L11.4888 14.0973L20.7929 4.79312C21.0032 4.58283 21.1213 4.29762 21.1213 4.00023C21.1213 3.70283 21.0032 3.41762 20.7929 3.20733C20.5826 2.99705 20.2974 2.87891 20 2.87891ZM17.7929 1.79312C18.3783 1.20776 19.1722 0.878906 20 0.878906C20.8278 0.878906 21.6217 1.20776 22.2071 1.79312C22.7925 2.37848 23.1213 3.1724 23.1213 4.00023C23.1213 4.82805 22.7925 5.62197 22.2071 6.20733L12.7071 15.7073C12.5789 15.8355 12.4184 15.9264 12.2425 15.9704L8.24254 16.9704C7.90176 17.0556 7.54127 16.9557 7.29289 16.7073C7.04451 16.459 6.94466 16.0985 7.02986 15.7577L8.02986 11.7577C8.07382 11.5819 8.16473 11.4213 8.29289 11.2931L17.7929 1.79312ZM1.87868 3.87891C2.44129 3.3163 3.20435 3.00023 4 3.00023H11C11.5523 3.00023 12 3.44794 12 4.00023C12 4.55251 11.5523 5.00023 11 5.00023H4C3.73478 5.00023 3.48043 5.10558 3.29289 5.29312C3.10536 5.48066 3 5.73501 3 6.00023V20.0002C3 20.2654 3.10536 20.5198 3.29289 20.7073C3.48043 20.8949 3.73478 21.0002 4 21.0002H18C18.2652 21.0002 18.5196 20.8949 18.7071 20.7073C18.8946 20.5198 19 20.2654 19 20.0002V13.0002C19 12.4479 19.4477 12.0002 20 12.0002C20.5523 12.0002 21 12.4479 21 13.0002V20.0002C21 20.7959 20.6839 21.5589 20.1213 22.1215C19.5587 22.6842 18.7957 23.0002 18 23.0002H4C3.20435 23.0002 2.44129 22.6842 1.87868 22.1215C1.31607 21.5589 1 20.7959 1 20.0002V6.00023C1 5.20458 1.31607 4.44152 1.87868 3.87891Z" fill="#DC6803"/>
                                </svg>
                                <p className='about-us-feature-box-title'>Post</p>
                            </div>
                            <p className='about-us-feature-box-description'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                        <div className='about-us-feature-box'>
                            <div className='about-us-feature-box-title-wrapper'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_5535_87121)">
                                        <path d="M0 1C0 0.447715 0.447715 0 1 0H5C5.47663 0 5.88701 0.336385 5.98055 0.803743L6.82043 5H23C23.298 5 23.5805 5.13293 23.7705 5.36256C23.9605 5.59218 24.0381 5.89458 23.9823 6.18733L22.3809 14.5848C22.2437 15.2754 21.868 15.8958 21.3195 16.3373C20.7738 16.7766 20.0916 17.011 19.3914 17H9.68864C8.98837 17.011 8.3062 16.7766 7.76048 16.3373C7.21225 15.8959 6.83664 15.2759 6.69933 14.5857C6.69927 14.5854 6.69939 14.5859 6.69933 14.5857L5.02879 6.2392C5.02201 6.21159 5.01638 6.18353 5.01195 6.15508L4.18032 2H1C0.447715 2 0 1.55228 0 1ZM7.22073 7L8.66084 14.1952C8.70656 14.4254 8.83179 14.6322 9.01461 14.7793C9.19743 14.9265 9.42619 15.0047 9.66084 15.0002L9.68 15H19.4L19.4192 15.0002C19.6538 15.0047 19.8826 14.9265 20.0654 14.7793C20.2474 14.6328 20.3723 14.4273 20.4185 14.1984L21.7913 7H7.22073ZM7 21C7 19.8954 7.89543 19 9 19C10.1046 19 11 19.8954 11 21C11 22.1046 10.1046 23 9 23C7.89543 23 7 22.1046 7 21ZM18 21C18 19.8954 18.8954 19 20 19C21.1046 19 22 19.8954 22 21C22 22.1046 21.1046 23 20 23C18.8954 23 18 22.1046 18 21Z" fill="#DC6803"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_5535_87121">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <p className='about-us-feature-box-title'>Shopping</p>
                            </div>
                            <p className='about-us-feature-box-description'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                        </div>
                    </div> */}
                    <Link to="/home" className='about-us-search-button'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 21" fill="none">
                            <path d="M9.66602 3.6237C12.8877 3.6237 15.4993 6.23537 15.4993 9.45703C15.4993 11.0287 14.8778 12.4551 13.8672 13.504C13.8386 13.526 13.8112 13.55 13.7851 13.5761C13.759 13.6022 13.7349 13.6297 13.713 13.6582C12.6641 14.6688 11.2376 15.2904 9.66602 15.2904C6.44435 15.2904 3.83268 12.6787 3.83268 9.45703C3.83268 6.23537 6.44435 3.6237 9.66602 3.6237ZM15.5259 14.1384C16.5523 12.8554 17.166 11.2279 17.166 9.45703C17.166 5.3149 13.8082 1.95703 9.66602 1.95703C5.52388 1.95703 2.16602 5.3149 2.16602 9.45703C2.16602 13.5992 5.52388 16.957 9.66602 16.957C11.4369 16.957 13.0644 16.3433 14.3474 15.3169L17.4101 18.3796C17.7355 18.7051 18.2632 18.7051 18.5886 18.3796C18.914 18.0542 18.914 17.5265 18.5886 17.2011L15.5259 14.1384Z" fill="#DC6803"/>
                        </svg>
                        <p className='about-us-search-button-name'>{t("aboutUs.exploreRecipesButton")}</p>
                    </Link>
                    <div className='about-us-news'>
                        <p className='about-us-news-title'>{t("aboutUs.news")}</p>
                        <div className='about-us-news-container'>
                            <div className='about-us-news-container-box'>
                                <img src={AboutUsNews} alt='about-us-news' className='about-us-news-container-box-image'/>
                                <div className='about-us-news-container-box-description-wrapper'>
                                    <p className='about-us-news-container-box-description-content'>{t("aboutUs.newsTitle")}</p>
                                    <p className='about-us-news-container-box-description-date'>{t("aboutUs.newsDate")}</p>
                                </div>
                            </div>
                            {/* <div className='about-us-news-container-box'>
                                <img src={AboutUsNews} alt='about-us-news' className='about-us-news-container-box-image'/>
                                <div className='about-us-news-container-box-description-wrapper'>
                                    <p className='about-us-news-container-box-description-content'>Kangacook starts kickstarter</p>
                                    <p className='about-us-news-container-box-description-date'>August 26, 2024</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className='about-us-sponsorship'>
                        <p className='about-us-sponsorship-title'>{t("aboutUs.sponsorship")}</p>
                        <div className='about-us-sponsorship-logo-wrapper'>
                            <img src={AboutUsOylmpus} alt='Olympus' className='about-us-sponsorship-logo'/>
                            <img src={AboutUsAwsStartups} alt='AwsStartups' className='about-us-sponsorship-logo'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
