import React from 'react'
import './ApplicationForm.css'

export default function ApplicationForm({id}){

	async function submitForm(event){
		event.preventDefault(); // Prevent the default form submission

		const form = event.target;
		const formData = new FormData(form); // Create FormData object from the form
		
		let msg_element = document.getElementById("invalid-msg")
		msg_element.classList.add("hidden")
		let submit_element= document.getElementById("submit")
		submit_element.classList.add("hidden")
		let loader_element= document.getElementById("loader")
		loader_element.classList.remove("hidden")

		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}`, {
				method: 'POST',
				body: formData,
			});

			if (response.ok) {
				event.target.reset()
				let msg_element = document.getElementById("valid-msg")
				msg_element.classList.remove("hidden")
				let form_element = document.getElementById("application-form")
				form_element.classList.add("hidden")
			} else {
				let msg_element = document.getElementById("invalid-msg")
				msg_element.classList.remove("hidden")

		
				let submit_element= document.getElementById("submit")
				submit_element.classList.remove("hidden")
				let loader_element= document.getElementById("loader")
				loader_element.classList.add("hidden")
			}
		}
		catch(error) {
			console.log("Error")
			let msg_element = document.getElementById("invalid-msg")
			msg_element.classList.remove("hidden")
		
			let submit_element= document.getElementById("submit")
			submit_element.classList.remove("hidden")
			let loader_element= document.getElementById("loader")
			loader_element.classList.add("hidden")

			console.log(error)
		}
	}

	return (
		<>
		<p id="valid-msg" className="valid-msg hidden"> 
			Your message has been sent successfully. We will get back to you shortly.
		</p>
		<p id="invalid-msg" className="invalid-msg hidden"> 
			There was an error while submitting the form. Please try again.
		</p>

		<form className="application-form" id="application-form" onSubmit={submitForm} method="post" encType="multipart/form-data">
			<h2> Apply Here </h2>
			<input type="hidden" name="id" value={id} />
			<input type="text" name="fname" placeholder="First Name" required/>
			<input type="text" name="lname" placeholder="Last Name" required/>
			<input type="text" name="email" placeholder="Email" pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,5}$" required/>
			<input type="tel" name="phone" placeholder="Phone Number" pattern="[0-9]{10}" />
			<input type="file" name="resume" accept="application/pdf" required/>

			<div id="loader" className="loader hidden"></div>
			<button id="submit">Submit Application</button>
		</form>
		</>
	)
}

