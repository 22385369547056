import React from 'react';
import {Helmet} from 'react-helmet'
import Forgotpassword from '../../components/KangaForgetPassword'; 
import './ForgotPassword.css'; 
import { useTranslation } from "react-i18next";

export default function ForgotPasswordPage() {
  const { t } = useTranslation();
  return (
    <div className="forgotpassword-container">
	  <Helmet>
		  <meta property="og:title" content="Kangacook - Forgot Password"/>
		  <meta property="og:description" content= "Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <meta property="og:url" content="https://kangacook.com/forgotpassword/"/>
		  <meta property="og:type" content="website" />

		  <meta name="description" content="Kangacook is a vibrant recipe and food-sharing platform where we believe cooking brings happiness. Share your favorite recipes, discover new dishes, and connect with fellow food lovers who share your passion for cooking."/>
		  <title> Kangacook - Forgot Password</title>
      <link rel="canonical" href={`/forgotpassword`} />
	  </Helmet>
      <div className="forgotpassword-image-section"></div>
      <div className="forgotpassword-form-section">
        <div className="forgotpassword-form-container">
		  <a href='/'>
          <img src="/logo.png" alt="Logo" className="forgotpassword-logo" /> 
		  </a>
            <div className="forgotpassword-header">
              <a href="/login" className="back-button">
                <img src="/arrow-left.png" alt="Back" />
              </a>
              <h1 className="forgotpassword-title">{t("forgotPassword.forgotPasswordTitle")}</h1>
            </div>
            <div className='forgotpassword-alterheader'>
              <Forgotpassword />
            </div>
              <div className="already-account-container">
                <span className="already-account-text">{t("forgotPassword.hasAccountText")}</span>
                <a href="/login" className="login-link">{t("forgotPassword.loginButton")}</a>
              </div>
              <div className="forgotpassword-agreement-container">
                <div className="forgotpassword-agreement-text">
                {t("forgotPassword.agreementText")} 
                  <a href="https://kangacook-terms-and-conditions.s3.amazonaws.com/Terms+of+Use.pdf" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("forgotPassword.terms")}  </a>
                  {t("forgotPassword.and")}  
                  <a href="https://www.termsfeed.com/live/93288d56-1cc2-41a0-b02b-df39f874782b" style={{ color: '#F79009', letterSpacing: '0.02em', textDecoration: 'none' }}> {t("forgotPassword.privacy")} </a>
                </div>
              </div>           
        </div>
      </div>
    </div>
  );
}
