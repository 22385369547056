import './Ingredients.css'
import up_img from '../icons/chevron-up.svg'
import '../../components/components.css'
import IngredientCheckbox from './components/IngredientCheckbox'
// import cart_img from '../icons/cart.svg'
import {useState} from 'react'
import { useTranslation } from "react-i18next";

export default function Ingredients({
	serving_count,
	recipe_ingredients,
}){
	const ingredients = recipe_ingredients? recipe_ingredients.map(ingredient => `${ingredient.serving} ${ingredient.name}`) : []
	const [ingredientsState, setIngredientsState] = 
		useState([...Array(ingredients.length)].map(x=>false))
	//
	// const [ingredientsState, setIngredientsState] = 
	// 	useState([...Array(ingredients.length)].map(x=>false))
	const [collapse, setCollapse] = useState(false)
	const { t } = useTranslation();


// 	const selectAll = ()=>{
// 		setIngredientsState([...Array(ingredients.length)].map(x=>true))
// 	}
// 	const unselectAll = ()=>{
// 		setIngredientsState([...Array(ingredients.length)].map(x=>false))
// 	}
	return (
		<div className='recipe-page-ingredients'>
			<div className='recipe-page-ingredients-header'>
				<div className='recipe-page-ingredients-header-header'>
					<p className='recipe-page-section-title'>
						{t("recipePage.ingredients")}
					</p>
					<div className='recipe-page-collapse'
						onClick={()=>{setCollapse(!collapse)}}
					>
					{
						collapse?
						<img style={{transform: 'rotate(180deg)'}} src={up_img} alt='collapse-icon'/>
							:
						<img src={up_img} alt='collapse-icon'/>
					}
					</div>
				</div>
				<p className='recipe-page-ingredients-header-serving-count'>
					({serving_count} {serving_count > 1 ? t("recipePage.servings") : t("recipePage.serving")})
				</p>
				{/*
				!collapse &&
				<div className='recipe-page-ingredients-selections'>
					{
						ingredientsState.every(v => v === true)? 
						<div
							className='s-prime-tertiary-text-container-disabled recipe-page-ingredients-selectAll'
							onClick={selectAll}
						>
							<p className='prime-tertiary-text-disabled'>
								Select all
							</p>
						</div>
						:
						<div
							className='s-prime-tertiary-text-container recipe-page-ingredients-selectAll'
							onClick={selectAll}
						>
							<p className='prime-tertiary-text'>
								Select all
							</p>
						</div>
					}
					{
						ingredientsState.every(v => v === false)? 
						<div
							className='s-prime-tertiary-text-container-disabled recipe-page-ingredients-unselectAll'
							onClick={unselectAll}
						>
							<p className='prime-tertiary-text-disabled'>
								Unselect all
							</p>
						</div>
							:
						<div
							className='s-prime-tertiary-text-container recipe-page-ingredients-unselectAll'
							onClick={unselectAll}
						>
							<p className='prime-tertiary-text'>
								Unselect all
							</p>
						</div>
					}

				</div>
				*/}
			</div>
			{!collapse && 
				<>
			<div className='recipe-page-ingredients-container'>
				{
					ingredients.map((item, index) =>{
						return(
							<IngredientCheckbox
								name={item}
								states={ingredientsState}
								setStates={setIngredientsState}
								index={index}
								key={index}
							/>
						)
					})
				}
			</div>
			{/*
				ingredientsState.every(v => v === false)? 
				<div className='recipe-page-ingredients-cart'>
					<div 
						className='secondary-prime-wrapper-disabled'
					>
						<img src={cart_img} className='secondary-prime-icon-disabled' />
						<p className='secondary-prime-text-disabled'>
							Add to Cart
						</p>
					</div>
				</div>
				:
				<div className='recipe-page-ingredients-cart'>
					<div 
						className='secondary-prime-wrapper'
					>
						<img src={cart_img} className='secondary-prime-icon' />
						<p className='secondary-prime-text'>
							Add to Cart
						</p>
					</div>
				</div>

			*/}
				</>
			}
		</div>
	)
}

