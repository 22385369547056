import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_MANAGER_ID}`
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Optional: You can also include a noscript fallback
const noscript = document.createElement('noscript');
noscript.innerHTML = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQWSW9J3"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;
document.body.appendChild(noscript);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
