import React from 'react';

const StepIndicator = ({ stepNumber, isActive }) => {
  return (
    <div className="flex flex-col items-center">
      <div
        className={`flex flex-col justify-center items-center w-[36px] h-[36px] p-[7px] gap-[10px] rounded-full text-white ${
          isActive ? 'bg-primary-500' : 'bg-gray-300'
        }`}
      >
        {stepNumber}
      </div>
      <div
        className={`w-[1px] h-[544px] tablet:h-[276px] ${
          isActive ? 'bg-primary-500' : 'bg-gray-300'
        }`}
      ></div>
    </div>
  );
};

export default StepIndicator;
