import './IndividualComment.css'
import '../../components/components.css'
import more_img from '../icons/more.svg'
import profile_img from '../icons/Avatar.svg'
import edit_img from '../icons/edit.svg'
import delete_img from '../icons/delete.svg'
import {useState, useRef, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next";

export default function IndividualComment({
	originalContent, profile, name, date, replyComment, deleteComment, comment_id,
}){
	const { t } = useTranslation();
	const navigate = useNavigate()
	const [viewMore, setViewMore] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const [editing, setEditing] = useState(false)
	const [content, setContent] = useState(originalContent)
	const buttonRef = useRef(null)
	
	const textRef = useRef(null)

	const cookie_value = Cookies.get('kangacook_user_id')
	const username = (cookie_value && JSON.parse(cookie_value).username) || '';

	const toggleDelete = () => {
		setViewMore(false)
		setDeleting(true)
	}

	const textOnChange = (e) => {
		textRef.current && setContent(textRef.current.value)
		textRef.current.style.height = 0;
		textRef.current.style.height = textRef.current.scrollHeight + 20 + 'px';
	}

	const toggleEdit = ()=>{
		setEditing(true)
		setViewMore(false)
	}

	const cancelEdit = () => {
		setEditing(false)
		setContent(originalContent)
	}

	const confirmEdit = async () => {
		setEditing(false)
		if(originalContent === content){
			return
		}
		originalContent = content
		try {
			await fetch(`${process.env.REACT_APP_BACKEND_URL}/comment/${comment_id}/`,{
				method: 'PUT',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					content: content
				})
			});
		} catch (error) {
			console.error('Error updating comment: ', error);
		}
	}

	const confirmDelete = async () => {
		setDeleting(false)
		deleteComment()
		try {
			await fetch(`${process.env.REACT_APP_BACKEND_URL}/comment/${comment_id}/`,{
				method: 'DELETE',
				credentials: 'include',
			});
		} catch (error) {
			console.error('Error deleting comment: ', error);
		}
	}

	useEffect(() => {
		if(editing){
			textRef.current.style.height = 0;
			textRef.current.style.height = textRef.current.scrollHeight + 20 + 'px';
			if(document.activeElement !== textRef.current){
				textRef.current.focus()
				textRef.current.value = ''
				textRef.current.value = content
			}
		}
		const resizeTextarea = (e)=> {
			textRef.current.style.height = 0;
			textRef.current.style.height = textRef.current.scrollHeight + 20 + 'px';
		}
		editing && window.addEventListener('resize', resizeTextarea)

		return () => {
			window.removeEventListener('resize', resizeTextarea)
		}
	}, [editing])

	useEffect(()=>{
		const handleClick = (e) => {
			if(buttonRef.current && !buttonRef.current.contains(e.target)){
				setViewMore(false)
			}
		}
		viewMore && document.addEventListener('click', handleClick)
		return ()=>{
			document.removeEventListener('click', handleClick)
		}
	}, [viewMore])
	
	return (
	<div className='recipe-page-comments-container'>
		<div className='recipe-page-comment-separator-container'>
			<div className='recipe-page-comment-separator'/>
		</div>
		<div className='recipe-page-comments-content'>
			<div className='recipe-page-profile-pic-container'>
				<div 
					className='s-avatar-container'
 					onClick={() => {navigate(`/profile/${name}`)}}
				>
					<div className='s-avatar-cover' />
					<img src={profile || profile_img} className='s-avatar-avatar' alt='profile-picture'/>
				</div>
			</div>
			<div className='recipe-page-comments-details'>
				<div className='recipe-page-comments-description'>
					<p
						onClick={() => {navigate(`/profile/${name}`)}}
						className='recipe-page-comment-commenter-name'
					>
						{name}
					</p>
					<p className='recipe-page-comment-date'>
						{date}
					</p>
					{
						name === username &&
					<div
						ref={buttonRef}
						className='s-tertiary-icon-container'
					>
						<img
							onClick={() => setViewMore(!viewMore)}
							src={more_img}
							alt='more-icon'
						/> 
					{
						viewMore &&

						<div className='recipe-page-comment-share-card-container'>
							<div
								onClick={() => setViewMore(!viewMore)}
								className='recipe-page-comment-share-card-exit'
							/>
							<div className='recipe-page-comment-share-card'>
								<div className='recipe-page-comment-share-card-title'> 
									<p> {t("recipePage.comment")} </p>
								</div>

								{
								name === username?
								<>
									<div
										onClick={toggleEdit}
										className='recipe-page-comment-share-item'
									>
										<img src={edit_img} alt='edit-icon'/>
										<p> {t("recipePage.edit")} </p>
									</div>
									<div
										onClick={toggleDelete}
										className='recipe-page-comment-share-item recipe-page-comment-delete-button'
									>
										<img src={delete_img} alt='delete-icon'/>
										<p> {t("recipePage.delete")} </p>
									</div>
								</>
									:
								<>
								{/*
								<div
									className='recipe-page-comment-share-item'
								>
									<img src={flag_img} />
									<p> Report </p>
								</div>
								*/}
								</>
								}
							</div>
						</div>
					}
					</div>
					}
				</div>
				{
					editing ?
					<>
					<p className='recipe-page-comments-comment recipe-page-comments-mb'> {content} </p>
					<div className='recipe-page-comment-edit'>
						<span onClick={cancelEdit} className='recipe-page-comment-edit-exit' />
						<p> {t("recipePage.editComments")} </p>
						<div className='recipe-page-comment-edit-content'>
							<div 
								className='s-avatar-container recipe-page-comment-edit-img'
							>
								<div className='s-avatar-cover' />
								<img src={profile_img} className='s-avatar-avatar' alt='profile-picture'/>
							</div>
							<textarea
								ref = {textRef}
								className={
									'recipe-page-comment-textarea'
								}
								value={content}
								onChange={textOnChange}
								placeholder={t("recipePage.writeComment")}
							/>
							<div className='recipe-page-comment-edit-button'>
								<div
									onClick={confirmEdit}
									className='recipe-page-comment-edit-confirm'
								>
									<p className='quaternary-prime-text'> {t("recipePage.saveChanges")} </p>
								</div>
								<div
									onClick={cancelEdit}
									className='recipe-page-comment-edit-cancel'
								>
									<p className='quaternary-text'> {t("recipePage.cancel")} </p>
								</div>
							</div>
						</div>
					</div>
				</>
					:
					<>
						<p className='recipe-page-comments-comment'> {content} </p>
						<p
							onClick={() => replyComment(name)}
							className='quaternary-text recipe-page-comments-reply'
						> {t("recipePage.reply")}
						</p>
					</>
				}
			</div>
		</div>
		{
			deleting &&
			<div className='recipe-page-comment-delete-container'>
				<div className='recipe-page-comment-delete-card'>
					<h6> {t("recipePage.confirmDeleteComment1")} </h6>
					<p>{t("recipePage.confirmDeleteComment2")}</p>
					<div>
						<div
							onClick={()=>setDeleting(false)}
							className='secondary-text-wrapper'
						>
							<p className='secondary-text'> {t("recipePage.cancel")} </p>
						</div>
						<div
							onClick={confirmDelete}
							className='primary-text-wrapper color-red'
						>
							<p className='primary-text'> {t("recipePage.delete")} </p>
						</div>
					</div>
				</div>
			</div>
		}
	</div>
	)
}

