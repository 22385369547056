import '../../../components/components.css'
import './CollectionContent.css'
import RecipeCardComponent from '../../../components/RecipeCardComponent'
import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next";

export default function CollectionContent({pk}){
	const { t } = useTranslation();
	const cookie_value = Cookies.get('kangacook_user_id')
	const navigate = useNavigate();
	const username = JSON.parse(cookie_value).username;
	const [collections, setCollections] = useState(null)
	const [toggleCollection, setToggleCollection] = useState(false)
	const [recipes, setRecipes] = useState({
		count: 0,
		results: [],
	})
	const fetchUserCollections = async () => {
		try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/recipe-collection`,{
				method: 'GET',
				credentials: 'include',
			});
			const data = await response.json();
			setCollections(data.filter(collection => collection.name !== 'Saved')); // Set the recipe data to state
			for(let i = 0; i < data.length; i++){
				if (data[i].name === pk){
					data[i].brief && setCollectionBrief(data[i].brief)
					break;
				}
			}
		} catch (error) {
			console.error('Error fetching user collection:', error);
		}
	}
	useEffect(() => {
		fetchUserCollections()
	}, [toggleCollection])
	useEffect(() => {
		async function fetchCollectionContent() {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/recipe-collection/${pk}/list-collection-contents`,{
						method: 'GET',
						credentials: 'include',
					}
				);
				if(response.ok){
					const data = await response.json();
					setRecipes(data); // Set the recipe data to state
				}
			} catch (error) {
				console.error('Error fetching user information:', error);
			}
		}
		fetchCollectionContent()
	}, [])


	const [collectionTitle, setCollectionTitle] = useState(pk)
	const [tempCollectionTitle, setTempCollectionTitle] = useState(pk)

	const [collectionBrief, setCollectionBrief] = useState(t("collectionContent.noDescription"))
	const [tempCollectionBrief, setTempCollectionBrief] = useState(collectionBrief)

  const [isEditing, setIsEditing] = useState(false);
  const [confirmDeletion, setConfirmDeletion] = useState(false);

  useEffect(() => {
    setTempCollectionBrief(collectionBrief);
  }, [collectionBrief]);

  async function editCollection(e) {
    e.preventDefault();
    console.log("collection edited");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/recipe-collection/${pk}/`,
        {
          method: "PUT",
          body: JSON.stringify({
            name: tempCollectionTitle,
            brief: tempCollectionBrief,
          }),
          credentials: "include",
        },
      );
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error fetching user information:", error);
    }

    window.history.replaceState(null, null, tempCollectionTitle);

    setCollectionBrief(tempCollectionBrief);
    setCollectionTitle(tempCollectionTitle);
    setIsEditing(false);
  }

  const deleteRecipeCollection = async () => {
    console.log("delete current recipe collection");
    console.log("current collection is " + pk);

    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/recipe-collection/${pk}`,
        {
          method: "DELETE",
          credentials: "include",
        },
      );
      console.log("DELETE");
      setConfirmDeletion(false);
      setIsEditing(false);
      navigate(`/profile/${username}/?section=saved`);
    } catch (error) {
      console.error("Error fetching user followings:", error);
    }
  };

  const cancelEdit = () => {
    setTempCollectionBrief(collectionBrief);
    setTempCollectionTitle(collectionTitle);
    setIsEditing(false);
    setConfirmDeletion(false);
  };

  return (
    <div className="user-collection-content">
      <div className="user-collection-description">
        <h3 className="user-collection-name">{collectionTitle}</h3>
        <p className="user-collection-brief">{collectionBrief}</p>
			</div>
			<div className='user-collection-options'>
				{pk !== "Saved" && 
        <div
          onClick={() => setIsEditing(true)}
          className="user-collection-option secondary-text-wrapper"
        >
					<p className='secondary-text'> {t("collectionContent.edit")} </p>
				</div>
       }
			</div>

			<div className='user-collection-recipe-container'>
				<p className='user-collection-recipe-count'>
					{`${recipes.count} ${recipes.count > 1 ? t("collectionContent.recipes") : t("collectionContent.recipe")}`}
				</p>
				{
					recipes.results.length>0?
						<div className='user-collection-recipes-list'>
							{
								recipes.results.map((item, index) => 
									<div className='user-collection-recipe-card' key={index}>
										<RecipeCardComponent
											{...item}
											collections={collections}
											toggleCollection={toggleCollection}
											setToggleCollection={setToggleCollection}
										/>
									</div>
								)
							}
						</div>
						:
					<div className='user-collection-empty-collection'>
						<p>{t("collectionContent.empty")}</p>
					</div>
				}
			</div>
			{
				isEditing &&
				<div className='user-collection-editing'>

				{
					(confirmDeletion && 
					<div className='user-collection-deletion-card'>
						<span>
						<h6> {t("collectionContent.confirmDeleteCollectionTitle")} </h6>
						<p>
							{t("collectionContent.confirmDeleteCollectionSub1")}
								<b>{' '+collectionTitle+' '}</b>
							{t("collectionContent.and")}
								<b>{' ' + recipes.count + ' '} </b>
							{t("collectionContent.confirmDeleteCollectionSub2")}
						</p>
						</span>
						<div className='user-collection-deletion-option'>
							<div
								onClick={cancelEdit}
								className='secondary-text-wrapper'
							>
								<p 
									className='secondary-text'
								>
									{t("collectionContent.cancel")}
								</p>
							</div>
							<div
								onClick={deleteRecipeCollection}
								className='secondary-prime-wrapper'
							>
								<span className='secondary-prime-text'>
									{t("collectionContent.delete")}
								</span>
							</div>
						</div>
					</div>
					)
					||
					<form
						onSubmit={editCollection}
						className='user-collection-editing-card'
					>

						<p className='user-collection-editing-title'>{t("collectionContent.editCollection")} </p>
						<div className='user-collection-edit-name'>
							<p> {t("collectionContent.name")} </p>
							<input
								name='title'
								maxLength='20'
								value={tempCollectionTitle}
								onChange={(e)=>setTempCollectionTitle(e.target.value)}
							/>
						</div>

						<div className='user-collection-edit-brief'>
							<p> {t("collectionContent.description")} </p>
							<textarea
								maxLength='500'
								name='brief'
								value={tempCollectionBrief}
								onChange={(e)=>setTempCollectionBrief(e.target.value)}
								placeholder={`What's your collection about?`}
							/>
						</div>
						
						<div className='user-collection-edit-buttons'>
							<div
								onClick={cancelEdit}
								className='user-collection-edit-cancel secondary-text-wrapper'
							>
								<p 
									className='secondary-text'
								>
									{t("collectionContent.cancel")}
								</p>
							</div>

							<input
								id='user-collection-edit-submit-button'
								className='hidden'
								type='submit'
								value='Submit'/>
							<label
								htmlFor='user-collection-edit-submit-button'
								className='user-collection-edit-submit secondary-prime-wrapper'
							>
								<p
								className='secondary-prime-text'
								>	
									{t("collectionContent.submit")}
								</p>
							</label>
							<div className='user-collection-delete-container'>
								<div
									onClick={()=>setConfirmDeletion(true)}
									className='user-collection-edit-delete tertiary-text-container'
								>
									<p className='tertiary-text'>
										{t("collectionContent.deleteCollection")}
									</p>
								</div>
							</div>
						</div>
					</form>
          }
        </div>
			}
    </div>
  );
}
