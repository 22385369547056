import './MyRecipeCardComponent.css'
import edit_img from '../icons/my-recipe-edit.svg'
import '../../../../components/components.css'
import default_img from '../icons/Recipe_DEMO.jpeg'
import LikesComponent from '../../../../components/LikesComponent'
import SavedComponent from '../../../../components/SavedComponent'

import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

export default function MyRecipeCardComponent(props){
	const [saved, setSaved] = useState(props.saved)
	const navigate = useNavigate();
	return (
		<div
			onClick={()=>navigate(`/recipe/${props.recipe_id}`)}
			className='my-recipe-card'
		>
			<div className='my-recipe-card-image-container'>
				<img src={props.cover_picture || default_img} className='my-recipe-card-image' alt='recipe-image'/>
				{
					props.draft &&
					<div className='my-recipe-card-draft-container'>
						<p className='my-recipe-card-draft'> Draft </p>
					</div>
				}
			</div>
			<div className='my-recipe-card-bottom'>
				<div className='my-recipe-card-details'>
					<p className='my-recipe-card-recipe-name'>
						{props.title}
					</p>
					<div className='my-recipe-card-recipe-details'>
						<LikesComponent
							recipe_id={props.recipe_id}
							like_count={props.like_count}
							liked={props.liked}
						/>
						<SavedComponent
							recipe_id={props.recipe_id}
							saved_count={props.saved_count}
							collections={props.collections}
							toggleCollection={props.toggleCollection}
							setToggleCollection={props.setToggleCollection}
							saved={props.saved}
						/>
					</div>
				</div>
				{
				props.isOwner && 
				<img
					src={edit_img}
					className='secondary-icon my-recipe-card-edit-img'
					onClick={(e) => {e.preventDefault(); e.stopPropagation(); navigate(`/recipes/create/${props.recipe_id}`)}}
					alt='edit-icon'
				/>
				}
			</div>
		</div>
	)
}

