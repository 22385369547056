import React from 'react'
import './StoreList.css'
import Store from './Store'
import { useTranslation } from "react-i18next";

export default function StoreList({selectedStore, setSelectedStore}) {
	const { t } = useTranslation();
	return (
		<div className='cart-store-list'>
			<p className='cart-store-list-title'>
				{t("cart.stores")}
			</p>
			<div className='cart-store-list-items'>
				<Store selectedStore={selectedStore} setSelectedStore={setSelectedStore} />
			</div>
		</div>
	)
}
